import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import useApi from '../../../hook/useApi';
import {
  storeAllEntitiesAction,
  storeUnapprovedEntitiesAction,
  storeSingleEntityAction,
  entityShipmentAction,
} from '../../../store';
import {
  allShipmentFromEntityQuery,
  AllOnboardingApplicationsQuery,
  getAllEntitiesQuery,
  updateEntityMutation,
  getEntity,
  onboardingMutation,
  findAllSalesMemberQuery,
  removeAddressMutation,
} from '../../../api';
import { editEntitySchema } from '../Entities/Schema/editEntitySchema';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';
import { FORMATE_ADDRESS, formatAddress } from '../../../helpers/helpers';
import useOutsideClick from '../../../hook/useOutsideClick';

const UseEditEntities = () => {
  const { shipments } = useSelector((state) => state);
  const { entities } = useSelector((state) => state);

  const [
    fetchSalesMember,
    fetchSalesMemberLoading,
    fetchSalesMemberError,
    data,
  ] = useApi(findAllSalesMemberQuery);

  const [getAllShipmentCall] = useApi(
    allShipmentFromEntityQuery,
    entityShipmentAction
  );
  const [updateEntity] = useApi(updateEntityMutation);
  const [updateNewApplication] = useApi(onboardingMutation);
  const [getSingleEntity] = useApi(getEntity, storeSingleEntityAction);

  const [getApprovedEntities] = useApi(
    getAllEntitiesQuery,
    storeAllEntitiesAction
  );
  const [getUnapprovedEntities] = useApi(
    AllOnboardingApplicationsQuery,
    storeUnapprovedEntitiesAction
  );

  const [selectedId, setSelectedId] = useState(0);
  const [memeberInformation, setMemeberInformation] = useState([]);
  const [shipmentInformation, setShipmentInformation] = useState([]);
  const [entityName, setEntityName] = useState('');

  const { id } = useParams();

  const path = window.location.pathname;

  const [selectedRowData, setSelectedRowData] = useState();
  const filterStatus = localStorage.getItem('Filter Status');
  const navigate = useNavigate();
  const { handleSubmit, register, errors, setValue, watch } =
    useHookFormSubmission(editEntitySchema);

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Updating entity information! Please wait...');
    let apiData;
    if (filterStatus === 'all') {
      apiData = {
        id: parseInt(selectedRowData?.id),
        ...values,
        salesPersonCode: selectedRowData?.salesPersonCode?.id,
        internationalRate: selectedRowData?.internationalRate,
        policyDocument: selectedRowData?.policyDocument,
      };
    } else {
      apiData = {
        streetAddress: values.address,
        nameOfBusiness: values.businessName,
        currentInsurancePolicy: selectedRowData?.currentInsurancePolicy,
        industry: selectedRowData?.industryType,
        emailAddress: values.email,
        phoneNumber: values.phone,
        nameOfPrincipal: selectedRowData?.principalName,
        website: values.website,
        tin: values.busniessTin,
        businessSince: values.since,
        referedBy: selectedRowData?.salesPersonID,
      };
    }

    const { data, errors } =
      filterStatus === 'all'
        ? await updateEntity(apiData)
        : await updateNewApplication(apiData);

    handleToast(data, errors);
    navigate(PATHS.ENTITIES);

    getUnapprovedEntities();
    getApprovedEntities();
  };

  const handleToast = (data) => {
    if (data) {
      toast.dismiss();
      toast.success('Entity information has been updated successfully', {
        duration: TOAST_TIME,
      });
    } else {
      toast.dismiss();
      toast.error('Failed to update', { duration: TOAST_TIME });
    }
  };

  const onClickCancel = () => {
    navigate(PATHS.ENTITIES);
  };

  useEffect(() => {
    if (shipments && shipments.entityShipment?.length > 0) {
      let shipmentArray = shipments.entityShipment.map((item) => {
        const deliveryDateObject = new Date(item.deliveryDate);
        const deliveryFormattedDate = deliveryDateObject.toLocaleDateString(
          'en-US',
          {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
          }
        );
        const pickupDateObject = new Date(item.pickUpDate);
        const pickupFormattedDate = pickupDateObject.toLocaleDateString(
          'en-US',
          {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
          }
        );

        const to_address = JSON.parse(item.to);

        return {
          from: item.from && item.from.nickName,
          to: to_address.name,
          mover: item.carrier && item.carrier.name,
          pickup: pickupFormattedDate,
          delivery: deliveryFormattedDate,
          value: item.totalValue,
          condirmation:
            item.confirmationId === '' ? 'N/A' : item.confirmationId,
          status: item.isDelivered === false ? 'Pending' : 'delivered',
        };
      });

      setShipmentInformation(shipmentArray);
    }
  }, [shipments]);

  useEffect(() => {
    if (selectedId && filterStatus === 'all') {
      getAllShipmentCall(parseInt(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    if (filterStatus === 'all' && selectedRowData) {
      if (selectedRowData?.entityMember?.length > 0) {
        let staffArray = selectedRowData?.entityMember.map((item) => {
          return {
            id: item.id,
            fname: item.firstName,
            lname: item.lastName,
            email: item.emailAddress,
            phone: item.phoneNumber,
            role: item.role,
            dateCreated: item.createdAt,
          };
        });
        setMemeberInformation(staffArray);
      }
      setSelectedId(selectedRowData?.id);
      setEntityName(selectedRowData?.name);
      setValue(
        'businessName',
        selectedRowData?.name ? selectedRowData?.name : 'N/A'
      );
      setValue('dba', selectedRowData?.DBA ? selectedRowData?.DBA : 'N/A');
      setValue(
        'busniessTin',
        selectedRowData?.EIN ? selectedRowData?.EIN : 'N/A'
      );
      setValue(
        'address',
        selectedRowData?.address ? selectedRowData?.address : 'N/A'
      );
      setValue(
        'phone',
        selectedRowData?.phoneNumber ? selectedRowData?.phoneNumber : 0
      );
      setValue(
        'email',
        selectedRowData?.emailAddress ? selectedRowData?.emailAddress : 'N/A'
      );
      setValue(
        'since',
        selectedRowData?.since ? selectedRowData?.since : 'N/A'
      );
      setValue(
        'website',
        selectedRowData?.website ? selectedRowData?.website : 'N/A'
      );
      setValue('donation', 0);
      setValue(
        'emailRebate',
        selectedRowData?.rebateEmail ? selectedRowData?.rebateEmail : 'N/A'
      );
      // setValue("rate", selectedRowData?.rate ? selectedRowData?.rate : 0);
      setValue(
        'rate',
        selectedRowData?.rate ? selectedRowData?.rate.toFixed(5) : 0
      );
      setValue(
        'note',
        selectedRowData?.additionalNote
          ? selectedRowData?.additionalNote
          : 'N/A'
      );
    } else {
      if (selectedRowData) {
        setEntityName(selectedRowData?.businessName);
        setValue(
          'businessName',
          selectedRowData?.businessName ? selectedRowData?.businessName : 'N/A'
        );

        setValue(
          'busniessTin',
          selectedRowData?.businessTIN ? selectedRowData?.businessTIN : 'N/A'
        );
        setValue(
          'address',
          selectedRowData?.address ? selectedRowData?.address : 'N/A'
        );
        setValue(
          'phone',
          selectedRowData?.phoneNumber ? selectedRowData?.phoneNumber : 'N/A'
        );
        setValue(
          'email',
          selectedRowData?.emailAddress ? selectedRowData?.emailAddress : 'N/A'
        );
        setValue(
          'since',
          selectedRowData?.since ? selectedRowData?.since : 'N/A'
        );
        setValue(
          'industryType',
          selectedRowData?.industryType ? selectedRowData?.industryType : 'N/A'
        );

        setValue(
          'website',
          selectedRowData?.website ? selectedRowData?.website : 'N/A'
        );
        setValue(
          'principalName',
          selectedRowData?.principalName
            ? selectedRowData?.principalName
            : 'N/A'
        );
        setValue(
          'salesPerson',
          selectedRowData?.salesPersonID
            ? selectedRowData?.salesPersonID
            : 'N/A'
        );
      }
    }
  }, [selectedRowData]);

  useEffect(() => {
    if (filterStatus === 'all') {
      getSingleEntity(parseInt(id));
    } else {
      const selectedEntity = localStorage.getItem('Selected Entity');
      if (selectedEntity != undefined && selectedEntity != 'undefined') {
        setSelectedRowData(JSON.parse(localStorage.getItem('Selected Entity')));
      }
    }
  }, [filterStatus]);

  useEffect(() => {
    if (filterStatus === 'all' && entities) {
      setSelectedRowData(entities.singleEntityDetail);
    }
  }, [entities]);

  const watchedIndustryType = watch('industryType');
  const watchedSalesPersonId = watch('salesPerson');

  useEffect(() => {
    fetchSalesMember();
  }, []);

  return {
    selectedRowData,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    memeberInformation,
    shipmentInformation,
    isDetailPage: path.includes('edit-entity'),
    filterStatus,
    entityName,
    onClickCancel,
    watchedIndustryType,
    findAllSalesMember: data?.findAllSalesMember,
    watchedSalesPersonId,
  };
};

export default UseEditEntities;
