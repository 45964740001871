import React from 'react';
import CustomContainer from '../../../../common/custom/CustomContainer';
import {
  CustomButton,
  CustomInput,
  CustomText,
  ValidationErrorText,
  CustomDropdown,
  CustomCheckbox,
} from '../../../../common/custom';

import { useSignUpFinalStep } from './useSignUpFinalStep';

const SignUpFinalStep = ({ decreaseStepHandler }) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    salesPersons,
    setValue,
    signUpApplicationLoading,
    getTermsAndConditionValue,
  } = useSignUpFinalStep();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col my-8 gap-6 w-full'
    >
      <div className='flex flex-col sm:flex-row justify-center gap-6'>
        <CustomContainer className='w-full'>
          <CustomInput
            name='businessSince'
            register={register}
            placeholder='Enter your last business'
            label='Business since(optional)'
          />
          {errors.businessSince && (
            <ValidationErrorText>
              {errors.businessSince.message}
            </ValidationErrorText>
          )}
        </CustomContainer>

        <CustomContainer className='w-full'>
          <CustomText as='label'>
            Reffered by<span className='text-foundation-brown'>*</span>
          </CustomText>
          <div className='mt-[8px]'>
            <CustomDropdown
              placeholder='Enter your referral name'
              options={salesPersons ? salesPersons : []}
              setValue={setValue}
              name='referedBy'
            />
          </div>
          {errors.referedBy && (
            <ValidationErrorText>
              {errors.referedBy.message}
            </ValidationErrorText>
          )}
        </CustomContainer>
      </div>
      <div className='flex flex-col sm:flex-row gap-[24px]'>
        <CustomContainer className='w-full'>
          <CustomInput
            name='currentInsurancePolicy'
            register={register}
            placeholder='Enter your insurance policy'
            label='Current insurance policy(optional)'
          />
          {errors.currentInsurancePolicy && (
            <ValidationErrorText>
              {errors.currentInsurancePolicy.message}
            </ValidationErrorText>
          )}
        </CustomContainer>
      </div>
      <CustomCheckbox
        getTermsAndConditionValue={getTermsAndConditionValue}
        checkboxText='I accept terms and conditions and privacy policy'
      />
      <div className='flex flex-col sm:flex-row  justify-center mt-2  max-w-[349px] gap-[12px] mx-auto '>
        <CustomButton
          onClick={() => decreaseStepHandler()}
          type='button'
          text='Back'
          className='white-bg-btn'
        />
        <CustomButton
          disabled={signUpApplicationLoading ? true : false}
          text='Apply Now'
          className='brown-bg-btn'
        />
      </div>
    </form>
  );
};

export default SignUpFinalStep;
