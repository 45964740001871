import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useApi from "../../../hook/useApi";
import { allShipmentFromEntityQuery, getEntity } from "../../../api";
import { storeSingleEntityAction, entityShipmentAction } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const useEntityShipment = () => {
  const { shipments } = useSelector((state) => state);
  const { entities } = useSelector((state) => state);

  const [getAllShipmentCall] = useApi(
    allShipmentFromEntityQuery,
    entityShipmentAction
  );
  const [getSingleEntity] = useApi(getEntity, storeSingleEntityAction);

  const { id } = useParams();

  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState(0);
  const [shipmentInformation, setShipmentInformation] = useState([]);

  useEffect(() => {
    if (entities && entities.singleEntityDetail) {
      setSelectedId(entities.singleEntityDetail.id);
    }
  }, [entities]);

  const onClickBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (shipments && shipments.entityShipment?.length > 0) {
      let shipmentArray = shipments.entityShipment.map((item) => {
        const deliveryDateObject = new Date(item.deliveryDate);
        const deliveryFormattedDate = deliveryDateObject.toLocaleDateString(
          "en-US",
          {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          }
        );

        const pickupDateObject = new Date(item.pickUpDate);
        const pickupFormattedDate = pickupDateObject.toLocaleDateString(
          "en-US",
          {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          }
        );
        const to_address = JSON.parse(item.to);
        return {
          from: item.from && item.from.nickName,
          to: to_address.name,
          mover: item.carrier && item.carrier.name,
          pickup: pickupFormattedDate,
          delivery: deliveryFormattedDate,
          value: item.totalValue,
          condirmation:
            item.confirmationId === "" ? "N/A" : item.confirmationId,
          status: item.isDelivered? "Delivered" : "In-Transit",
        };
      });

      setShipmentInformation(shipmentArray);
    }
  }, [shipments]);

  useEffect(() => {
    if (selectedId) {
      getAllShipmentCall(parseInt(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    getSingleEntity(parseInt(id));
  }, []);

  return {
    shipmentInformation,
    onClickBack,
  };
};

export default useEntityShipment;
