import React from 'react';
import { saveAs } from 'file-saver';
import { excelIcon } from '../../../assets';
import ExcelJS from 'exceljs';
import imageUrl from './imageUrl';
import { FORMATE_AMOUNT } from '../../../helpers/helpers';

const DownloadExcelButton = ({ data }) => {
  const filename = `Shipment-Report-${
    new Date()
      .toISOString()
      .replace(/[-:]/g, '')
      .replace('T', '-')
      .split('.')[0]
  }.xlsx`;

  // console.log('data:', data);
  // // Simple download with all data provided
  // const handleDownload = () => {
  //   console.log('data:', data);
  //   const ws = XLSX.utils.json_to_sheet(data);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  //   const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   saveAs(blob, `${filename}.xlsx`);
  // };

  // // Download Data for Selected Columns only
  // const handleDownload = () => {
  //   // Define the columns you want to include in the Excel file
  //   const columns = [
  //     'Insured',
  //     'Confirmation #',
  //     'Merchandise Type',
  //     'Customer Ref #',
  //     'From Address',
  //     'To Address',
  //     'Means Transport',
  //     'Pick Up Date',
  //     'Delivery Date',
  //     'Insured Value',
  //   ];

  //   // Extract only the required fields from each object
  //   const extractedData = data.map((item) => {
  //     const extractedItem = {};
  //     columns.forEach((column) => {
  //       extractedItem[column] = item[column] || '';
  //     });
  //     return extractedItem;
  //   });

  //   // Create a worksheet from the extracted data
  //   const ws = XLSX.utils.json_to_sheet(extractedData);

  //   // Create a new workbook and add the worksheet
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   // Convert the workbook to an Excel buffer
  //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  //   // Create a Blob from the buffer and save it as a file
  //   const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  //   saveAs(blob, `${filename}.xlsx`);
  // };

  // // Download with with styles applied on Excel Sheet
  // const handleDownload = async () => {
  //   // Create a new workbook
  //   const workbook = new ExcelJS.Workbook();

  //   // Add a worksheet to the workbook
  //   const worksheet = workbook.addWorksheet('Sheet1');

  //   // Define column headers
  //   const columns = [
  //     'Insured',
  //     'Confirmation #',
  //     'Merchandise Type',
  //     'Customer Ref #',
  //     'From Address',
  //     'To Address',
  //     'Means Transport',
  //     'Pick Up Date',
  //     'Delivery Date',
  //     'Insured Value',
  //   ];

  //   // Add column headers with bold styling and background color
  //   const headerRow = worksheet.addRow(columns);
  //   headerRow.font = {
  //     bold: true,
  //     name: 'Century Gothic',
  //     color: { argb: 'FFFFFF' },
  //   };
  //   headerRow.fill = {
  //     type: 'pattern',
  //     pattern: 'solid',
  //     fgColor: { argb: '333F4F' },
  //   };

  //   // Add data rows
  //   data.forEach((item) => {
  //     const rowValues = columns.map((column) => item[column] || '');
  //     const row = worksheet.addRow(rowValues);
  //     row.eachCell((cell) => {
  //       cell.alignment = { vertical: 'middle', horizontal: 'left' };
  //       cell.font = { name: 'Century Gothic' };
  //       cell.border = {
  //         top: { style: 'thin' },
  //         right: { style: 'thin' },
  //         bottom: { style: 'thin' },
  //         left: { style: 'thin' },
  //       };
  //       if (cell.value && cell.value.toString().length > 50) {
  //         cell.alignment = { vertical: 'middle', horizontal: 'left' };
  //       }
  //     });
  //   });

  //   // Auto-adjust column widths
  //   worksheet.columns.forEach((column) => {
  //     let maxWidth = 0;
  //     column.eachCell({ includeEmpty: true }, (cell) => {
  //       const length = cell.value ? cell.value.toString().length : 0;
  //       if (length > maxWidth) {
  //         maxWidth = length;
  //       }
  //     });
  //     column.width = maxWidth < 10 ? 10 : maxWidth + 2;
  //   });

  //   // Apply padding on all cells
  //   worksheet.eachRow((row) => {
  //     row.eachCell((cell) => {
  //       cell.padding = { top: 5, left: 5, bottom: 5, right: 5 };
  //     });
  //   });

  //   // Convert the workbook to a buffer
  //   const buffer = await workbook.xlsx.writeBuffer();

  //   // Create a Blob from the buffer and save it as a file
  //   const blob = new Blob([buffer], { type: 'application/octet-stream' });
  //   saveAs(blob, `${filename}.xlsx`);
  // };
  const handleDownload = async () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet('Shipping Report');

    // Add 6 empty rows at the beginning
    for (let i = 0; i < 6; i++) {
      worksheet.addRow([]);
    }

    // Set the fill color of each cell to white and remove borders
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        // cell.fill = {
        //   type: 'pattern',
        //   pattern: 'solid',
        //   fgColor: { argb: 'FFFFFF' },
        // };
        cell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFF' } },
        };
      });
    });

    // Add Shipment Report text
    const titleCell = worksheet.getCell('K4');
    titleCell.value = 'Shipment Report';
    titleCell.font = {
      bold: true,
      size: 24,
      color: { argb: 'A5A5A5' },
      name: 'Century Gothic',
    };
    titleCell.alignment = { horizontal: 'right' };

    // Add Date text
    const dateCell = worksheet.getCell('I7');
    dateCell.value = 'Date:';
    dateCell.font = { size: 12, name: 'Century Gothic' };
    dateCell.alignment = { horizontal: 'right', vertical: 'bottom' };

    // Merge cells and add current date
    worksheet.mergeCells('J7:K7');
    const currentDate = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    const mergedCell = worksheet.getCell('J7');
    mergedCell.value = currentDate;
    mergedCell.font = { size: 12, name: 'Century Gothic' };
    mergedCell.alignment = { horizontal: 'right', vertical: 'bottom' };

    if (imageUrl) {
      const imageId = workbook.addImage({
        base64: imageUrl,
        extension: 'png',
      });
      worksheet.addImage(imageId, {
        tl: { col: 1, row: 1 },
        br: { col: 4, row: 6 },
      });
    }

    // Define column headers
    const columns = [
      '',
      'Insured',
      'Confirmation #',
      'Merchandise Type',
      'Customer Ref #',
      'From Address',
      'To Address',
      'Means Transport',
      'Pick Up Date',
      'Delivery Date',
      'Insured Value',
      'Rebate',
    ];

    // Add column headers with bold styling and background color
    const headerRow = worksheet.addRow(columns);
    headerRow.eachCell((cell, index) => {
      cell.font = {
        bold: true,
        name: 'Century Gothic',
        size: 11,
        color: { argb: 'FFFFFF' },
      };
      cell.fill = {
        type: 'pattern',
        // pattern: 'solid',
        // fgColor: { argb: '333F4F' },
        pattern: index === 1 ? 'none' : 'solid', // Apply background color only to data columns
        fgColor: index === 1 ? null : { argb: '333F4F' }, // Background color for data columns
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFF' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFF' } },
      };
    });
    headerRow.height = 30;

    // Initialize sum variables for Insured Value and Rebate
    let insuredValueSum = 0;
    let rebateSum = 0;

    // Add data rows
    data.forEach((item) => {
      // const rowValues = [null, ...columns.map((column) => item[column] || '')]; // Add null for the empty cell
      const rowValues = columns.map((column) => item[column] || '');
      const row = worksheet.addRow(rowValues);

      // Convert insured value and rebate to numeric for summing
      const insuredValue =
        parseFloat(item['Insured Value'].replace(/[^0-9.-]+/g, '')) || 0;
      const rebate = parseFloat(item['Rebate'].replace(/[^0-9.-]+/g, '')) || 0;

      // Add to the sums
      insuredValueSum += insuredValue;
      rebateSum += rebate;

      row.eachCell((cell, index) => {
        cell.font = { name: 'Century Gothic', size: 10 };
        cell.border = {
          top: { style: index !== 1 && 'thin' },
          right: { style: index !== 1 && 'thin' },
          bottom: { style: index !== 1 && 'thin' },
          left: { style: index !== 1 && 'thin' },
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal:
            index === 5 || index === 11
              ? 'center'
              : index === 9 || index === 10
              ? 'right'
              : 'left',
        };
        cell.fill = { type: 'pattern', pattern: 'none' };
      });
      row.height = 24; // Increase cell height
    });

    // Add a blank row before the totals
    worksheet.addRow([]);

    // Add totals row for Insured Value and Rebate
    const totalRow = worksheet.addRow([
      '',
      '', // Leave empty cells for other columns
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Total:', // "Total" label
      FORMATE_AMOUNT(insuredValueSum), // Total Insured Value
      FORMATE_AMOUNT(rebateSum), // Total Rebate
    ]);

    // Style the totals row
    totalRow.eachCell((cell, index) => {
      if (index >= 10) {
        cell.font = { bold: true, name: 'Century Gothic', size: 11 };
        cell.alignment = { vertical: 'middle', horizontal: 'right' };
        cell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFF' } },
        };
      }
    });

    // Auto-adjust column widths
    worksheet.columns.forEach((column) => {
      let maxWidth = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? cell.value.toString().length : 0;
        if (length > maxWidth) {
          maxWidth = length;
        }
      });
      column.width = maxWidth < 16 ? 16 : maxWidth + 2;
    });

    // Convert the workbook to a buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a Blob from the buffer and save it as a file
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `${filename}`);
  };

  return (
    <button
      onClick={handleDownload}
      className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] text-[#101828] flex items-center h-[48px] min-w-[120px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
    >
      <img src={excelIcon} className='w-4' /> Download
    </button>
  );
};

export default DownloadExcelButton;
