import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  approveShipmentMutation,
  cancelShipmentMutation,
  getAllShipments,
} from '../../../api';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import {
  FORMATE_AMOUNT,
  FORMATE_DATE,
  PARSIFY,
  PREMIUM,
} from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import useOutsideClick from '../../../hook/useOutsideClick';
import { storeAllShipmentsAction } from '../../../store';

const usePendingShipmentsList = () => {
  const { shipments } = useSelector((state) => state);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shipmentActionClickedData, setShipmentActionClickedData] =
    useState(null);
  const [shipmentActionClickedId, setShipmentActionClickedId] = useState(null);
  const actionMenuRef = useRef(null);

  const [cancelShipmentCall] = useApi(cancelShipmentMutation);
  const [approveShipmentCall] = useApi(approveShipmentMutation);

  const [getAllShipmentsCall, loading] = useApi(
    getAllShipments,
    storeAllShipmentsAction
  );
  // useEffect(() => getAllShipmentsCall(), [])
  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));
  const modifiedShipmentsData = shipments?.allShipments
    .filter((shipment) => shipment.approved === false)
    ?.map((shipment) => {
      return {
        ...shipment,
        id: shipment.id,
        entity: shipment?.location.entity.name || '---',
        from: shipment?.from?.nickName,
        recipient: (PARSIFY(shipment.to) && PARSIFY(shipment.to).name) || '---',
        carrier: shipment.carrier && shipment.carrier.name,
        pickup: FORMATE_DATE(shipment?.pickUpDate, 'mm/dd/yy'),
        delivery: FORMATE_DATE(shipment?.deliveryDate, 'mm/dd/yy'),
        value: FORMATE_AMOUNT(shipment.totalValue),
        premium: shipment?.premium
          ? FORMATE_AMOUNT(shipment?.premium)
          : 'UNPAID',
        // premium: PREMIUM(
        //   shipment.totalValue,
        //   shipment &&
        //     shipment.location &&
        //     shipment.location.entity &&
        //     (PARSIFY(shipment.to).country == 'United States' ||
        //       PARSIFY(shipment.to).country == 'USA')
        //     ? shipment.location.entity.rate
        //     : shipment.location.entity.internationalRate
        // ),
        confirmation: shipment.confirmationId ? shipment.confirmationId : 'N/A',
        reference:
          (PARSIFY(shipment.to) && PARSIFY(shipment.to).reference) || '---',
      };
    });

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setShipmentActionClickedId(parseInt(data?.id));
    setShipmentActionClickedData(data);

    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
  };

  const approveShipmentHandler = async (shipmentId) => {
    const findByIDShipment = await shipments.allShipments.find(
      (shipment) => parseInt(shipment.id) === shipmentId
    );

    toast.dismiss();
    toast.loading('Approving shipment...');
    const { data, errors } = await approveShipmentCall({
      shipmentId,
      // data: {
      //   ...findByIDShipment,
      //   approved: true,
      // },
    });
    if (data) {
      getAllShipmentsCall();
      // console.log('YUNNN');
      toast.dismiss();
      toast.success('Shipment Approved successfully', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in approving shipment', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  const cancelShipmentHandler = async (shipmentId) => {
    toast.dismiss();
    toast.loading('Deleting shipment...');
    const { data, errors } = await cancelShipmentCall(shipmentId);
    if (data) {
      getAllShipmentsCall();
      toast.dismiss();
      toast.success('Shipment deleted successfully', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('error in canceling shipment', { duration: TOAST_TIME });
    }

    return { data, errors };
  };

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  return {
    data: modifiedShipmentsData,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    showDeleteModal,
    setShowDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    cancelShipmentHandler,
    shipmentActionClickedData,
    shipmentActionClickedId,
    approveShipmentHandler,
    loading
  };
};

export default usePendingShipmentsList;
