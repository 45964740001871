import { closeIcon, editIcon, plusBrown, viewIcon } from '../../../../assets';
import {
  CustomButton,
  CustomHeading,
  CustomInput,
  CustomModal,
  CustomText,
  ValidationErrorText,
} from '../../../../common/custom';
import { CustomIcon, CustomImage } from '../../../../common/customAssets';

const AddNewModal = ({
  onClose,
  title,
  show,
  onSubmit,
  handleSubmit,
  register,
  errors,
  trigger,
  setValue,
  data,
  pass,
  setPass,
  confirmPass,
  setConfirmPass,
  passErr,
}) => {
  return (
    <CustomModal
      open={show}
      onClose={onClose}
      className='max-w-[1219px] p-8 overflow-y-auto bg-slate-700'
    >
      <div className='!pt-4 mt-[28.5px] pb-6 min-w-[400px] p-3 shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] rounded-xl bg-[#fff]'>
        <div className='flex justify-between'>
          <div className='flex justify-between items-center gap-3'>
            <div className='border-2 border-[#77553D] rounded-full'>
              <CustomIcon
                icon={plusBrown}
                alt='add'
                className='w-[16px] h-[16px]'
              />
            </div>
            <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
              {title}
            </div>
          </div>
          <div onClick={onClose} className='hover:cursor-pointer'>
            <CustomImage
              img={closeIcon}
              alt='close'
              className='w-[30px] h-[30px]'
            />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          {handleSubmit && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {title?.toLowerCase()?.includes('Sales'?.toLowerCase()) ? (
                <div>
                  <div className='my-4'>
                    <CustomInput
                      register={register}
                      name='firstName'
                      placeholder={data ? data?.firstName : 'First Name'}
                      type='text'
                      errors={errors}
                      label={'First Name'}
                      trigger={trigger}
                      setValue={setValue}
                      required
                      readOnly={
                        title?.toLowerCase()?.includes('View'?.toLowerCase())
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='my-4'>
                    <CustomInput
                      register={register}
                      name='lastName'
                      placeholder={data ? data?.lastName : 'Last Name'}
                      type='text'
                      errors={errors}
                      label={'Last Name'}
                      trigger={trigger}
                      setValue={setValue}
                      required
                      readOnly={
                        title?.toLowerCase()?.includes('View'?.toLowerCase())
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className='my-4'>
                    <CustomInput
                      register={register}
                      name='email'
                      placeholder={data ? data?.emailAddress : 'Email'}
                      type='email'
                      errors={errors}
                      label={'Email'}
                      trigger={trigger}
                      setValue={setValue}
                      required
                      readOnly={
                        title?.toLowerCase()?.includes('View'?.toLowerCase())
                          ? true
                          : false
                      }
                    />
                  </div>
                  {title?.toLowerCase()?.includes('New'.toLowerCase()) && (
                    <div className='my-4'>
                      <CustomInput
                        register={register}
                        name='password'
                        placeholder={'Password'}
                        type='password'
                        errors={errors}
                        label={'Password'}
                        trigger={trigger}
                        setValue={setValue}
                        required
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className='my-4'>
                  <div>
                    <CustomInput
                      register={register}
                      name='name'
                      placeholder={data ? data?.name : 'Name'}
                      required
                      type='text'
                      errors={errors}
                      label={'Name'}
                      trigger={trigger}
                      setValue={setValue}
                    />
                  </div>
                </div>
              )}
              {title?.toLowerCase()?.includes('Carrier'?.toLowerCase()) && (
                <div className='my-4'>
                  <div>
                    <CustomInput
                      register={register}
                      name='address'
                      placeholder={data ? data?.address : 'Address'}
                      type='text'
                      errors={errors}
                      label={'Address'}
                      trigger={trigger}
                      setValue={setValue}
                    />
                  </div>
                </div>
              )}

              <div className='flex justify-center mt-4 gap-4'>
                <CustomButton
                  type='button'
                  className='white-bg-btn'
                  variant='outline'
                  text='Cancel'
                  onClick={onClose}
                />
                <CustomButton
                  className='brown-bg-btn'
                  type='submit'
                  text='Submit'
                />
              </div>
            </form>
          )}

          {title?.toLowerCase()?.includes('Password'.toLowerCase()) && (
            <>
              <div className='my-2'>
                <CustomInput
                  placeholder={'New Password'}
                  type='password'
                  label={'New Password'}
                  value={pass}
                  onChange={(e) => setPass(e?.target?.value)}
                  required
                />
              </div>
              <div className='my-2'>
                <CustomInput
                  placeholder={'Confirm New Password'}
                  type='password'
                  label={'Confirm New Password'}
                  required
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e?.target?.value)}
                />
              </div>
              {passErr && (
                <div className='mt-1 mb-2'>
                  <ValidationErrorText>{passErr}</ValidationErrorText>
                </div>
              )}
              <div className='flex justify-center mt-4 gap-4'>
                <CustomButton
                  type='button'
                  className='white-bg-btn'
                  variant='outline'
                  text='Cancel'
                  onClick={onClose}
                />
                <CustomButton
                  className='brown-bg-btn'
                  type='submit'
                  text='Submit'
                  onClick={onSubmit}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </CustomModal>
  );
};
export default AddNewModal;
