import { gql } from '@apollo/client';
import client from '../apollo.';

export const addMemberMutation = async (addMembersData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation CreateEntityMember(
        $createEntityMemberInput: EntityMemberCreateInput!
      ) {
        createEntityMember(createEntityMemberInput: $createEntityMemberInput) {
          id
          firstName
        }
      }
    `,
    variables: {
      createEntityMemberInput: {
        emailAddress: addMembersData.emailAddress,
        additionalNote: '',
        firstName: addMembersData.firstName,
        lastName: addMembersData.lastName,
        password: addMembersData.password,
        phoneNumber: addMembersData.phoneNumber,
        entity: {
          connect: {
            id: addMembersData.entityId,
          },
        },
        location: {
          connect: {
            id: addMembersData.locationId,
          },
        },
      },
    },
  });
  return { data, errors };
};

export const getAllMembersQuery = async (entityId) => {
  const { data, errors } = await client.query({
    query: gql`
      query AllEntityMember($allEntityMemberId: Int!) {
        AllEntityMember(id: $allEntityMemberId) {
          emailAddress
          createdAt
          firstName
          role
          id
          phoneNumber
          lastName
        }
      }
    `,
    variables: {
      allEntityMemberId: entityId,
    },
  });
  return { data, errors };
};

export const acceptTermEntityMemberMutation = async (id) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $updateEntityMemberInput: EntityMemberUpdateInput!
        $updateEntityMemberUniqueInput: EntityMemberWhereUniqueInput!
      ) {
        updateEntityMember(
          updateEntityMemberInput: $updateEntityMemberInput
          updateEntityMemberUniqueInput: $updateEntityMemberUniqueInput
        ) {
          id
        }
      }
    `,
    variables: {
      updateEntityMemberInput: {
        termsAccepted: {
          set: true,
        },
      },
      updateEntityMemberUniqueInput: {
        id: id,
      },
    },
  });
  return data;
};

// export const approveApplicationMutation = async (apiData) => {
//   const { data, errors } = await client.mutate({
//     mutation: gql`
//       mutation Mutation($createApplicationInput: ApproveApplicationInput!) {
//         approveApplication(createApplicationInput: $createApplicationInput) {
//           id
//           name
//         }
//       }
//     `,
//     variables: {
//       createApplicationInput: {
//         additionalNote: apiData.additionalNote,
//         address: apiData.address,
//         businessDBA: apiData.businessDBA,
//         businessName: apiData.businessName,
//         businessTIN: apiData.businessTIN,
//         currentInsurancePolicy: apiData.currentInsurancePolicy,
//         emailAddress: apiData.emailAddress,
//         industryType: apiData.industryType,
//         phoneNumber: apiData.phoneNumber,
//         principalName: apiData.principalName,
//         premiumRate: apiData.premiumRate,
//         salesPersonID: apiData.salesPersonID,
//         website: apiData.website,
//         since: apiData.since,
//         slug: apiData.slug,
//         donation: apiData.donation,
//         rebateEmail: apiData.rebateEmailAddress,
//         rebateAddress: apiData.rebateAddress,
//       },
//     },
//   });
//   return { data, errors };
// };

export const approveApplicationMutation = async (apiData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation Mutation($createApplicationInput: ApproveApplicationInput!) {
        approveApplication(createApplicationInput: $createApplicationInput)
      }
    `,
    variables: {
      createApplicationInput: {
        additionalNote: apiData.additionalNote,
        address: apiData.address,
        businessDBA: apiData.businessDBA,
        businessName: apiData.businessName,
        businessTIN: apiData.businessTIN,
        currentInsurancePolicy: apiData.currentInsurancePolicy,
        emailAddress: apiData.emailAddress,
        industryType: apiData.industryType,
        phoneNumber: apiData.phoneNumber,
        principalName: apiData.principalName,
        premiumRate: apiData.premiumRate,
        salesPersonID: apiData.salesPersonID,
        website: apiData.website,
        since: apiData.since,
        slug: apiData.slug,
        donation: apiData.donation,
        rebateEmail: apiData.rebateEmailAddress,
        rebateAddress: apiData.rebateAddress,
      },
    },
  });
  return { data, errors };
};
