// import { useEffect } from 'react';

// const useOutsideClick = (ref, callback) => {
//   useEffect(() => {
//     if (ref.current && !ref.current.contains(event.target)) {
//       callback();
//     }
//     const handleClickOutside = () => {
//       callback();
//     };

//     // Attach the event listener to the document

//     document.addEventListener('click', handleClickOutside);

//     // Cleanup the event listener on component unmount
//     return () => {
//       document.removeEventListener('click', handleClickOutside);
//     };
//   }, [ref, callback]);
// };

// export default useOutsideClick;

import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the event target exists and if it's not inside the ref element

      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
