import CustomersDetailModal from './customer-details-modal';
import DeleteModal from './delete-modal';
import PaymentMethodDescriptonMethodModal from './payment-method-description-modal';

import ChangeShipmentStatusModal from './change-shipment-status-modal';

export {
  DeleteModal,
  PaymentMethodDescriptonMethodModal,
  CustomersDetailModal,
  ChangeShipmentStatusModal,
};
