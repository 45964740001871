import React from 'react';

import { DesktopNavbar } from './components';
import MobileNavbar from './components/mobileNavbar';

const Navbar = () => {
  return (
    <>
      <div className='lg:hidden'>
        <MobileNavbar />
      </div>
      <DesktopNavbar />
    </>
  );
};

export default Navbar;
