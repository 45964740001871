import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileNavBarAction } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';

export const useMobileNavbar = () => {
  const { ui } = useSelector((state) => state);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem('token');
    navigate(PATHS.DEFAULT_LOGIN);
  };
  const toggleMenu = () => {
    dispatch(toggleMobileNavBarAction());
  };

  return {
    logout,
    toggleMobileNavBarAction,
    mobileNavbarOpen: ui.mobileNavbarOpen,
    toggleMenu,
  };
};
