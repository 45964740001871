import * as yup from 'yup';

export const reportShipmentStepOneSchema = yup.object().shape({
  entity: yup.string().required('Entity Name is required'),
  entityMember: yup.string().required('Entity Member is required'),
  from: yup.string().required('From Address is required'),
  destination: yup.string().required('Destination Address is required'),
  reference: yup
    .string()
    // .number()
    // .typeError('Reference must be a number')
    .required('Reference is required'),
  // streetAddress: yup.string().required('Street Address is required'),
  unitNumber: yup.string().required('Unit Number required'),
  // city: yup.string().required('City is required'),
  zipCode: yup
    .string()
    // .matches(/^\d{5}$/, 'Zip Code must be a valid 5-digit number')
    .required('Zip Code is required'),
  // state: yup.string().required('State is required'),
});
