import React, { useRef, useState } from "react";
import { CustomIcon } from "../../../common/customAssets";
import {
  chevronDown,
  logoutIcon,
  userAccountAvatar,
  userCircle,
} from "../../../assets";
import { CustomButton } from "../../../common/custom";

import useOutsideClick from "../../../hook/useOutsideClick";
import { PATHS } from "../../../constants/paths";
import { useNavigate } from "react-router-dom";
const User = ({ type, logout }) => {
  const navigate = useNavigate();
  const [toggleLogoutDropDown, setToggleLogoutDropDown] = useState(false);
  const userComponenetRef = useRef(null);

  useOutsideClick(userComponenetRef, () => setToggleLogoutDropDown(false));
  return (
    // <div
    //   onClick={() => setToggleLogoutDropDown((prevState) => !prevState)}
    //   className='cursor-pointer  relative max-w-[32px] w-full rounded-full bg-foundation-gray h-[32px] flex justify-center items-center'
    // >
    <div
      onClick={(e) => {
        e.stopPropagation();
        setToggleLogoutDropDown((prevState) => !prevState);
      }}
      className="cursor-pointer rounded-[6px]  relative  bg-foundation-gray w-full h-[32px] flex justify-center items-center gap-[6px] "
    >
      <CustomIcon icon={userCircle} />
      {toggleLogoutDropDown ? (
        <div
          ref={userComponenetRef}
          className={` cursor-default flex items-center pl-[14px] w-[161px] min-h-[56px] z-10  bg-pure-white absolute 
          ${
            type
              ? "left-[-100px] top-[50px] translate-x-[100px] "
              : "right-[-100px] top-[50px] translate-x-[-100px]"
          } 

            rounded-[8px] shadow-lg   `}
        >
          <div className=" py-1 flex flex-col gap-3  w-full ">
            {" "}
            <CustomButton
              className="p-1 bg-hover text-[16px] font-[inter] text-[#101828] flex items-center gap-2 mb"
              text="My Account"
              icon={userAccountAvatar}
              type="button"
              onClick={() => {
                navigate(PATHS.PROFILE);
              }}
            />
            <CustomButton
              className="p-1 bg-hover text-[16px] font-[inter] text-[#101828] flex items-center gap-2"
              text="Logout"
              icon={logoutIcon}
              type="button"
              onClick={logout}
            />
          </div>
        </div>
      ) : null}

      <CustomIcon
        className={`${toggleLogoutDropDown ? "rotate-180" : "-rotate-0"}`}
        icon={chevronDown}
      />
    </div>
  );
};

export default User;
