import React from 'react';
import { Link } from 'react-router-dom';
import { ceastaLogoWhite } from '../../../../assets';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../../common/customAssets';
import { PATHS } from '../../../../constants/paths';
import MenuList from '../MenuList';
import User from '../User';
import { useDesktopNavbar } from './useDesktopNavbar';

const DesktopNavbar = () => {
  const { logout } = useDesktopNavbar();
  return (
    <nav className='bg-foundation-brown min-h-[80px]  lg:flex items-center hidden'>
      <CustomContainer>
        <CustomContainer className='flex justify-between items-center gap-1'>
          <Link
            className=''
            to={PATHS.SHIPMENTS}
          >
            <CustomIcon
              icon={ceastaLogoWhite}
              className='w-[226px] h-[43px]'
            />
          </Link>
          <MenuList />
          <div
            className='flex items-center 
           gap-[20px] max-w-[68px] w-full'
          >
            <User logout={logout} />
          </div>
        </CustomContainer>
      </CustomContainer>
    </nav>
  );
};

export default DesktopNavbar;
