import React from 'react';
import FormImageLayout from '../component/FormImageLayout';
import { CustomButton, CustomInput, CustomText } from '../../../common/custom';
import { useResetPassword } from './useResetPassword';

import { PATHS } from '../../../constants/paths';
import CustomHeading from '../../../common/custom/CustomHeading';
import BackButton from '../../../common/custom/BackButton';
import { backIcon } from '../../../assets';
import { useLocation } from 'react-router-dom';

const ResetPassword = () => {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const { handleSubmit, onSubmit, register, errors, resetPasswordLoading } =
    useResetPassword(email);

  return (
    <FormImageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomHeading as='h1'>Set new password</CustomHeading>
        <CustomText
          as='p'
          className='text-para-black text-small-para md:text-medium-para font-roboto font-[600] text-center mt-[12px]'
        >
          Your new passwords must be different to previously password
        </CustomText>
        <div className=' mt-[28px] mb-[24px]'>
          <CustomInput
            name='verificationCode'
            register={register}
            placeholder='Enter your verification code'
            label='Verification Code'
            errors={errors}
          />
        </div>
        <div className='mb-[24px]'>
          <CustomInput
            name='newPassword'
            register={register}
            placeholder='Enter your pasword'
            label='New Password'
            errors={errors}
          />
        </div>
        <div className='mb-[30px]'>
          <CustomInput
            name='confirmPassword'
            register={register}
            placeholder='Confirm your pasword'
            label='Confirm New Password'
            errors={errors}
          />
        </div>

        <CustomButton
          disabled={resetPasswordLoading ? true : false}
          text='Reset Password'
        />
        <div className=' flex justify-center mt-[32px]'>
          <BackButton
            link={PATHS.DEFAULT_LOGIN}
            icon={backIcon}
          />
        </div>
      </form>
    </FormImageLayout>
  );
};

export default ResetPassword;
