import { createSlice } from "@reduxjs/toolkit";
import { storeUnapprovedEntities } from "./onBoradingAction";

const initialState = {
  newEntities: [],
};

const onBoardingSlice = createSlice({
  name: "onboardingEntites",
  initialState,
  reducers: {
    storeUnapprovedEntitiesAction: storeUnapprovedEntities,
  },
});

export const onBoardingReducer = onBoardingSlice.reducer;
export const { storeUnapprovedEntitiesAction } = onBoardingSlice.actions;
