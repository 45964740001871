import axios from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FORMATE_DATE,
  HANDLE_FILE_SIZE_CHECK,
  PARSIFY,
} from '../../../../helpers/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';
import useApi from '../../../../hook/useApi';
import { deliverShipmentMutation } from '../../../../api/services/shipments.service';
import { useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { concludeTransitSchema } from './schema';
import { getAllEntitiesQuery } from '../../../../api';

function useConcludeTransit() {
  const { id } = useParams();
  const [getApprovedEntities] = useApi(getAllEntitiesQuery);
  const [concludeTransit] = useApi(deliverShipmentMutation);

  let { handleSubmit, register, errors, trigger, setValue } =
    useHookFormSubmission(concludeTransitSchema);

  const { shipments } = useSelector((state) => state);
  const [selectedItem, setSelectedItem] = useState(null);

  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [transitFile, setTransitFile] = useState('');

  useEffect(() => {
    const getEntities = async () => {
      let _entities = await getApprovedEntities();
      _entities = _entities?.data?.allEntity?.map((item) => ({
        label: item.name,
        name: item.name,
        value: item.id,
        id: item.id,
      }));
      setEntities(_entities);
    };
    getEntities();
  }, []);

  const handleUploadTransit = (file) => {
    const document = HANDLE_FILE_SIZE_CHECK(20, file);
    setIsLoading(true);
    toast.loading('Please wait. File is uploading...');
    if (document) {
      setTransitFile(file);
      const formData = new FormData();
      formData.append('upload', file);

      axios
        .post('https://api.ceasta.com/fileUpload', formData)
        .then((response) => {
          setTransitFile([...response.data][0].location);
          toast.dismiss();
          setIsLoading(false);
        })
        .catch(() => {
          toast.dismiss();
          setIsLoading(false);
        });
    }
  };
  // console.log(shipments?.allShipments);

  const toBeConcluded = shipments?.allShipments
    ?.filter((shipment) => shipment?.isDelivered === false)
    .map((shipment, index) => {
      return {
        id: shipment.id,
        value: shipment.id,
        name: `${index + 1}. Confirmation ID: ${
          shipment.confirmationId
        }, Reference: ${PARSIFY(shipment.to)?.reference}, from: ${
          shipment?.from?.nickName
        }(${shipment.location.name}) to: ${
          PARSIFY(shipment.to).name
        }, delivered on ${FORMATE_DATE(shipment?.deliveryDate)}`,
        label: `${index + 1}. Confirmation ID: ${
          shipment.confirmationId
        }, Reference: ${PARSIFY(shipment.to)?.reference}, from: ${
          shipment?.from?.nickName
        }(${shipment.location.name}) to: ${
          PARSIFY(shipment.to).name
        }, delivered on ${FORMATE_DATE(shipment?.deliveryDate)}`,
        location: PARSIFY(shipment.to)?.name,
        additionalNote: shipment.additionalNote,
        locationId: shipment.locationId,
        entityId: shipment.location.entity.id,
      };
    });

  // console.log(toBeConcluded);

  const handleUploadTransitCancel = () => {
    setTransitFile('');
  };

  const handleCancel = () => {
    navigate(PATHS.CLAIMS);
  };

  const onSubmit = async (values) => {
    try {
      toast.success('Please wait! Concluding the shipment...');
      // e.preventDefault();
      // console.log(values);

      const { data } = await concludeTransit({
        shipmentId: Number(values?.selectShipment),
        documents: transitFile,
      });
      if (data.deliverShipment.id) {
        navigate(PATHS.CLAIMS);
        toast.success('Concluded Successfully');
      } else {
        toast.error('Something went wrong');
      }
    } catch (err) {
      if (err?.message?.includes('Cannot read properties of null'))
        toast.error(
          'Unable to conclude due to following reason(s): \n1) Shipment ID not found'
        );
    }
  };

  return {
    entities,
    selectedEntity,
    setSelectedEntity,
    transitFile,
    isLoading,
    handleCancel,
    onSubmit,
    handleUploadTransit,
    handleUploadTransitCancel,
    toBeConcluded,
    setSelectedItem,
    handleSubmit,
    register,
    errors,
    trigger,
    setValue,
  };
}

export default useConcludeTransit;
