import { gql } from '@apollo/client';
import client from '../apollo.';

// export const loginUser = async (loginData) => {
//   const { username, password } = loginData;
//   const { data, errors } = await client.mutate({
//     mutation: gql`
//       mutation loginUser($loginUserInput: LoginUserInput!) {
//         login(loginUserInput: $loginUserInput) {
//           access_token
//           user {
//             _count {
//               Shipment
//             }
//             entityId
//             firstName
//             id
//             entity {
//               name
//               rate
//               type {
//                 name
//                 id
//               }
//             }
//             location {
//               _count {
//                 shipment
//               }
//               id
//               stripeCustomerID
//               defaultPaymentMethodID
//               address {
//                 address
//                 id
//                 nickName
//               }
//             }
//             lastName
//             termsAccepted
//             locationId
//             role
//             phoneNumber
//             emailAddress
//             additionalNote
//           }
//           type
//         }
//       }
//     `,
//     variables: {
//       loginUserInput: {
//         password,
//         username,
//       },
//     },
//   });
//   return { data, errors };
// };

export const loginUser = async (loginData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation loginStaff($loginUserInput: LoginUserInput!) {
        loginStaff(loginUserInput: $loginUserInput) {
          access_token
          user {
            createdAt
            emailAddress
            firstName
            id
            lastName
            phoneNumber
            type
          }
        }
      }
    `,
    variables: {
      loginUserInput: {
        password: loginData.password,
        username: loginData.username,
        // loginUserType: 'CEASTA_STAFF',
        loginUserType: loginData.loginUserType,
      },
    },
  });
  return { data, errors };
};

export const fetchCurrentUserQuery = async () => {
  const { data } = await client.query({
    query: gql`
      query fetchCurrentUser {
        currentUser {
          _count {
            Shipment
          }
          entityId
          firstName
          id
          entity {
            name
            id
            rate
            internationalRate
            isBlocked
            type {
              name
              id
            }
          }
          location {
            _count {
              shipment
            }
            id
            stripeCustomerID
            defaultPaymentMethodID
            address {
              address
              id
              nickName
            }
          }
          lastName
          locationId
          role
          termsAccepted
          phoneNumber
          emailAddress
          additionalNote
        }
      }
    `,
  });
  return data;
};

export const forgetPasswordMutation = async (forgetPasswordData) => {
  const { data, errors } = client.mutate({
    mutation: gql`
      mutation ForgetTeamPassword($email: String!) {
        forgetTeamPassword(input: { email: $email })
      }
    `,
    variables: { email: forgetPasswordData.email },
  });

  return { data, errors };
};

export const resetPasswordMutation = async (resetPasswordData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation ResetTeamPassword($input: ResetPasswordInput!) {
        resetTeamPassword(input: $input)
      }
    `,
    variables: {
      input: {
        token: resetPasswordData.verificationCode,

        email: resetPasswordData.email,
        newPassword: resetPasswordData.newPassword,
        confirmNewPassword: resetPasswordData.confirmPassword,
      },
    },
  });

  return { data, errors };
};
