import { brownClose } from '../../../assets';
import {
  CustomButton,
  CustomInput,
  CustomModal,
  CustomHeading,
  ValidationErrorText,
} from '../../../common/custom';
import CustomDropdown3 from '../../../common/custom/custom-drop-down/index3';
import { CustomIcon } from '../../../common/customAssets';
import useChangeEntityRate from './useChangeEntityRate';
const EntityChangeRate = ({ closeChangeRateModal, modalHeading, entityId }) => {
  const {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    errors,
    salesMembers,
    salesMemberErr,
    setSalesMemberErr,
    salesPerson,
    setSelected,
  } = useChangeEntityRate({
    entityId,
    closeChangeRateModal,
    modalHeading,
  });

  return (
    <>
      <CustomModal>
        <div className='h-auto p-8 max-w-[600px] h-[250px] bg-pure-white rounded-[12px] flex flex-col items-center gap-1 justify-center px-4'>
          <div className='flex justify-between py-2 max-w-[476px] w-full h-[50px]'>
            <div className=' gap-1 flex '>
              <div className=' max-w-[400px]'>
                <CustomHeading className='text-[26px] font-inter leading-[28px] font-[600] text-foundation-brown'>
                  {modalHeading}
                </CustomHeading>
              </div>
            </div>
            <div
              onClick={closeChangeRateModal}
              className=' cursor-pointer flex justify-end'
            >
              <CustomIcon
                icon={brownClose}
                className=' h-[18px] w-[18px] flex justify-center items-center'
              />
            </div>
          </div>
          <div className='min-w-[360px]'>
            <form onSubmit={handleSubmit(onSubmit)}>
              {modalHeading?.includes('Premium') ? (
                <>
                  <div>
                    <CustomInput
                      name='premiumRate'
                      register={register}
                      placeholder='Premium Rate'
                      type='text'
                      label='Premium Rate (%)'
                      required
                      className='text-[#667085] text-base not-italic font-normal leading-6 flex items-center gap-2 self-stretch border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-4 py-3 rounded-lg border-solid border-[#E7ECF4] w-[100%] h-[44px] rounded-7 lg:px-[14px] bg-foundation-gray outline-none   '
                      errors={errors}
                    />
                  </div>
                  <div className='mt-4'></div>
                  <div>
                    <CustomInput
                      name='internationalRate'
                      register={register}
                      placeholder='International Premium Rate'
                      type='text'
                      label='International Premium Rate (%)'
                      required
                      className='text-[#667085] text-base not-italic font-normal leading-6 flex items-center gap-2 self-stretch border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-4 py-3 rounded-lg border-solid border-[#E7ECF4] w-[100%] h-[44px] rounded-7 lg:px-[14px] bg-foundation-gray outline-none   '
                      errors={errors}
                    />
                  </div>
                </>
              ) : (
                <CustomDropdown3
                  placeholder='Select Sales Member'
                  options={salesMembers}
                  setValue={(e) => {
                    console.log('salesMember', e);
                    setValue('salesMember', e);
                    setSelected(e);
                    setSalesMemberErr(false);
                  }}
                  name='salesMember'
                  label='Select Sales Member'
                  value={salesMembers.filter(
                    (option) => option.id == salesPerson
                  )}
                />
              )}
              {salesMemberErr && (
                <div className='mt-1 mb-2'>
                  <ValidationErrorText>{salesMemberErr}</ValidationErrorText>
                </div>
              )}

              <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
                <CustomButton
                  type='button'
                  onClick={closeChangeRateModal}
                  className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
                  variant='outline'
                  text='Cancel'
                />
                <CustomButton
                  className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
                  text='Update'
                />
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default EntityChangeRate;
