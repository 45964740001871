import { cross } from '../../../assets';
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomText,
} from '../../../common/custom';
import { CustomIcon } from '../../../common/customAssets';

const ApproveModal = ({
  approvalHandler,
  closeApproveModal,
  modalHeading,
  modalPara,
  buttonText,
}) => {
  return (
    <CustomModal>
      <div className='max-w-[544px] h-[204px] bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center   '>
        <div
          onClick={closeApproveModal}
          className=' cursor-pointer h-[24px] w-full flex justify-end max-w-[476px]'
        >
          <CustomIcon
            icon={cross}
            className=' h-[12px] w-[12px] flex justify-center items-center'
          />
        </div>
        <div className='max-w-[476px] w-full h-[72px] gap-1 flex justify-between'>
          <div className=' max-w-[400px]'>
            <CustomHeading className='text-[18px] font-inter leading-[28px] font-[600] mb-1'>
              {modalHeading}
            </CustomHeading>
            <CustomText>{modalPara}</CustomText>
          </div>
        </div>
        <div className=' h-[44px] flex justify-end gap-[12px] w-full max-w-[476px] mt-[36px] '>
          <CustomButton
            onClick={closeApproveModal}
            type='button'
            text='Cancel'
            className='w-[87px] h-[44px] border-[1px] rounded-[8px]'
          />
          <CustomButton
            onClick={async () => {
              const { data } = await approvalHandler();
              if (data) {
                closeApproveModal();
              }
            }}
            type='button'
            text={buttonText ? buttonText : 'Approve'}
            className={`w-[87px] h-[44px] ${
              buttonText ? 'bg-red-700' : 'bg-lime-700'
            } rounded-[8px] text-pure-white`}
          />
        </div>
      </div>
    </CustomModal>
  );
};
export default ApproveModal;
