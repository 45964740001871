import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../../hook/useHookFormSubmission';
import { recipientSchema } from '../../../../shipments/schema/recipientDetailsSchema';
import {
  resetReportClaimFormAction,
  updateReportClaimFormAction,
} from '../../../../../store';
import { useNavigate } from 'react-router';
import useApi from '../../../../../hook/useApi';
import { getAllEntitiesQuery } from '../../../../../api';

export const useRecipientDetails = (increaseStepHandler) => {
  const navigate = useNavigate();
  const { selectedAddress } = useSelector((state) => state.addresses);
  const { step1 } = useSelector((state) => state.forms.claimFile.formData);
  const dispatch = useDispatch();
  const [getApprovedEntities] = useApi(getAllEntitiesQuery);

  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const { handleSubmit, register, errors, watch, setValue, reset } =
    useHookFormSubmission(recipientSchema);

  const { contact } = watch();

  useEffect(() => {
    const getEntities = async () => {
      let _entities = await getApprovedEntities();
      _entities = _entities?.data?.allEntity?.map((item) => ({
        label: item.name,
        name: item.name,
        value: item.id,
        id: item.id,
      }));
      setEntities(_entities);
    };
    getEntities();
  }, []);

  useEffect(() => {
    setValue('contact', step1 && step1.contact);
    setValue('selectedShipment', step1 && step1.selectedShipment);
  }, [step1]);

  useEffect(() => {
    setValue(
      'claimAmount',
      selectedShipment
        ? selectedShipment?.totalValue
        : step1 && step1.selectedShipment?.totalValue
    );
  }, [selectedShipment]);

  const onCancel = () => {
    reset();
    dispatch(resetReportClaimFormAction());
    setValue('selectedShipment', '');
    navigate(-1);
  };

  const onSubmit = (values) => {
    // console.log(1, values, selectedEntity, selectedShipment);

    values.entityId = selectedEntity;
    values.locationId = selectedShipment?.locationId;
    values.selectedShipment = selectedShipment?.id;
    values.shipmentId = selectedShipment?.id;
    values.additionalNote = selectedShipment?.additionalNote;
    // console.log(2, values);

    // console.log('values:', values);
    increaseStepHandler();
    dispatch(updateReportClaimFormAction({ step: 1, values }));
  };

  return {
    entities,
    selectedEntity,
    setSelectedEntity,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    contact,
    selectedShipment,
    setSelectedShipment,
    onCancel,
  };
};
