import * as Yup from 'yup';

const customerDetailsSchema = Yup.object().shape({
  destinationName: Yup.string().required('Destination name is required'),

  // referenceNumber: Yup.string().required('Reference number is required'),

  // unitNumber: Yup.string().required('Unit number is required'),

  zipCode: Yup.string().required('Zip code is required'),

  city: Yup.string().required('City is required'),

  state: Yup.string().required('State is required'),
});

export default customerDetailsSchema;
