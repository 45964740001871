import React from 'react';
import {
  CustomButton,
  CustomDropdown,
  CustomInput,
  ValidationErrorText,
} from '../../../../../common/custom';
import { useRecipientDetails } from './use-recipient-detail.hook';
import { cross } from '../../../../../assets';
import CustomDropdown3 from '../../../../../common/custom/custom-drop-down/index3';
import TextArea from '../../../../../common/custom/customTextArea';

const RecipientDetails = ({ increaseStepHandler, toBeClaimed }) => {
  const {
    entities,
    selectedEntity,
    setSelectedEntity,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    contact,
    selectedShipment,
    setSelectedShipment,
    onCancel,
  } = useRecipientDetails(increaseStepHandler);

  const filteredShipments = toBeClaimed?.filter(
    (e) => e?.entityId == selectedEntity
  );

  // const selectedData = toBeClaimed?.find((e) => e?.id == selectedShipment);
  // console.log('selectedData:', selectedData);
  return (
    <div className='stepper-cards'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Enter Recipient Detail
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid gap-4'>
          <div>
            {/* <CustomDropdown
              placeholder='Entity'
              label='Select Entity'
              onChange={(e) => setSelectedEntity(e)}
              options={entities}
              setValue={setValue}
              name='entity'
            /> */}

            <CustomDropdown3
              placeholder='Entity'
              label='Select Entity'
              setValue={(e) => {
                setSelectedEntity(e);
                setSelectedShipment(null);
              }}
              options={entities}
              name='entity'
            />
          </div>{' '}
          <div>
            <CustomDropdown3
              placeholder='Select Shipment'
              label='Select Shipment to File Claim'
              setValue={(e) =>
                setSelectedShipment(toBeClaimed?.find((i) => i?.id == e))
              }
              options={filteredShipments}
              name='selectedShipment'
            />
            {/* <CustomDropdown
              placeholder='Select Shipment'
              label='Select Shipment to create claim'
              // value={contact}
              onChange={(e) => setSelectedShipment(e)}
              options={filteredShipments}
              setValue={setValue}
              name='selectedShipment'
            /> */}
          </div>
          {/* <div>
            <CustomInput
              register={register}
              name='location'
              type='text'
              value={selectedData?.location}
              label='Location Name'
              errors={errors}
              readOnly={true}
            />
          </div>
          <input
            type='text'
            name='internalNote'
            value={selectedData?.additionalNote}
            hidden
          />
          <input
            type='text'
            name='locationId'
            value={selectedData?.locationId}
            hidden
          />
          <input
            type='text'
            name='entityId'
            value={selectedData?.entityId}
            hidden
          /> */}
          <div>
            <CustomInput
              register={register}
              name='claimAmount'
              placeholder='Claim Amount'
              type='number'
              label='Claim Amount'
            />
            {errors.claimAmount ? (
              <ValidationErrorText>
                {errors.claimAmount.message}
              </ValidationErrorText>
            ) : null}
          </div>
          <div>
            <CustomInput
              register={register}
              name='phoneNumber'
              placeholder="Recipient's Phone Number"
              type='text'
              label="Recipient's Phone Number *"
            />
            {errors.phoneNumber ? (
              <ValidationErrorText>
                {errors.phoneNumber.message}
              </ValidationErrorText>
            ) : null}
          </div>
          <div className=''>
            <CustomDropdown
              placeholder='Choose best way to contact'
              label='Best Way to Contact the Recipient *'
              value={contact}
              options={[
                { id: 'Email', state: 'Email' },
                { id: 'Phone', state: 'Phone' },
              ]}
              setValue={setValue}
              name='contact'
            />
            {errors.state ? (
              <ValidationErrorText>{errors.state.message}</ValidationErrorText>
            ) : null}
          </div>
          <div>
            <CustomInput
              register={register}
              name='filedByName'
              type='text'
              label='Filed By Name'
              errors={errors}
              setValue={setValue}
            />
          </div>
          <div>
            <TextArea
              label='Part to be Claimed / Claim Description'
              name='partToBeClaimed'
              register={register}
              errors={errors}
              placeholder='Part to be Claimed / Claim Description'
              type='text'
              labelClassName='text-[#344054] text-sm not-italic font-medium leading-5'
            />
          </div>
          <div className='flex justify-end mt-[32px] gap-[24px]'>
            <button
              className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
              type='submit'
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RecipientDetails;
