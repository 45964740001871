import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function CircularILoader() {
  return (
    <div className="tw-flex tw-items-center tw-justify-center">
      <Box sx={{ display: "flex" }}>
        <CircularProgress style={{ color: '#8E725E' }} />
      </Box>
    </div>
  );
}
