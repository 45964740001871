import React, { useEffect } from 'react';
import { isTokenExpired, logout } from '../helpers/helpers';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../constants/toastNotifications';

const withAuth = (WrappedComponent) => {
  //   console.log('enter withAuth HOC');
  const WithAuthComponent = (props) => {
    // console.log('withAuth HOC applied to:', WrappedComponent.name);

    useEffect(() => {
      //   console.log(`useEffect called for ${WrappedComponent.name}`);
      if (isTokenExpired()) {
        // console.log('Token expired, logging out');
        toast.dismiss();
        toast.success('Token expired, logging out!', {
          duration: TOAST_TIME,
        });
        setTimeout(() => {
          logout();
        }, TOAST_TIME);
      }
    });

    return <WrappedComponent {...props} />;
  };

  return WithAuthComponent;
};

export default withAuth;
