import { useDispatch, useSelector } from 'react-redux';
import { setReportShipmentFormStepAction } from '../../../store';
import { useState } from 'react';

export const useReportShipments = () => {
  const dispatch = useDispatch();
  const { reportShipment } = useSelector((state) => state.forms);
  const [isTrial, setIsTrial] = useState(false);

  const increaseStepHandler = () => {
    const step = reportShipment.currentStep + 1;
    dispatch(setReportShipmentFormStepAction(step));
  };

  const decreaseStepHandler = () => {
    const step = reportShipment.currentStep - 1;
    dispatch(setReportShipmentFormStepAction(step));
  };

  return {
    currentStep: reportShipment.currentStep,
    increaseStepHandler,
    decreaseStepHandler,
    isTrial,
    setIsTrial,
  };
};
