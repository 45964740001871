import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { download, uploadFileIcon } from '../../../../assets';
import {
  DOWNLOAD_FILE,
  FILE_TYPE,
  HANDLE_FILE_SIZE_CHECK,
} from '../../../../helpers/helpers';
import FileUpload from '../../../../common/custom/file-upload/file-upload.component';
import FilePreview from '../../../../common/custom/file-preview/file-preview';
import { ValidationErrorText } from '../../../../common/custom';
import CircularILoader from '../../../../common/custom/circularLoader';

const UploadClaimCloseDoc = ({
  files,
  file,
  setFile,
  fileError,
  setFileError,
}) => {
  const [isLoading, setisLoading] = useState(false);

  const handleFileUpload = (file) => {
    const document = HANDLE_FILE_SIZE_CHECK(20, file);
    setisLoading(true);
    toast.loading('Document is uploading! Please wait...');
    if (document) {
      setFile(file);
      const formData = new FormData();
      formData.append('upload', file);

      axios
        .post('https://api.ceasta.com/fileUpload', formData)
        .then((response) => {
          setFile([...response.data][0]);
          setFileError(false);
          setisLoading(false);
          toast.dismiss();
        })
        .catch((error) => {
          setisLoading(false);
          toast.dismiss();
        });
    }
  };

  const handleFileCancel = () => {
    setFile('');
  };

  const customHtml = `
    <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
      <div class="flex flex-col items-center">
      <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]" />
      <div class="flex flex-col gap-1 mt-3 items-center">
       <div class="flex gap-1">
       <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
       <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
       </div>
        <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG or PDF</div>
      </div>
      </div>
    </div> 
  `;
  return (
    <div className='mt-8'>
      {files?.length > 0 ? (
        files?.map((e, index) => (
          <div key={index} className='flex justify-between items-center pt-1'>
            <div className='flex justify-start items-center'>
              <div className='w-8'></div>
              <div className='text-sm text-[#7D7D7D]'>{e?.originalname}</div>
            </div>
            <div onClick={() => DOWNLOAD_FILE(e?.location)}>
              <CustomIcon icon={download} className='w-6 h-6 cursor-pointer' />
            </div>
          </div>
        ))
      ) : (
        <div className='flex flex-col gap-[6px]'>
          <div className='text-[#344054] text-sm not-italic font-medium leading-5'>
            Attach a document for claim close (if required).
          </div>
          {file && isLoading ? (
            <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
              <CircularILoader />{' '}
            </div>
          ) : file ? (
            <FilePreview files={[file]} handleCancel={handleFileCancel} />
          ) : (
            <FileUpload
              handleChange={handleFileUpload}
              fileTypes={FILE_TYPE}
              customHtml={customHtml}
            />
          )}
          {fileError && (
            <div className='mt-1 mb-1'>
              <ValidationErrorText>{fileError}</ValidationErrorText>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadClaimCloseDoc;
