import { useLocation } from 'react-router-dom';
import CustomContainer from '../../common/custom/CustomContainer';

export default function Footer() {
  const location = useLocation();
  const authPage = location.pathname.split('/')[1] === 'auth';
  return (
    <footer className=''>
      <CustomContainer>
        <div className='flex flex-col lg:flex-row items-start justify-between lg:items-center mt-[20px] md:mt-[30px] lg:mt-[120px] py-[24px]' style={{ position: authPage ? 'fixed' : 'inherit', bottom: authPage ? 0 : 'inherit' }}>
          <span className='mb-[12px] lg:mb-0 text-[color:var(--Foundation-Black-B100,#6B6B6B)] text-sm not-italic font-medium leading-[normal];' style={{ textWrap: 'nowrap' }}>
            © 2024 Ceasta Assurance
          </span>
        </div>
      </CustomContainer>
    </footer>
  );
}
