import React from 'react';
import { uploadFileIcon } from '../../../../assets';
import FileUpload from '../../../../common/custom/file-upload/file-upload.component';
import { FILE_TYPE } from '../../../../helpers/helpers';
import useConcludeTransit from './useConcludeTransit';
import CircularILoader from '../../../../common/custom/circularLoader';
import FilePreview from '../../../../common/custom/file-preview/file-preview';
import { CustomDropdown } from '../../../../common/custom';
import CustomDropdown3 from '../../../../common/custom/custom-drop-down/index3';

function ConcludeTransit() {
  const {
    entities,
    selectedEntity,
    setSelectedEntity,
    transitFile,
    isLoading,
    handleCancel,
    onSubmit,
    handleUploadTransit,
    handleUploadTransitCancel,
    toBeConcluded,
    setSelectedItem,
    handleSubmit,
    register,
    errors,
    trigger,
    setValue,
  } = useConcludeTransit();

  const filteredShipments = toBeConcluded?.filter(
    (e) => e?.entityId == selectedEntity
  );
  const customHtml = `
    <div class="flex flex-col h-[126px] cursor-pointer items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]">
      <div class="flex flex-col items-center">
      <img src=${uploadFileIcon} alt="upload" class="w-[20px] h-[20px]"/>
      <div class="flex flex-col gap-1 mt-3 items-center">
       <div class="flex gap-1">
       <div class="text-[#B2B7BF] text-sm not-italic font-semibold leading-5">Click to upload</div>
       <div class="text-[#475467] text-sm not-italic font-normal leading-5">or drag and drop</div>
       </div>
        <div class="text-[#475467] text-sm not-italic font-normal leading-5">SVG, PNG, JPG, JPEG or PDF</div>
      </div>
      </div>
    </div> 
  `;
  return (
    <div className='flex justify-center mt-[60px]'>
      <div className='flex min-w-[996px] min-h-[400px] flex-col items-start gap-8 shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl'>
        <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
          Conclude Transit
        </div>
        <div className='flex gap-[6px] w-full flex-col'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <CustomDropdown3
                placeholder='Entity'
                label='Select Entity'
                setValue={(e) => {
                  setSelectedEntity(e);
                  // setSelectedShipment(null);
                }}
                options={entities}
                name='entity'
              />
            </div>{' '}
            <div className='mt-4'>
              <CustomDropdown3
                placeholder='Select Shipment'
                options={filteredShipments}
                setValue={(e) => setValue('selectShipment', e)}
                name='selectShipment'
                label='Select Shipment'
                errors={errors}
              />
            </div>
            <div className='text-[#344054] text-sm not-italic font-medium leading-5 mt-4'>
              You may choose to upload the Deliver Receipts
            </div>
            {transitFile && isLoading ? (
              <div class='flex h-[126px] justify-center items-center gap-1 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
                <CircularILoader />{' '}
              </div>
            ) : transitFile ? (
              <FilePreview
                file={transitFile}
                handleCancel={handleUploadTransitCancel}
              />
            ) : (
              <FileUpload
                handleChange={handleUploadTransit}
                fileTypes={FILE_TYPE}
                customHtml={customHtml}
              />
            )}
            <div className='flex justify-end mt-[32px]'>
              <div className='flex gap-[32px]'>
                <button
                  className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className='flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
                  // onClick={handleConclude}
                >
                  Conclude
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ConcludeTransit;
