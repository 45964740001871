import { TextareaAutosize } from '@mui/material';
import FieldLabel from './field-label/field-label.component';

import ValidationErrorText from './ValidationErrorText';

export default function TextArea({
  placeholder = '',
  name,
  register = null,
  label = null,
  className = '',
  minRows = 4,
  maxRows = 10,
  value = null,
  disabled = false,
  defaultValue = null,
  onChange = null,
  onKeyDown = null, // Add onSubmit prop
  errors = null,
  isRequired = false,
  inlineLabel = false,
  labelClassName = '',
  readOnly = false,
  ref = null,
  onBlur = null,
  customRef = null,
}) {
  return (
    <div className='flex flex-col w-full'>
      <div
        className={`${
          inlineLabel
            ? 'flex w-full flex-row items-center'
            : 'flex flex-col gap-[8px] text-xs font-medium capitalize not-italic leading-6 text-text-black'
        }`}
      >
        {label && (
          <FieldLabel
            label={label}
            className={labelClassName}
          />
        )}

        <div className='w-full'>
          <TextareaAutosize
            {...(register && register(`${name}`))}
            name={name}
            {...(customRef && { ref: customRef })}
            minRows={minRows}
            maxRows={maxRows}
            placeholder={placeholder}
            className={`input-field text-[#667085] text-base not-italic font-normal leading-6 h-[40px] w-full outline-none rounded-[5px] !border border-text-ultra-light-gray !bg-transparent px-[16px] py-[9px] font-dm text-text-dark-gray placeholder:!p-0 placeholder:text-text-ultra-light-gray before:content-none after:content-none min hover:border-text-dark-gray focus:border-[1px] focus:border-text-dark-gray ${
              errors && errors[name] && 'error-field'
            } ${className} ${!disabled || 'disabled-input'} `}
            {...(defaultValue && { defaultValue })}
            {...(value && { value })}
            onChange={onChange}
            onKeyDown={onKeyDown} // Add this line to handle key events
            readOnly={readOnly}
            disabled={disabled}
            {...(onBlur && { onBlur })}
          />
        </div>
      </div>
      {errors && errors[name] && (
        <div className='mt-[-4px]'>
          <ValidationErrorText>{errors[name].message}</ValidationErrorText>
        </div>
      )}
    </div>
  );
}
