// import { useState } from "react";

// function useCustomTable(rows) {
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5;
//   console.log(currentPage, 'currentPage')
//   const totalPages = Math.ceil(rows && rows.length / itemsPerPage);

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = rows && rows.slice(indexOfFirstItem, indexOfLastItem);

//   const disableNext = totalPages === currentPage;
//   const disablePrevious = currentPage === 1;

//   const handleNextPage = () => {
//     setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };

//   const handlePrevPage = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure currentPage is never less than 1
//   };

//   const handlePageClick = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const renderPaginationNumbers = () => {
//     const paginationNumbers = [];
//     const maxDisplayedPages = 3;

//     // Render pagination numbers based on current page and total pages
//     for (let i = 1; i <= totalPages; i++) {
//       if (
//         i === 1 || // Always render first page
//         i === totalPages || // Always render last page
//         (i >= currentPage - 1 && i <= currentPage + 1) || // Render current page and adjacent pages
//         (currentPage <= maxDisplayedPages && i <= maxDisplayedPages) || // Render first few pages
//         (currentPage >= totalPages - maxDisplayedPages + 1 &&
//           i >= totalPages - maxDisplayedPages + 1) // Render last few pages
//       ) {
//         paginationNumbers.push(
//           <div
//             key={i}
//             className={`border-r-2 border-[#D0D5DD] mx-1 w-[40px]  h-[40px] flex justify-center items-center ${
//               currentPage === i ? "font-bold" : ""
//             }`}
//             onClick={() => handlePageClick(i)}
//           >
//             {i}
//           </div>
//         );
//       }
//     }

//     // Render ellipsis if necessary
//     if (currentPage > maxDisplayedPages + 1) {
//       paginationNumbers.splice(
//         1,
//         0,
//         <div
//           key={-1}
//           className={`border-r-2 border-[#D0D5DD] w-[40px]  h-[40px] flex justify-center items-center ${
//             currentPage > maxDisplayedPages ? "font-bold" : ""
//           }`}
//           onClick={() => handlePageClick(Math.max(1, currentPage - 1))}
//         >
//           ...
//         </div>
//       );
//     }
//     if (currentPage < totalPages - maxDisplayedPages) {
//       paginationNumbers.splice(
//         paginationNumbers.length - 1,
//         0,
//         <div
//           key={-2}
//           className={`border-r-2 border-[#D0D5DD] w-[40px]  h-[40px] flex justify-center items-center ${
//             currentPage < totalPages - maxDisplayedPages ? "font-bold" : ""
//           }`}
//           onClick={() => handlePageClick(Math.min(totalPages, currentPage + 1))}
//         >
//           ...
//         </div>
//       );
//     }

//     return paginationNumbers;
//   };

//   return {
//     currentItems,
//     renderPaginationNumbers,
//     handlePrevPage,
//     handleNextPage,
//     disableNext,
//     disablePrevious,
//   };
// }

// export default useCustomTable;
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setPage } from '../../../store/slices/paginationSlice';
// import { setPage } from '../  ./store/paginationSlice';

function useCustomTable(rows, tableId) {
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const currentPage = useSelector(state => state.pagination[tableId] || 1);
  const totalPages = Math.ceil(rows && rows.length / itemsPerPage);

  useEffect(() => {
    dispatch(setPage({ tableId, page: currentPage }));
  }, [currentPage, dispatch, tableId]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rows && rows.slice(indexOfFirstItem, indexOfLastItem);

  const disableNext = totalPages === currentPage;
  const disablePrevious = currentPage === 1;

  const handleNextPage = () => {
    dispatch(setPage({ tableId, page: Math.min(currentPage + 1, totalPages) }));
  };

  const handlePrevPage = () => {
    dispatch(setPage({ tableId, page: Math.max(currentPage - 1, 1) }));
  };

  const handlePageClick = (pageNumber) => {
    dispatch(setPage({ tableId, page: pageNumber }));
  };

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    const maxDisplayedPages = 3;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1) ||
        (currentPage <= maxDisplayedPages && i <= maxDisplayedPages) ||
        (currentPage >= totalPages - maxDisplayedPages + 1 && i >= totalPages - maxDisplayedPages + 1)
      ) {
        paginationNumbers.push(
          <div
            key={i}
            className={`border-r-2 border-[#D0D5DD] mx-1 w-[40px] h-[40px] flex justify-center items-center ${currentPage === i ? "font-bold" : ""}`}
            onClick={() => handlePageClick(i)}
          >
            {i}
          </div>
        );
      }
    }

    if (currentPage > maxDisplayedPages + 1) {
      paginationNumbers.splice(
        1,
        0,
        <div
          key={-1}
          className={`border-r-2 border-[#D0D5DD] w-[40px] h-[40px] flex justify-center items-center ${currentPage > maxDisplayedPages ? "font-bold" : ""}`}
          onClick={() => handlePageClick(Math.max(1, currentPage - 1))}
        >
          ...
        </div>
      );
    }
    if (currentPage < totalPages - maxDisplayedPages) {
      paginationNumbers.splice(
        paginationNumbers.length - 1,
        0,
        <div
          key={-2}
          className={`border-r-2 border-[#D0D5DD] w-[40px] h-[40px] flex justify-center items-center ${currentPage < totalPages - maxDisplayedPages ? "font-bold" : ""}`}
          onClick={() => handlePageClick(Math.min(totalPages, currentPage + 1))}
        >
          ...
        </div>
      );
    }

    return paginationNumbers;
  };

  return {
    currentItems,
    renderPaginationNumbers,
    handlePrevPage,
    handleNextPage,
    disableNext,
    disablePrevious,
  };
}

export default useCustomTable;
