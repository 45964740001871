import React from 'react';
import { CustomIcon } from '../customAssets';

const CustomButton = ({
  // will receive className props  in which tailwind css classes will be passed for styling
  className = 'bg-foundation-brown  text-pure-white w-full max-w-[356px] lg:max-w-[356px] h-[44px] rounded-[7px] text-medium-para ', // if no className props are passed than these
  //classes will aplied as default.
  // Note (default tailwind css classes styling are of those button which are in application figma, so if you want those application design button than dont pass that, it will style figma
  // button for you, just pass your desired text as prop in that case )

  text, // text prop for rendering desired text in button

  // type prop for passing type of text
  type = 'submit', // if no type is passed than 'submit' will used as default type

  // click handler
  onClick, // button click handler for different clicking events
  disabled,

  icon, // icon prop for passing icon in button, if no icon is passed than no icon will be shown
}) => {
  // Render the dynamic button  with dynamic styles, text and icon based on props
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={className + `${disabled ? 'opacity-70' : ''}`}
    >
      {icon ? (
        // <img
        //   src={icon}
        //   alt={icon}
        // />
        <CustomIcon icon={icon} />
      ) : null}
      <div> {text}</div>
    </button>
  );
};

export default CustomButton;

// Example usage:

// example 1 Note (if now className prop is passed than, we will have our figma design reusable buttons which are mainly used in login on other parts of application
// so for that you only have to send text prop)

// <CustomButton
// text="Pay"
// >
// </CustomButton>

//example 2 Note (here i am  creating custom button with className prop for styling and also passing icon prop for adding icon in  button)

// <CustomButton
// icon ={plusIcon}
// className='bg-orange-600 text-white'
// text="add"
// >
// </CustomButton>
