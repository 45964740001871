import { createSlice } from "@reduxjs/toolkit";
import {
  storeAllShipments,
  addShipment,
  entityShipment,
} from "./shipmentsAction";

const initialState = {
  allShipments: [],
  entityShipments: [],
};

const formsSlice = createSlice({
  name: "allShipments",
  initialState,
  reducers: {
    storeAllShipmentsAction: storeAllShipments,
    addShipmentAction: addShipment,
    entityShipmentAction: entityShipment,
    resetAllSHipmentsAction: () => {
   
    },
  },
});

export const shipmentsReducer = formsSlice.reducer;
export const {
  storeAllShipmentsAction,
  addShipmentAction,
  entityShipmentAction,
} = formsSlice.actions;
