import { gql } from '@apollo/client';
import client from '../apollo.';

export const findAllSalesMemberQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query findAllSalesMemberForAdmin {
        findAllSalesMember {
          id
          firstName
          lastName
          emailAddress
        }
      }
    `,
  });
  return { data, errors };
};

export const findAllSalesMemberForAdminQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query findAllSalesMemberForAdmin {
        findAllSalesMemberForAdmin {
          id
          firstName
          lastName
          emailAddress
        }
      }
    `,
  });
  return { data, errors };
};

export const getCommissionDetailsQuery = async (saleMemberId) => {
  console.log('id', saleMemberId);

  if (saleMemberId) {
    const { data, errors } = await client.query({
      query: gql`
        query getCommissionDetails($saleMemberId: String!) {
          getCommissionDetails(saleMemberId: $saleMemberId) {
            id
            premium
            entityMemberId
            createdAt
          }
        }
      `,
      variables: { saleMemberId },
    });
    return { data, errors };
  }
};

export const addSalesMemberMutation = async (inputData) => {
  // console.log('inputData:', inputData);
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation createUser($createUserInput: UserCreateInput!) {
        createUser(createUserInput: $createUserInput) {
          id
          firstName
          lastName
          emailAddress
        }
      }
    `,
    variables: {
      createUserInput: {
        firstName: inputData.firstName,
        lastName: inputData.lastName,
        emailAddress: inputData.email,
        password: inputData.password,
        type: 'SALES',
        phoneNumber: '',
        userId: '',
      },
    },
  });
  return { data, errors };
};

export const updateSalesMemberMutation = async (updateData) => {
  console.log('updateData:', updateData);
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation updateUser(
        $userWhereUniqueInput: UserWhereUniqueInput!
        $updateUserInput: UserUpdateInput!
      ) {
        updateUser(
          userWhereUniqueInput: $userWhereUniqueInput
          updateUserInput: $updateUserInput
        ) {
          id
          firstName
          lastName
          emailAddress
        }
      }
    `,
    variables: {
      userWhereUniqueInput: {
        id: parseInt(updateData.userId),
      },
      updateUserInput: {
        firstName: {
          set: updateData.firstName,
        },
        lastName: {
          set: updateData.lastName,
        },
        emailAddress: {
          set: updateData.email,
        },
      },
    },
  });

  return { data, errors };
};

export const changeSalesMemberPasswordMutation = async (passwordData) => {
  console.log('passwordData', passwordData);
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation updateUser(
        $userWhereUniqueInput: UserWhereUniqueInput!
        $updateUserInput: UserUpdateInput!
      ) {
        updateUser(
          userWhereUniqueInput: $userWhereUniqueInput
          updateUserInput: $updateUserInput
        ) {
          id
          firstName
          lastName
          emailAddress
        }
      }
    `,
    variables: {
      userWhereUniqueInput: {
        id: parseInt(passwordData.userId),
      },
      updateUserInput: {
        password: {
          set: passwordData.password,
        },
      },
    },
  });

  return { data, errors };
};

export const deleteSalesMemberMutation = async (userId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation deleteUser($deleteUserId: Int!) {
        deleteUser(id: $deleteUserId) {
          id
        }
      }
    `,
    variables: {
      deleteUserId: userId,
    },
  });
  return { data, errors };
};
