import CustomContainer from '../../../common/custom/CustomContainer';
import {
  ClientsPanelHeader,
  CustomDropdown,
  CustomText,
} from '../../../common/custom';
import UseEditEntities from './useEditEntities';
import { CustomInput, CustomButton } from '../../../common/custom';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import {
  AddressInformation,
  MememberHeader,
  ShipmentInformation,
} from '../../../constants/columns';
import NoEntitiesComponent from '../noEntityComponent';
import PaymentLocationTable from '../EntityPaymentTable/paymentLocationTable';
import useEntities from '../Entities/useEntities';
import ApproveModal from '../../modals/approve-modal';
import AddressList from '../address';
import { CustomIcon } from '../../../common/customAssets';
import { download } from '../../../assets';
import { Tooltip } from '@material-tailwind/react';
import { DOWNLOAD_FILE } from '../../../helpers/helpers';

const EditEntities = () => {
  const {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    memeberInformation,
    shipmentInformation,
    isDetailPage,
    filterStatus,
    errors,
    entityName,
    selectedRowData,
    watchedIndustryType,
    onClickCancel,
    findAllSalesMember,
    watchedSalesPersonId,
  } = UseEditEntities();

  const {
    openApprovalModal,
    showApprovalModal,
    closeApprovalModal,
    approveEntityHandler,
  } = useEntities();

  const merchandiseTypeOptions = [
    { id: '1', name: 'Carrier' },
    { id: '2', name: 'Furniture' },
    { id: '3', name: 'Fine Art' },
    { id: '4', name: 'Interior Design' },
    { id: '5', name: 'Other' },
  ];
  const merchandiseTypePreValue = merchandiseTypeOptions?.find(
    (option) => option.id === watchedIndustryType
  );
  const salesMemberPreValue = findAllSalesMember?.find(
    (option) => option.id === String(watchedSalesPersonId)
  );

  return (
    <>
      {showApprovalModal && (
        <ApproveModal
          modalHeading='Approve Entity'
          modalPara='Are you sure you want to approve this Entity? This action cannot be undone.'
          approvalHandler={() =>
            approveEntityHandler({ data: selectedRowData })
          }
          closeApproveModal={closeApprovalModal}
        />
      )}

      <CustomContainer>
        <ClientsPanelHeader />
        <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
          <div className='flex items-center gap-2 justify-between'>
            <h1 className='max-w-[600px] text-[24px] font-semibold mb-8 text-black font-inter'>
              {entityName + ': '}
              <span className='text-[24px] font-semibold mb-8 font-inter text-foundation-brown'>
                Entity Information
              </span>
            </h1>
            {!selectedRowData?.id && (
              <CustomButton
                onClick={openApprovalModal}
                className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
                text='Approve'
                type='button'
              />
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div>
                <div className='mb-[6px]'>
                  <CustomText>Select Industry Type</CustomText>
                </div>

                <CustomDropdown
                  value={merchandiseTypePreValue?.name}
                  placeholder='Select Industry type'
                  defaultValue={selectedRowData?.type?.name}
                  options={merchandiseTypeOptions}
                  setValue={setValue}
                  name='industryType'
                  errors={errors}
                />
              </div>
              <div>
                <CustomInput
                  name='businessName'
                  register={register}
                  placeholder='Business Name'
                  type='text'
                  label='Business Name'
                  required
                  errors={errors}
                  readOnly={filterStatus === 'new' ? true : false}
                />
              </div>
            </div>

            <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div>
                <CustomInput
                  name='busniessTin'
                  register={register}
                  placeholder='Enter Business TIN'
                  type='text'
                  label='Business TIN'
                />
              </div>

              <div className='md:mt-[5px]'>
                <div>
                  <CustomInput
                    name='address'
                    register={register}
                    placeholder='Enter Address'
                    type='text'
                    label='Address'
                    required
                    errors={errors}
                  />
                </div>
              </div>
            </div>

            <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div>
                <CustomInput
                  name='phone'
                  register={register}
                  placeholder='Enter Phone Number'
                  type='text'
                  label='Phone Number'
                  required
                  errors={errors}
                />
              </div>
              <div>
                <div className='md:mt-[5px]'>
                  <div>
                    <CustomInput
                      name='email'
                      register={register}
                      placeholder='Enter Email Address'
                      type='text'
                      label='Email Address'
                      required
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div>
                <CustomInput
                  name='since'
                  register={register}
                  placeholder='Enter Since'
                  type='text'
                  label='Since'
                />
              </div>

              {filterStatus === 'all' ? (
                <div className='md:mt-[5px]'>
                  <div>
                    <CustomInput
                      name='dba'
                      register={register}
                      placeholder='Enter DBA'
                      type='text'
                      label='DBA'
                    />
                  </div>
                </div>
              ) : (
                <div className='md:mt-[5px]'>
                  <div>
                    <CustomInput
                      name='principalName'
                      register={register}
                      placeholder='Principal Name'
                      type='text'
                      label='Principal Name'
                    />
                  </div>
                </div>
              )}
            </div>

            {filterStatus !== 'all' && (
              <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
                <div>
                  <div className='mb-[6px]'>
                    <CustomText>Sales Person</CustomText>
                  </div>

                  <CustomDropdown
                    value={salesMemberPreValue?.firstName}
                    placeholder='Select Sales Person'
                    options={findAllSalesMember}
                    setValue={setValue}
                    name='salesPerson'
                    errors={errors}
                  />
                </div>

                <div className='md:mt-[5px]'>
                  <CustomInput
                    name='website'
                    register={register}
                    placeholder='Enter Website'
                    type='text'
                    label='Website'
                  />
                </div>
              </div>
            )}

            {filterStatus === 'all' ? (
              <>
                <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
                  <div>
                    <CustomInput
                      name='rate'
                      register={register}
                      placeholder='Enter rate'
                      type='text'
                      label='Premium Rate'
                    />
                  </div>
                  <div>
                    <div className='md:mt-[5px]'>
                      <div>
                        <CustomInput
                          name='emailRebate'
                          register={register}
                          placeholder='Enter Email for Rebate'
                          type='text'
                          label='Email for Rebate'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='items-center grid grid-cols-1 sm:grid-cols-1 gap-[16px] mb-[22px] sm:mb-[16x]'>
                  <div>
                    <div className='md:mt-[5px]'>
                      <div>
                        <CustomInput
                          name='note'
                          register={register}
                          placeholder='Enter Additional Notes'
                          type='text'
                          label='Addition Note'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='items-center grid grid-cols-1 sm:grid-cols-1 gap-[16px] mb-[22px] sm:mb-[16x]'>
                  <div>
                    <div className='md:mt-[5px]'>
                      <div className='flex gap-2'>
                        <div className='font-bold'>Policy Document:</div>
                        <div>
                          {selectedRowData?.policyDocument ? (
                            <div className='flex gap-2'>
                              {
                                JSON.parse(selectedRowData?.policyDocument)
                                  .originalname
                              }
                              <Tooltip
                                className='mt-[-10px] z-50'
                                content='Download'
                              >
                                <div
                                  className='cursor-pointer'
                                  onClick={() =>
                                    DOWNLOAD_FILE(
                                      JSON.parse(
                                        selectedRowData?.policyDocument
                                      ).location
                                    )
                                  }
                                >
                                  <CustomIcon
                                    icon={download}
                                    className='w-6 h-6'
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          ) : (
                            'No policy attached.'
                          )}
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
              <CustomButton
                type='button'
                onClick={onClickCancel}
                className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
                variant='outline'
                text='Cancel'
              />
              <CustomButton
                className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
                text='Update'
              />
            </div>
          </form>
        </div>
      </CustomContainer>

      {isDetailPage && filterStatus === 'all' ? (
        <>
          <CustomContainer className='mt-4'>
            <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
              <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
                Member Information
              </h1>
              {memeberInformation && memeberInformation.length > 0 ? (
                <CustomTable
                  table="/memberInformation"
                  header={MememberHeader}
                  rows={memeberInformation}
                />
              ) : (
                <NoEntitiesComponent text={'No Member Information Found'} />
              )}
            </div>
          </CustomContainer>

          <PaymentLocationTable />

          <AddressList entity={selectedRowData} />

          <CustomContainer className='mt-4'>
            <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
              <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
                Shipment Information
              </h1>

              {shipmentInformation && shipmentInformation.length > 0 ? (
                <CustomTable
                  table="/shipmentInformation"
                  header={ShipmentInformation}
                  rows={shipmentInformation}
                />
              ) : (
                <NoEntitiesComponent text={'No Shipment Found'} />
              )}
            </div>
          </CustomContainer>
        </>
      ) : null}
    </>
  );
};

export default EditEntities;
