import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../../hook/useHookFormSubmission';
import { recipientSchema } from '../../../../shipments/schema/recipientDetailsSchema';
import {
  resetReportClaimFormAction,
  updateReportClaimFormAction,
} from '../../../../../store';
import { useNavigate } from 'react-router';
import useApi from '../../../../../hook/useApi';
import {
  getAllEntitiesQuery,
  getEntityLocationsQuery,
} from '../../../../../api';

export const useRecipientDetails = (increaseStepHandler) => {
  const navigate = useNavigate();
  const { step1 } = useSelector((state) => state.forms.claimFile.formData);
  const dispatch = useDispatch();
  const [getApprovedEntities] = useApi(getAllEntitiesQuery);
  const [getEntityLocations] = useApi(getEntityLocationsQuery);

  const [entities, setEntities] = useState([]);
  const [entityLocations, setEntityLocations] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { handleSubmit, register, errors, watch, setValue, reset } =
    useHookFormSubmission(recipientSchema);

  const { contact } = watch();

  useEffect(() => {
    const getEntities = async () => {
      let _entities = await getApprovedEntities();
      _entities = _entities?.data?.allEntity?.map((item) => ({
        label: item.name,
        name: item.name,
        value: item.id,
        id: item.id,
      }));
      setEntities(_entities);
    };
    getEntities();
  }, []);

  // useEffect(() => {
  //   const getLocations = async () => {
  //     let _data = await getEntityLocations(selectedEntity);
  //     _data = _data?.data?.map((item) => ({
  //       label: item.name,
  //       name: item.name,
  //       value: item.id,
  //       id: item.id,
  //     }));
  //     setEntityLocations(_data);
  //   };
  //   getLocations();
  // }, [selectedEntity]);

  useEffect(() => {
    setValue('contact', step1 && step1.contact);
    // setValue('selectedLocation', step1 && step1.selectedLocation);
  }, [step1]);

  const onSubmit = (values) => {
    values.entityId = selectedEntity;
    // values.locationId = selectedLocation?.id;
    increaseStepHandler();
    dispatch(updateReportClaimFormAction({ step: 1, values }));
  };

  const onCancel = () => {
    reset();
    dispatch(resetReportClaimFormAction());
    navigate(-1);
  };

  return {
    entities,
    // entityLocations,
    selectedEntity,
    setSelectedEntity,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    contact,
    selectedLocation,
    setSelectedLocation,
    onCancel,
  };
};
