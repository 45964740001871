import { useEffect, useState } from 'react';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import useApi from '../../../hook/useApi';
import { updateRateEntitySchema } from '../Entities/Schema/editEntitySchema';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import toast from 'react-hot-toast';
import { findAllSalesMemberQuery } from '../../../api';
import { useSelector } from 'react-redux';
import {
  storeAllEntitiesAction,
  storeSingleEntityAction,
} from '../../../store';
import {
  getAllEntitiesQuery,
  updateEntityMutation,
  getEntity,
} from '../../../api';
import { HANDLE_FILE_SIZE_CHECK } from '../../../helpers/helpers';
import axios from 'axios';

const usePolicyDocument = ({ entityId, closeModal }) => {
  const [policyFile, setPolicyFile] = useState(null);
  const [err, setErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { entities } = useSelector((state) => state);
  const [getSingleEntity] = useApi(getEntity, storeSingleEntityAction);
  const [updateEntity] = useApi(updateEntityMutation);
  const [getApprovedEntities] = useApi(
    getAllEntitiesQuery,
    storeAllEntitiesAction
  );

  const onSubmit = async (values) => {
    console.log('entities', entities, entityId);
    if (!policyFile) {
      setErr('Document is required');
      return;
    }
    if (!(singleData ? singleData?.id : entityId)) {
      setErr('No entity is selected');
      return;
    }

    toast.loading(`Attaching policy file! Please wait...`);
    var singleData = entities.singleEntityDetail;
    // console.log('singleData', singleData);

    if (Object.keys(singleData).length === 0) {
      singleData =
        entities?.allEntities?.allEntity?.length > 0
          ? entities?.allEntities?.allEntity.find(
              (e) => parseInt(e?.id) === parseInt(entityId)
            )
          : null;
    }
    const apiData = {
      id: parseInt(singleData.id),
      businessName: singleData.name,
      dba: singleData.DBA,
      busniessTin: singleData.EIN,
      address: singleData.address,
      email: singleData.emailAddress,
      rate: values.premiumRate ? values.premiumRate : singleData.rate,
      internationalRate: values.internationalRate
        ? values.internationalRate
        : singleData.internationalRate,
      note: singleData.additionalNote,
      phone: singleData.phoneNumber,
      salesPersonCode: singleData.salesPersonCode?.id,
      website: singleData.website,
      since: singleData.since,
      emailRebate: singleData.rebateEmail,
      since: singleData.since,
      policyDocument: policyFile
        ? JSON.stringify(policyFile)
        : singleData.policyDocument,
    };
    console.log('apiData:', apiData);
    const { data, errors } = await updateEntity(apiData);
    if (data) {
      toast.success('Policy document has been attached successfully!', {
        duration: TOAST_TIME,
      });
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to assign policy document', {
        duration: TOAST_TIME,
      });
    }
    closeModal();
    getApprovedEntities();
  };

  const handleUpload = async (file) => {
    try {
      const document = HANDLE_FILE_SIZE_CHECK(20, file);
      setIsLoading(true);
      toast.loading('File is uploading. Please wait...');
      if (document) {
        setPolicyFile(file);
        const formData = new FormData();
        formData.append('upload', file);

        axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => {
            setPolicyFile([...response.data][0]);
            setIsLoading(false);
            toast.dismiss();
          })
          .catch(() => {
            setIsLoading(false);
            toast.dismiss();
          });
      }
    } catch (err) {
      console.log('Error in uploading the file: ', err);
    }
  };

  const handleCancelUpload = () => setPolicyFile(null);

  return {
    policyFile,
    err,
    isLoading,
    onSubmit,
    handleUpload,
    handleCancelUpload,
  };
};

export default usePolicyDocument;
