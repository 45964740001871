import React from 'react';
import Industries from '../../features/industries';

const IndustriesPage = () => {
  return (
    <div>
      <Industries />
    </div>
  );
};

export default IndustriesPage;
