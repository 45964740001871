import React from 'react';
import { CustomIcon } from '../../../../common/customAssets';
import { checkBoxIcon, unCheckBoxIcon } from '../../../../assets';

function Checklist({ invoice, receipt, attachment }) {
  return (
    <div className='flex flex-col justify-center items-start gap-8 border shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl border-solid border-[#EAECF0]'>
      <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
        Checklist
      </div>
      <div className='flex flex-col gap-8'>
        <div className='flex gap-4'>
          <div>
            <CustomIcon
              icon={invoice.length > 0 ? checkBoxIcon : unCheckBoxIcon}
              className='w-9 h-9'
            />
          </div>
          <div className='flex flex-col gap-1'>
            <div className='text-[#77553D] text-base not-italic font-semibold leading-[normal]'>
              Invoice Uploaded
            </div>
            <div className='text-[#344054] text-sm not-italic font-normal leading-[normal]'>
              Shipments invoices are available. Click to download.
            </div>
          </div>
        </div>

        <div className='flex gap-4'>
          <div>
            <CustomIcon
              icon={attachment?.length > 0 ? checkBoxIcon : unCheckBoxIcon}
              className='w-9 h-9'
            />
          </div>
          <div className='flex flex-col gap-1'>
            <div className='text-[#77553D] text-base not-italic font-semibold leading-[normal]'>
              Receipt Uploaded
            </div>
            <div className='text-[#344054] text-sm not-italic font-normal leading-[normal]'>
              Shipments receipts are available. Click to download.
            </div>
          </div>
        </div>

        <div className='flex gap-4'>
          <div>
            <CustomIcon
              icon={receipt?.length > 0 ? checkBoxIcon : unCheckBoxIcon}
              className='w-9 h-9'
            />
          </div>
          <div className='flex flex-col gap-1'>
            <div className='text-[#77553D] text-base not-italic font-semibold leading-[normal]'>
              Proof of Damage Uploaded
            </div>
            <div className='text-[#344054] text-sm not-italic font-normal leading-[normal]'>
              Damage proofs are available. Click to download.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checklist;
