import { gql } from '@apollo/client';
import client from '../apollo.';

export const getDashboardStatisticsQuery = async ({ endDate, startDate }) => {
  const { data, error } = await client.query({
    query: gql`
      query dashboardStatistics($endDate: String!, $startDate: String!) {
        dashboardStatistics(endDate: $endDate, startDate: $startDate) {
          entitiesCount
          manualClaimsCount
          shipmentClaimsCount
          pendingManualClaimsCount
          pendingShipmentClaimsCount
          shipmentCount
          shipmentsTotalValue
          totalRevenue
          trialShipmentsCount
          monthlyEntitiesCount {
            count
            month
          }
          monthlyManualClaimsCount {
            count
            month
          }
          monthlyPremiumPercentage {
            month
            premiumPercentage
          }
          monthlyShipmentsCount {
            count
            month
          }
          monthlyBilling {
            totalPremium
            month
          }
          monthlyShipmentClaimsCount {
            count
            month
          }
        }
      }
    `,
    variables: {
      endDate: endDate,
      startDate: startDate,
    },
  });
  return { data, error };
};
