import React from 'react';
import PreviewFullImageModal from '../../../../common/custom/Modals/PreviewFullImage/modal';
import useFilePreview from '../../../../common/custom/file-preview/use-file-preview.hook';
import { pdfIcon, wordIcon } from '../../../../assets';
import { FILE_SIZE_B_TO_MB } from '../../../../helpers/helpers';

function FileViewer({ files }) {
  const { open, handleFullScreenPreview, handleClose } = useFilePreview();

  return (
    <div className='flex max-h-[126px] items-center justify-center gap-4 self-stretch border px-6 py-4 rounded-xl border-dashed border-[#B8BABE]'>
      {files &&
        files.map((file, key) => (
          <div className='flex flex-col relative' key={key}>
            <PreviewFullImageModal
              title='View Document'
              show={open}
              file={file}
              onClose={handleClose}
            />
            {file.location?.split('.').pop() === 'pdf' ? (
              <>
                <a href={file.location} download={file.originalname}>
                  <div className='flex items-center gap-4 p-2 rounded-lg bg-[#efefef]'>
                    <img
                      src={pdfIcon}
                      alt='file'
                      className='h-10 hover:cursor-pointer'
                    />
                    <div className=''>
                      <div className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6'>
                        {file?.originalname}
                      </div>
                      <div className='text-[#747474] text-xs not-italic font-normal leading-[normal]'>
                        {FILE_SIZE_B_TO_MB(file.size)}MB
                      </div>
                    </div>
                  </div>
                </a>
              </>
            ) : file.location?.split('.').pop() === 'docx' ||
              file.location?.split('.').pop() === 'doc' ? (
              <>
                <a href={file.location} download={file.originalname}>
                  <div className='flex items-center gap-4 p-2 rounded-lg bg-[#efefef]'>
                    <img
                      src={wordIcon}
                      alt='file'
                      className='h-10 hover:cursor-pointer'
                    />
                    <div className=''>
                      <div className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6'>
                        {file?.originalname}
                      </div>
                      <div className='text-[#747474] text-xs not-italic font-normal leading-[normal]'>
                        {FILE_SIZE_B_TO_MB(file.size)}MB
                      </div>
                    </div>
                  </div>
                </a>
              </>
            ) : (
              <a href={file.location} download={file.originalname}>
                <img
                  src={file?.location}
                  alt='file'
                  className='h-[80px] hover:cursor-pointer'
                  // onClick={handleFullScreenPreview}
                  // onClick={handleFullScreenPreview}
                />
              </a>
            )}
          </div>
        ))}
    </div>
  );
}

export default FileViewer;
