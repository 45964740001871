import React from 'react';
import ValidationErrorText from './ValidationErrorText';

const CustomRadio = ({
  className = 'text-[#667085] self-stretch cursor-pointer',
  options,
  register,
  name,
  label,
  errors,
}) => {
  return (
    <>
      {label && (
        <div className='mb-[6px] flex'>
          <label className='text-[#464646] text-sm leading-[normal]'>
            {label}
          </label>
        </div>
      )}

      <div className='flex flex-row gap-8'>
        {options.map((option, index) => (
          <label key={index} className='flex items-center cursor-pointer'>
            <input
              type='radio'
              {...register(name)}
              name={name}
              value={option.value}
              className={className}
              checked={option?.selected}
            />
            <span className='ml-2'>{option.label}</span>
          </label>
        ))}
      </div>
      {errors && errors[name] && (
        <div className='mt-1 mb-2'>
          <ValidationErrorText>{errors[name].message}</ValidationErrorText>
        </div>
      )}
    </>
  );
};

export default CustomRadio;
