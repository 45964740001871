import { signUpStepOneSchema } from "../../schema/sign-up-step-one";
import { useHookFormSubmission } from "../../../../hook/useHookFormSubmission";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormDataAction } from "../../../../store";

export const useSignUpStepOne = (increaseStepHandler) => {
  const { signUp } = useSelector((state) => state.forms);
  const dispatch = useDispatch();

  const { handleSubmit, register, errors, setValue } =
    useHookFormSubmission(signUpStepOneSchema);

  useEffect(() => {
    setValue(
      "nameOfBusiness",
      signUp.formData.step1?.nameOfBusiness
        ? signUp.formData.step1.nameOfBusiness
        : ""
    );
    setValue(
      "tin",
      signUp.formData.step1?.tin ? signUp.formData.step1.tin : ""
    );
    setValue(
      "industry",
      signUp.formData.step1?.industry ? signUp.formData.step1.industry : ""
    );
    setValue(
      "nameOfPrincipal",
      signUp.formData.step1?.nameOfPrincipal
        ? signUp.formData.step1.nameOfPrincipal
        : ""
    );
  }, []);

  const onSubmit = (values) => {
    increaseStepHandler();
    dispatch(updateFormDataAction({ step: 1, values }));
  };

  return { handleSubmit, onSubmit, register, errors, setValue };
};
