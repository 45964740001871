import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../../hook/useHookFormSubmission';
import {
  resetReportClaimFormAction,
  updateReportClaimFormAction,
} from '../../../../../store';
import { describeSchema } from '../../../../shipments/schema/describeClaimSchema';
import { useNavigate } from 'react-router-dom';

export const useDescribeClaim = (increaseStepHandler, toBeClaimed) => {
  const { step2 } = useSelector((state) => state.forms.claimFile.formData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, register, errors, watch, setValue, reset } =
    useHookFormSubmission(describeSchema);

  const { claimType } = watch();

  useEffect(() => {
    setValue('claimType', step2 && step2.claimType);
    setValue('description', step2 && step2.description);
  }, []);

  const onSubmit = (values) => {
    increaseStepHandler();
    dispatch(updateReportClaimFormAction({ step: 2, values }));
  };

  const onCancel = () => {
    reset();
    dispatch(resetReportClaimFormAction());
    navigate(-1);
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    claimType,
    toBeClaimed,
    onCancel,
  };
};
