import React from 'react';
import { deleteIcon, editIcon } from '../../../assets';
import { CustomButton } from '../../../common/custom';

const ShipFromActionMenu = ({ openEditModal, openDeleteModal, upper }) => {
  return (
    <>
      <div
        className={`w-[202px] min-h-[50px] z-10 shadow-md  bg-foundational-white  absolute ${
          upper ? 'top-[-40px]' : 'top-[30px]'
        } right-[40px] rounded-[8px] flex flex-col items-center justify-center border-[1px] border-solid border-[#EAECF0]   `}
      >
        <div
          className='flex gap-[8px] items-center px-[8px] cursor-pointer  w-full   min-h-[40px] bg-hover'
          onClick={openEditModal}
        >
          <CustomButton
            type='button'
            // onClick={openDeleteModal}
            icon={editIcon}
            text='Edit'
            className=' font-inter font-[500] flex gap-2'
          />
        </div>
        <div
          className='flex gap-[8px] items-center px-[8px] cursor-pointer  w-full   min-h-[40px] bg-hover'
          onClick={openDeleteModal}
        >
          <CustomButton
            type='button'
            onClick={openDeleteModal}
            icon={deleteIcon}
            text='Delete'
            className=' font-inter font-[500] flex gap-2'
          />
        </div>
      </div>
    </>
  );
};

export default ShipFromActionMenu;
