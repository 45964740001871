import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import UseManualClaimDetails from './use-manual-claim-details.hook';
import { extractArrayData } from '../../../helpers/helpers';
import Attachments from './components/attachments.component';
import UpdateClaim from './components/update-claim.component';
import Checklist from './components/checklist.component';
import Details from './components/details.component';
import SignedRelease from './components/signed-release.component';

function ManualClaimDetails() {
  const {
    claim,
    claimType,
    options,
    setClaimType,
    handleSubmit,
    register,
    errors,
    handleUpdateClaim,
    claimData,
    onCancel,
    claimClosefile,
    setClaimCloseFile,
    fileError,
    setFileError,
    adjustmentAmount,
    setAdjustmentAmount,
  } = UseManualClaimDetails();
  return (
    <div className='sm:px-[120px]'>
      <ClientsPanelHeader />
      <div className='flex mt-[22.5px] gap-6 flex-col sm:flex-row px-[20px]'>
        <div className='w-full max-w-[746px] flex flex-col gap-6'>
          <Attachments
            invoice={extractArrayData(claimData?.invoice)}
            receipt={extractArrayData(claimData?.receipts)}
            attachment={extractArrayData(claimData?.attachments)}
          />
          {/* {console.log('claimData?.signedReleaseClaim', claimData)} */}
          {claimData?.signedReleaseClaim && (
            <SignedRelease files={JSON.parse(claimData?.signedReleaseClaim)} />
          )}

          <UpdateClaim
            claim={claimData}
            claimType={claimType}
            register={register}
            errors={errors}
            options={options}
            setClaimType={setClaimType}
            handleSubmit={handleSubmit}
            handleUpdateClaim={handleUpdateClaim}
            onCancel={onCancel}
            claimClosefile={claimClosefile}
            setClaimCloseFile={setClaimCloseFile}
            fileError={fileError}
            setFileError={setFileError}
            // adjustmentAmount={adjustmentAmount}
            // setAdjustmentAmount={setAdjustmentAmount}
          />
        </div>

        <div className='flex flex-col max-w-[430px] gap-6'>
          <Checklist
            invoice={extractArrayData(claimData?.invoice)}
            receipt={extractArrayData(claimData?.receipts)}
            attachment={extractArrayData(claimData?.attachments)}
          />
          <Details claim={claimData} />
        </div>
      </div>
    </div>
  );
}

export default ManualClaimDetails;
