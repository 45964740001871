import { Tooltip } from '@material-tailwind/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  repeatIcon,
  deleteIcon,
  editIcon,
  userAccountAvatar,
  creditIcon,
  approvedIcon,
  viewIcon,
  suspendIcon,
  shipFromMenuIcon,
  shipmentsMenuIcon,
  claimFile,
  attachement,
} from '../../../assets';
import { CustomText } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../common/customAssets';
import { PATHS } from '../../../constants/paths';

const EntitiesActionMenu = ({
  filterStatus,
  openPolicyModal,
  openSuspendModal,
  openDeteleModal,
  openApprovalModal,
  openChangeRateModal,
  openChangeSalesMemberModal,
  entityActionClickedId,
  upper,
}) => {
  const navigate = useNavigate();

  const navigateHandler = (path) => {
    navigate(path);
  };

  return (
    <>
      {filterStatus === 'all' ? (
        <div
          className={`w-[190px] z-10 shadow-md bg-foundational-white  absolute ${
            upper ? 'top-[-220px]' : 'top-[-60px]'
          }  right-[50px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0] `}
        >
          <div
            onClick={() =>
              navigateHandler(
                `${PATHS.EDIT_ENTITIES}/${entityActionClickedId?.data.id}`
              )
            }
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='Edit'>
                <div>
                  <CustomIcon icon={editIcon} />
                </div>
              </Tooltip>

              <CustomText className='font-inter font-[500] ml-[8px] '>
                Edit
              </CustomText>
            </div>
          </div>

          <div
            onClick={() =>
              navigateHandler(
                `${PATHS.DETAIL_ENTITIES}/${entityActionClickedId?.data.id}`
              )
            }
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='View Profile'>
                <div>
                  <CustomIcon icon={viewIcon} />
                </div>
              </Tooltip>

              <CustomText className='font-inter font-[500] ml-[8px] '>
                View Entity
              </CustomText>
            </div>
          </div>

          <div
            onClick={openChangeSalesMemberModal}
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='View Profile'>
                <div>
                  <CustomIcon icon={userAccountAvatar} />
                </div>
              </Tooltip>
              <CustomText className='font-inter font-[500] ml-[8px] '>
                Change Sales Person
              </CustomText>
            </div>
          </div>

          <div
            onClick={() =>
              navigateHandler(
                `${PATHS.PAYMENT_ENTITY}/${entityActionClickedId?.data.id}`
              )
            }
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='Payment Method'>
                <div>
                  <CustomIcon icon={creditIcon} />
                </div>
              </Tooltip>

              <CustomText className='font-inter font-[500] ml-[8px] '>
                Payment Method
              </CustomText>
            </div>
          </div>

          <div
            onClick={openChangeRateModal}
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='Change'>
                <CustomIcon icon={repeatIcon} />
              </Tooltip>
              <CustomText className='font-inter font-[500] ml-[8px]  '>
                Change Premium Rate
              </CustomText>
            </div>
          </div>
          <div
            onClick={() =>
              navigate(
                `${PATHS.SHIPMENT_ENTITY}/${entityActionClickedId?.data.id}`
              )
            }
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div
            // to={`${PATHS.SHIPMENT_ENTITY}/${entityActionClickedId?.data.id}`}
            >
              <div className='flex'>
                <Tooltip className='mt-[-10px] z-50' content='Shipment View'>
                  <div className='rounded-full p-1 bg-[#000]'>
                    <CustomIcon icon={shipmentsMenuIcon} className='w-3 h-3' />
                  </div>
                </Tooltip>
                <CustomText className='font-inter font-[500] ml-[8px]  '>
                  Shipment View
                </CustomText>
              </div>
            </div>
          </div>

          <div
            onClick={openSuspendModal}
            className='cursor-pointer flex items-center  w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px]'
          >
            <Tooltip className='mt-[-10px] z-50' content='Suspend'>
              <div>
                <CustomIcon icon={suspendIcon} className='w-5 h-5 fw-bold' />
              </div>
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]  '>
              <div
                // className='font-inter font-[500] text-[#D92D20] flex gap-[12px]'
                onClick={openSuspendModal}
              >
                {entityActionClickedId?.data?.isBlocked
                  ? 'Continue'
                  : 'Suspend'}
              </div>
            </CustomText>
          </div>

          <div
            onClick={openPolicyModal}
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='View Profile'>
                <div>
                  <CustomIcon icon={attachement} />
                </div>
              </Tooltip>

              <CustomText className='font-inter font-[500] ml-[8px] '>
                {entityActionClickedId?.data?.policyDocument
                  ? 'Update Policy'
                  : 'Attach Policy'}
              </CustomText>
            </div>
          </div>

          <div
            onClick={openDeteleModal}
            className='cursor-pointer flex items-center  w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px]'
          >
            <Tooltip className='mt-[-10px] z-50' content='Delete'>
              <div>
                <CustomIcon icon={deleteIcon} />
              </div>
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]'>
              <div
                className='font-inter font-[500] text-[#D92D20] flex gap-[12px]'
                onClick={openDeteleModal}
              >
                Delete
              </div>
            </CustomText>
          </div>
        </div>
      ) : (
        <div
          className={`w-[190px] z-10 shadow-md bg-foundational-white  absolute ${
            upper ? 'top-[-100px]' : 'top-[30px]'
          }  right-[50px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0] `}
        >
          <div
            onClick={() => navigateHandler(`${PATHS.EDIT_ENTITIES}`)}
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='Edit'>
                <div>
                  <CustomIcon icon={editIcon} />
                </div>
              </Tooltip>

              <CustomText className='font-inter font-[500] ml-[8px] '>
                Edit
              </CustomText>
            </div>
          </div>

          <div
            onClick={openApprovalModal}
            className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
          >
            <div className='flex'>
              <Tooltip className='mt-[-10px] z-50' content='Approve Entity'>
                <div>
                  <CustomIcon icon={approvedIcon} />
                </div>
              </Tooltip>
              <CustomText className='font-inter font-[500] ml-[8px] text-[#228400] '>
                Approve Entity
              </CustomText>
            </div>
          </div>

          <div
            onClick={openDeteleModal}
            className='cursor-pointer flex items-center  w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px]'
          >
            <Tooltip className='mt-[-10px] z-50' content='Delete'>
              <div onClick={openDeteleModal}>
                <CustomIcon icon={deleteIcon} />
              </div>
            </Tooltip>
            <CustomText className='font-inter font-[500] ml-[8px]'>
              <div className='font-inter font-[500] text-[#D92D20] flex gap-[12px]'>
                Delete
              </div>
            </CustomText>
          </div>
        </div>
      )}
    </>
  );
};

export default EntitiesActionMenu;
