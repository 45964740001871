import React from 'react';

import CustomContainer from '../../../../common/custom/CustomContainer';
import {
  CustomButton,
  CustomInput,
  ValidationErrorText,
} from '../../../../common/custom';
import { useSignUpStepTwo } from './useSignUpStepTwo';

const SignUpStepTwo = ({ increaseStepHandler, decreaseStepHandler }) => {
  const { handleSubmit, onSubmit, register, errors } =
    useSignUpStepTwo(increaseStepHandler);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col my-8 gap-6 w-full'
    >
      <div className='flex flex-col sm:flex-row justify-center gap-6'>
        <CustomContainer className='w-full'>
          <CustomInput
            required
            name='streetAddress'
            register={register}
            placeholder='Enter your business address'
            label='Business Address'
          />
          {errors.streetAddress && (
            <ValidationErrorText>
              {errors?.streetAddress.message}
            </ValidationErrorText>
          )}
        </CustomContainer>
        <CustomContainer className='w-full'>
          <CustomInput
            required
            name='phoneNumber'
            register={register}
            placeholder='Enter your phone number'
            label='Phone number'
          />
          {errors.phoneNumber && (
            <ValidationErrorText>
              {errors?.phoneNumber.message}
            </ValidationErrorText>
          )}
        </CustomContainer>
      </div>
      <div className='flex flex-col sm:flex-row gap-6'>
        <CustomContainer className='w-full'>
          <CustomInput
            required
            name='emailAddress'
            register={register}
            placeholder='Enter your email address'
            label='Email address'
          />
          {errors.emailAddress && (
            <ValidationErrorText>
              {errors?.emailAddress.message}
            </ValidationErrorText>
          )}
        </CustomContainer>
        <CustomContainer className='w-full'>
          <CustomInput
            name='website'
            register={register}
            placeholder='Enter your website'
            label='Website(optional)'
          />
          {errors.website && (
            <ValidationErrorText>{errors?.website.message}</ValidationErrorText>
          )}
        </CustomContainer>
      </div>

      <div className='flex flex-col sm:flex-row justify-center mt-2 max-w-[349px] gap-3 mx-auto '>
        <CustomButton
          onClick={() => decreaseStepHandler()}
          type='button'
          text='Back'
          className='white-bg-btn'
        />
        <CustomButton
          text='Next'
          className='brown-bg-btn'
        />
      </div>
    </form>
  );
};

export default SignUpStepTwo;
