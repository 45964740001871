import { EditEntities } from "../../features/entities";
import React from "react";

const EditEntitiesPage = () => {
  return (
    <>
      <EditEntities />
    </>
  );
};

export default EditEntitiesPage;
