import { configureStore } from '@reduxjs/toolkit';

import { persistStore } from 'redux-persist';
import { rootReducer } from './slices';
import persistConfig from './persist.config';

// ****** AUth Actions**********//

// ***********************

// *********ui actions***********//
import { toggleMobileNavBarAction } from './slices/ui-slice';

// *********forms actions*******************************************************************************//
import {
  resetSignUpAction,
  setStepAction,
  updateFormDataAction,
  updateReportShipmentFormAction,
  setReportShipmentFormStepAction,
  resetReportShipmentFormAction,
  updateReportClaimFormAction,
  setReportClaimFormStepAction,
  resetReportClaimFormAction,
} from './slices/formsSlice';

// ******************************//

// ***********shipments actions*************//

import {
  storeAllShipmentsAction,
  addShipmentAction,
  entityShipmentAction,
} from './slices/shipmentsSlice';

// ***************************************** //

// ***********Claim actions*************//

import {
  storeAllClaimAction,
  addClaimAction,
  cancelClaimAction,
} from './slices/claimSlices';

// ***************************************** //

//***************addresses actions****************************/

import {
  storeAllEntityAddressAction,
  storeAllAddressesAction,
} from './slices/addressesSlice';
// **************************************************************

//**************staff actions */

import { storeAllStaffAction } from './slices/staffSlice';

//**************entities actions */

import {
  storeAllEntitiesAction,
  storeSingleEntityAction,
} from './slices/entitiesSlice';
//**************************** */

//**************onboarding actions */

import { storeUnapprovedEntitiesAction } from './slices/onBoardingSlice';

//**************************** */

// *****************billing actions**************************/

import { storeAllBillingAction } from './slices/billingSlice';

// **********************

// *****************payment actions**************************/

import { storeAllPaymentsAction } from './slices/payments';
import { storeLoginUserAction } from './slices/loginUserSlice';
// **********************
export const store = configureStore({
  reducer: persistConfig(rootReducer),
});

export {
  toggleMobileNavBarAction,
  resetSignUpAction,
  setStepAction,
  updateFormDataAction,
  updateReportShipmentFormAction,
  setReportShipmentFormStepAction,
  resetReportShipmentFormAction,
  storeAllShipmentsAction,
  addShipmentAction,
  storeAllStaffAction,
  storeAllClaimAction,
  addClaimAction,
  cancelClaimAction,
  updateReportClaimFormAction,
  setReportClaimFormStepAction,
  resetReportClaimFormAction,
  storeAllEntitiesAction,
  storeUnapprovedEntitiesAction,
  storeAllBillingAction,
  entityShipmentAction,
  storeAllEntityAddressAction,
  storeAllPaymentsAction,
  storeSingleEntityAction,
  storeAllAddressesAction,
  storeLoginUserAction,
};
export const persistor = persistStore(store);
