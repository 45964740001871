import React from 'react';
import CustomContainer from '../../../../common/custom/CustomContainer';
import { CustomIcon } from '../../../../common/customAssets';
import { noClaims } from '../../../../assets';
import { CustomHeading, CustomText } from '../../../../common/custom';

const NoClaimsComponent = ({ children }) => {
  return (
    <div className=' p-1'>
      <CustomContainer>
        {children}
        <CustomContainer className='mt-[32px]  min-h-[620px] flex justify-center items-center bg-foundational-white '>
          <CustomContainer className='max-w-[342px] min-h-[355px]  w-full flex flex-col items-center'>
            <CustomHeading className='text-small-heading sm:text-medium-heading md:text-large-heading font-roboto font-[500] text-foundation-black '>
              There are no claims yet.
            </CustomHeading>
            <CustomText className='text-pure-black font-[400] font-roboto text-small-para sm:text-medium-para  mt-[8px]'>
              There are no claims yet.
            </CustomText>
            <CustomIcon
              icon={noClaims}
              className='mt-[40px] '
            />
          </CustomContainer>
        </CustomContainer>
      </CustomContainer>
    </div>
  );
};

export default NoClaimsComponent;
