import { createSlice } from '@reduxjs/toolkit';
import { storeAllBilling } from './billingActions';

const initialState = {
  billing: [],
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    storeAllBillingAction: storeAllBilling,
  },
});

export const billingReducer = billingSlice.reducer;
export const { storeAllBillingAction } = billingSlice.actions;
