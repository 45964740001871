import * as yup from 'yup';

export const describeSchema = yup.object().shape({
  claimType: yup.string().required('Claim Type is required'),
  description: yup.string().required('Description is required'),
});

export const updateClaimSchema = yup.object().shape({
  claimType: yup.string().required('Claim Type is required'),
  internalNote: yup.string().required('Internal Note is required'),
});

export const updateManualClaimSchema = yup.object().shape({
  internalNote: yup.string().required('Internal Note is required'),
});
