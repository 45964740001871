import React from 'react';
import {
  CustomButton,
  CustomDropdown,
  ValidationErrorText,
} from '../../../../common/custom';

import { useReportShipmentStepThree } from './useReportShipmentStepThree';
import CustomDatePicker from '../../../../common/custom/CustomDatePicker';

const ReportShipmentStepThree = ({ decreaseStepHandler }) => {
  const { handleSubmit, onSubmit, register, errors, setValue, carriers } =
    useReportShipmentStepThree();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='stepper-cards'>
      <h1 className='text-2xl font-semibold mb-6 text-foundation-brown'>
        Report Shipment
      </h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 mb-4'>
        <div className='flex rounded-md shadow-sm relative'>
          <CustomDatePicker
            label='Pickup Date'
            name='pickupDate'
            register={register}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>

        {errors.pickupDate && (
          <div className='mb-[45px] mt-2'>
            <ValidationErrorText>
              {errors.pickupDate.message}
            </ValidationErrorText>
          </div>
        )}

        <div className='flex rounded-md shadow-sm relative'>
          <CustomDatePicker
            label='Delivery Date'
            name='deliveryDate'
            register={register}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>

        {errors.deliveryDate && (
          <div className='mb-[45px] mt-2'>
            <ValidationErrorText>
              {errors.deliveryDate.message}
            </ValidationErrorText>
          </div>
        )}
      </div>
      <CustomDropdown
        label='Select Carrier'
        placeholder='Select Carrier'
        options={
          carriers
          // [
          //   { id: 18, name: 'AN Trucking' },
          //   { id: 16, name: 'Bluegrace' },
          //   { id: 17, name: 'CMV Super Trucking' },
          //   { id: 13, name: 'CR Creative' },
          //   { id: 19, name: 'Daylight' },
          //   { id: 7, name: 'Elite Trucking Services' },
          //   { id: 8, name: 'H20 Logistics ' },
          //   { id: 2, name: 'Julio Express' },
          //   { id: 1, name: 'Netbonds' },
          //   { id: 14, name: 'New Penn Mortor Express' },
          //   { id: 5, name: 'Nippon Express' },
          //   { id: 9, name: 'Premier Installations At Linea' },
          //   { id: 3, name: 'Rigos Delivery' },
          //   { id: 21, name: 'ScanGlobal' },
          //   { id: 12, name: 'Total Transportation & Distribution, Inc' },
          //   { id: 10, name: 'Tramo ' },
          //   { id: 20, name: 'West Direct' },
          //   { id: 11, name: 'Wetzel & Son Moving & Storage ' },
          //   { id: 6, name: 'White Glove' },
          //   { id: 15, name: 'YRC Freight' },
          //   { id: 4, name: 'Other' },
          // ]
        }
        setValue={setValue}
        name='carrier'
      />
      {errors.carrier && (
        <ValidationErrorText>{errors.carrier.message}</ValidationErrorText>
      )}

      <div className='flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
        <CustomButton
          type='button'
          onClick={decreaseStepHandler}
          className='white-bg-btn'
          variant='outline'
          text='Previous'
        />

        <CustomButton className='brown-bg-btn' text='Next' />
      </div>
    </form>
  );
};

export default ReportShipmentStepThree;
