// store/paginationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPage: (state, action) => {
      const { tableId, page } = action.payload;
      state[tableId] = page;
    }
  }
});

export const { setPage } = paginationSlice.actions;
export default paginationSlice.reducer;
