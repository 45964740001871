export const storeAllAddresses = (state, action) => {
  state.addresses = action?.payload?.locationAddresses;
};

export const setAddress = (state, action) => {
  state.selectedAddress = action.payload;
};

export const storeAllEntityAddress = (state, action) => {
  state.entityAddress = action.payload;
};
