import React from 'react';
import CustomText from './CustomText';
import ValidationErrorText from './ValidationErrorText';

function CustomDatePicker2({ label, onChange, error }) {
  return (
    <div className='w-full'>
      {label && (
        <div className='mb-[6px]'>
          <CustomText
            as='label'
            className='text-[#464646] text-sm not-italic font-medium leading-[normal]'
          >
            {label}
          </CustomText>
        </div>
      )}
      <input
        type='date'
        className='flex h-12 w-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background  border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] rounded-lg border-solid border-[#E7ECF4] bg-[#f8fafb]'
        onChange={onChange}
      />

      {error && (
        <div className='mt-2'>
          <div className='mt-1 mb-2'>
            {<ValidationErrorText>{error}</ValidationErrorText>}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomDatePicker2;
