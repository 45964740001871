import React from 'react';
import { FORMATE_AMOUNT, FORMATE_DATE } from '../../../../helpers/helpers';

function ShipmentDetail({ claim }) {
  return (
    <div className='flex flex-col justify-center items-start gap-8 self-stretch border shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] p-10 rounded-xl border-solid border-[#EAECF0]'>
      <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
        Shipment Details
      </div>

      <div className='flex w-full'>
        <div className='flex flex-col gap-6 w-full justify-between'>
          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Entity
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim.entity && claim.entity.name) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Filed On
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {FORMATE_DATE(claim && claim.createdAt, 'dd/mm/yy') || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Location
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim.location && claim.location.name) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Claim Value
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim &&
                (claim?.claimAmount
                  ? FORMATE_AMOUNT(claim.claimAmount)
                  : FORMATE_AMOUNT(claim.claimValue))) ||
                '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Recipient’s Contact
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim?.customerPhone) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Best way to contact
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim?.bestWayToContact) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Recipient Note
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim?.customerNote) || '---'}
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-4 w-full justify-between'>
          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Claim Value
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {claim && claim?.claimValue
                ? FORMATE_AMOUNT(claim && claim.claimValue)
                : FORMATE_AMOUNT(claim && claim.claimAmount) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Filed By
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim.filedByUser && claim.filedByUser.firstName) ||
                '---'}{' '}
              {claim && claim.filedByUser && claim.filedByUser.lastName}
            </div>
          </div>
          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Recipient's Name
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && JSON?.parse(claim?.shipment?.to)?.name) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Recipient's Email
            </div>
            <div className='text-[#475467] max-w-[142px] whitespace-nowrap overflow-hidden text-base not-italic font-medium leading-6'>
              {(claim && claim?.customerEmail) || '---'}
            </div>
          </div>

          <div className='flex flex-col h-[50px] gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Claim Status
            </div>
            <div className='whitespace-nowrap text-[#475467] text-base not-italic font-medium leading-6 items-center px-2 py-1 rounded-[21px] bg-[#f1eeec]'>
              {(claim && claim?.status) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Internal Comments
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim?.internalComments) || '---'}
            </div>
          </div>

          <div className='flex flex-col gap-[6px]'>
            <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
              Internal Note
            </div>
            <div className='text-[#475467] text-base not-italic font-medium leading-6'>
              {(claim && claim?.internalNote) || '---'}
            </div>
          </div>
        </div>
      </div>

      <div className='w-full'>
        <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
          Description
        </div>
        <div className='mt-[6px] text-[#475467] text-base not-italic font-medium leading-6'>
          {claim && claim?.description}
        </div>
      </div>
      {claim && claim?.partToBeClaimed && (
        <div className='w-full'>
          <div className='text-[#9C9C9C] text-sm not-italic font-medium leading-5'>
            Parts to be claimed
          </div>
          <div className='mt-[6px] text-[#475467] text-base not-italic font-medium leading-6'>
            {claim?.partToBeClaimed}
          </div>
        </div>
      )}
    </div>
  );
}

export default ShipmentDetail;
