import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/paths";

function useEditClaim() {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(PATHS.CLAIMS);
  };

  return {
    handleCancel,
  };
}

export default useEditClaim;
