import { PATHS } from "../../../constants/paths";

import {
  claimsMenyIcon,
  shipFromMenuIcon,
  billingMenuIcon,
  shipmentsMenuIcon,
  userAccountWhite,
  dashboard,
} from "../../../assets";

export const menuListData = [
  {
    menuName: "Dashboard",
    link: PATHS.DASHBOARD,
    icon: dashboard,
  },
  {
    menuName: "Shipments",
    children: [
      {
        menuName: "Shipments",
        link: PATHS.SHIPMENTS,
        icon: shipmentsMenuIcon,
      },
      {
        menuName: "Pending Shipments",
        link: PATHS.PENDING_SHIPMENTS,
        icon: shipmentsMenuIcon,
      },
    ]
  },
  {
    menuName: "Entities",
    link: PATHS.ENTITIES,
    icon: billingMenuIcon,
  },
  {
    menuName: "Billing",
    link: PATHS.BILLING,
    icon: claimsMenyIcon,
  },
  {
    menuName: "Claims",
    link: PATHS.CLAIMS,
    icon: shipFromMenuIcon,
  },
  {
    menuName: "Resource Management",
    children: [
      {
        menuName: "Carriers",
        link: PATHS.CARRIERS,
        icon: shipmentsMenuIcon,
        user: "ADMIN",
      },
      {
        menuName: "Industries",
        link: PATHS.INDUSTRIES,
        icon: shipmentsMenuIcon,
        user: "ADMIN",
      },
      {
        menuName: "Shipment Types",
        link: PATHS.SHIPMENT_TYPES,
        icon: shipmentsMenuIcon,
        user: "ADMIN",
      },
      {
        menuName: "Sales Members",
        link: PATHS.SALES_MEMBERS,
        icon: userAccountWhite,
        user: "ADMIN",
      },
    ]
  },
];
