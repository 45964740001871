// Breadcrumbs.js
import { Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { arrowLeft, homeIcon } from '../../assets';
import { setPage } from '../../store/slices/paginationSlice';
import { CustomIcon } from '../customAssets';

const capitalizeFirstLetter = (str) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const isNumeric = (str) => {
  return /^\d+$/.test(str);
};

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const shouldRemoveLastSegment =
    pathnames.length > 0 && isNumeric(pathnames[pathnames.length - 1]);

  const handleNavigation = (routeTo) => {
    navigate(routeTo);
    dispatch(setPage({ tableId: routeTo, page: 1 }));
  }
  return (
    <div>
      <div className='flex items-center bg-foundation-white opacity-90 py-1 px-2'>
        <span className='mr-2'>
          <Link
            to='/'
            className='text-blue-500 flex'
          >
            <CustomIcon icon={homeIcon} />
          </Link>
        </span>
        <span className='mr-1 text-[#D0D5DD]'>/</span>
        {pathnames.map((name, index) => {
          if (index === pathnames.length - 1 && shouldRemoveLastSegment) {
            return null;
          }
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return (
            <span
              key={name}
              className={`${isLast ? 'text-gray-600' : 'text-foundation-brown'}`}
            >
              {index > 0 && <span className='ml-1 text-[#D0D5DD]'> / </span>}
              <span className='cursor-pointer' onClick={() => handleNavigation(routeTo)}>{capitalizeFirstLetter(name)}</span>
            </span>
          );
        })}
      </div>
      {pathnames?.length > 1 &&
        <div className='pt-6'>
          <Button onClick={() => navigate(-1)} sx={{ display: 'flex', alignItems: 'center', gap: '3px', border: '1px solid #77553d', color: '#77553d' }}>
            <img src={arrowLeft} alt='arrow left' /> <span>Back</span>
          </Button>
        </div>
      }
    </div>
  );
};

export default Breadcrumbs;
