import { useEffect, useState } from 'react';
import { getAllActiveClaimsQuery } from '../../../api';
import { FORMATE_AMOUNT, FORMATE_DATE } from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';

export const useClaims = () => {
  const navigate = useNavigate();
  const [getAllClaims, isLoading, claimsError, data] = useApi(
    getAllActiveClaimsQuery
  );

  const [currentTab, setCurrentTab] = useState('claims');

  useEffect(() => {
    getAllClaims();
  }, []);

  const reportClaimHandler = () => {
    navigate(`${PATHS.REPORT_MANUAL_CLAIM}`);
  };

  const concludeHandler = () => {
    navigate(`${PATHS.CONCLUDE_TRANSIT}`);
  };

  const newClaimHandler = () => {
    navigate(`${PATHS.CLAIM_FILE}`);
  };

  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
  };

  const currentClaims =
    currentTab == 'claims'
      ? data?.activeClaims?.claims
      : data?.activeClaims?.manualClaims;

  const modifiedData = currentClaims?.map((claim) => {
    return {
      id: claim.id,
      entity: claim?.entity?.name,
      filedBy: claim?.filedByName,
      // claim?.filedByUserType === 'User'
      //   ? claim?.filedByUser?.firstName + ' ' + claim?.filedByUser?.lastName
      //   : !claim?.filedByEntityMember // this check is for shipment claims which do not have entity member type
      //   ? claim?.filedByUser?.firstName + ' ' + claim?.filedByUser?.lastName
      //   : claim?.filedByEntityMember?.firstName +
      //     ' ' +
      //     claim?.filedByEntityMember?.lastName,
      from:
        claim.shipment && claim.shipment.from && claim.shipment.from.nickName,
      carrier:
        claim.shipment && claim.shipment.carrier && claim.shipment.carrier.name,
      shipmentType:
        claim.shipment &&
        claim.shipment.shipmentType &&
        claim.shipment.shipmentType.name,
      claimId: claim.claimId,
      shipValue: claim.shipment && FORMATE_AMOUNT(claim?.shipment?.totalValue),
      value: claim.claimValue
        ? FORMATE_AMOUNT(claim.claimValue)
        : FORMATE_AMOUNT(claim.claimAmount),
      adjusted:
        currentTab == 'claims'
          ? claim.claimAmount
            ? FORMATE_AMOUNT(claim.claimAmount)
            : '-'
          : claim.adjustedAmount
          ? FORMATE_AMOUNT(claim.adjustedAmount)
          : '-',
      partial: claim?.partToBeClaimed ? true : false,
      filledOn: claim.createdAt && FORMATE_DATE(claim.createdAt, 'dd/mm/yyyy'),
      status: claim.status,
      createdAt: claim.createdAt,
    };
  });

  return {
    data: modifiedData,
    isLoading,
    reportClaimHandler,
    concludeHandler,
    newClaimHandler,
    currentTab,
    handleTabSwitch,
  };
};
