import React from 'react';
import CustomContainer from '../../common/custom/CustomContainer';
import { ClientsPanelHeader, CustomInput } from '../../common/custom';

const Profile = () => {
  const user = JSON.parse(localStorage.getItem('login-user'));

  return (
    <>
      <CustomContainer>
        <ClientsPanelHeader />
        <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
          <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
            Profile
          </h1>
          <div>
            <div className='items-center grid grid-cols-1 sm:grid-cols-2 gap-[16px] mb-[22px] sm:mb-[16x]'>
              <div
                className='cursor-pointer'
                // onClick={() => openCustomerDetailModal()}
              >
                <CustomInput
                  readOnly={true}
                  //   placeholder={data ? beautifyAddress(streetPlaceholder()) : ''}
                  placeholder={user.firstName}
                  label='First Name'
                />
              </div>
              <div
                className='cursor-pointer'
                // onClick={() => openCustomerDetailModal()}
              >
                <CustomInput
                  readOnly={true}
                  placeholder={user.lastName}
                  label='Last Name'
                />
              </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div className='mb-4'>
                <CustomInput
                  readOnly
                  name='declaredValue'
                  //   register={register}
                  placeholder={user.emailAddress}
                  type='text'
                  label='Email'
                  //   value={declaredValue}
                  //   onChange={(e) => handleDeclaredValue(e.target.value)}
                />
              </div>

              <div className='mb-4'>
                <CustomInput
                  readOnly
                  name='declaredValue'
                  //   register={register}
                  placeholder={user.phoneNumber ? user.phoneNumber : 'NA'}
                  type='text'
                  label='Phone Number'
                  //   value={declaredValue}
                  //   onChange={(e) => handleDeclaredValue(e.target.value)}
                />
              </div>
            </div>

            <div className='mb-4'>
              <CustomInput
                readOnly
                name='declaredValue'
                //   register={register}
                placeholder={user.additionalNote ? user.additionalNote : 'NA'}
                type='text'
                label='Additional Note'
                //   value={declaredValue}
                //   onChange={(e) => handleDeclaredValue(e.target.value)}
              />
            </div>
          </div>
        </div>
      </CustomContainer>
    </>
  );
};

export default Profile;
