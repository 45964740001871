// import React, { useEffect, useRef } from 'react';
// import { CustomText } from '../../../common/custom';

// // Define the AddressComponents and GoogleMapAutocompleteProps interfaces here if needed

// const GoogleMapAutocomplete = ({
//   label,
//   onChange,
//   street,
//   setstreet,
//   placeholder,
//   isFormatted,
//   onPlaceSelect,
//   setValue,
// }) => {
//   const inputRef = useRef(null);
//   let autocomplete;

//   useEffect(() => {
//     if (inputRef.current && window.google) {
//       autocomplete = new window.google.maps.places.Autocomplete(
//         inputRef.current,
//         {
//           types: ['address'],
//           // componentRestrictions: { country: 'us' }, // Limit to US only
//         }
//       );
//       autocomplete.addListener('place_changed', () => {
//         const place = autocomplete.getPlace();

//         if (!place.geometry) {
//           alert(
//             'Invalid address selected. Please choose a valid address from the suggestions.'
//           );
//           return;
//         }
//         var newaddress = extractAddressComponents(place);
//         const address = place.formatted_address;
//         const latitude = place.geometry.location.lat();
//         const longitude = place.geometry.location.lng();
//         onPlaceSelect(address || '', latitude, longitude);
//         if (isFormatted) {
//           onChange(address);
//         } else {
//           onChange(newaddress);
//         }
//       });
//     }
//   }, [onPlaceSelect]);

//   function extractAddressComponents(place) {
//     const addressComponents = {
//       street: '',
//       city: '',
//       unitNo: '',
//       state: '',
//       zipCode: '',
//       country: '',
//     };

//     place.address_components?.forEach((component) => {
//       if (component.types.includes('street_number')) {
//         addressComponents.street = component.long_name;
//       } else if (component.types.includes('route')) {
//         addressComponents.street += ' ' + component.long_name;
//       } else if (component.types.includes('locality')) {
//         addressComponents.city = component.long_name;
//       } else if (component.types.includes('administrative_area_level_1')) {
//         addressComponents.state = component.short_name;
//       } else if (component.types.includes('postal_code')) {
//         addressComponents.zipCode = component.long_name;
//       }
//     });

//     return addressComponents;
//   }

//   return (
//     <input
//       ref={inputRef}
//       type='text'
//       value={street}
//       setValue={setValue}
//       onChange={(event) => (
//         setstreet ? setstreet(event.target.value) : null
//       )}
//       placeholder={placeholder}
//       className='text-[#667085] text-base not-italic font-normal leading-6 flex items-center gap-2 self-stretch border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-4 py-3 rounded-lg border-solid border-[#E7ECF4] w-[100%] h-[44px] rounded-7 lg:px-[14px] bg-foundation-gray outline-none  '
//     />
//   );
// };

// export default GoogleMapAutocomplete;

import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { CustomInput } from '../../../common/custom';

const GoogleMapAutocomplete = ({
  onChange,
  street,
  setstreet,
  classes,
  isFormatted,
  onPlaceSelect,
  name,
  setValue,
  trigger,
  errors,
  address,
  register,
  value,
}) => {
  const inputRef = useRef(null);
  let autocomplete;

  useEffect(() => {
    if (inputRef.current && window.google) {
      handleAutoComplete();
    }
  }, [onPlaceSelect]);

  const handleAutoComplete = () => {
    autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ['address'],
        // componentRestrictions: { country: "us" }, // Limit to US only
      }
    );
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        toast.dismiss();
        toast.error(
          'Invalid address selected. Please choose a valid address from the suggestions.',
          { duration: TOAST_TIME }
        );
        return;
      }
      var newaddress = extractAddressComponents(place);
      const address = place.formatted_address;
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      onPlaceSelect(address || '', latitude, longitude);
      if (isFormatted) {
        onChange(address);
      } else {
        onChange(newaddress);
      }
    });
  };

  function extractAddressComponents(place) {
    const addressComponents = {
      street: '',
      city: '',
      unitNo: '',
      state: '',
      zipCode: '',
      country: '',
    };

    place.address_components?.forEach((component) => {
      if (component.types.includes('street_number')) {
        addressComponents.street = component.long_name;
      } else if (component.types.includes('route')) {
        addressComponents.street += ' ' + component.long_name;
      } else if (component.types.includes('locality')) {
        addressComponents.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        addressComponents.state = component.short_name;
      } else if (component.types.includes('postal_code')) {
        addressComponents.zipCode = component.long_name;
      }
    });

    return addressComponents;
  }

  return (
    <CustomInput
      inputRef={inputRef}
      errors={errors}
      register={register}
      name={name}
      trigger={trigger}
      setValue={setValue}
      value={address}
      onChange={(e) => onChange(e.target.value)}
      placeholder='Street address'
    />
  );
};

export default GoogleMapAutocomplete;
