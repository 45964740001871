import React from 'react';
import { ClientsPanelHeader } from '../../../common/custom';
import CustomContainer from '../../../common/custom/CustomContainer';
import EntitiesTableLayout from '../EntitiesTableLayout';
const Entities = () => {
  return (
    <>
      <CustomContainer>
        {/* <ClientsPanelHeader /> */}
        <EntitiesTableLayout />
      </CustomContainer>
    </>
  );
};

export default Entities;
