import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateReportShipmentFormAction } from '../../../../store';
import reportShipmentStepTwoSchema from '../../schema/reportShipmentStepTwoSchema';
import toast from 'react-hot-toast';
import {
  CONFIRMATION_DECLARED_VALUE,
  MAXIMUM_DECLARED_VALUE,
} from '../../../../constants/declaredValue';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import { HANDLE_FILE_SIZE_CHECK } from '../../../../helpers/helpers';
import axios from 'axios';
import _ from 'lodash';
import { getEntity } from '../../../../api';
import useApi from '../../../../hook/useApi';
import { getShipmentTypesQuery } from '../../../../api/services/admin.service';

export const useReportShipmentStepTwo = (increaseStepHandler, isTrial) => {
  const [declaredValue, setDeclaredValue] = useState('');
  const [declaredValueModal, setDeclaredValueModal] = useState(false);
  const [declaredValueModalClose, setDeclaredValueModalClose] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTrialReported, setIsTrialReported] = useState(false);

  const [merchandiseTypeOptions, setMerchandiseTypeOptions] = useState([]);
  const [getShipmentTypes] = useApi(getShipmentTypesQuery);

  const { reportShipment } = useSelector((state) => state.forms);
  const dispatch = useDispatch();
  const [getSingleEntity] = useApi(getEntity);

  const { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(reportShipmentStepTwoSchema);

  const watchedMerchandiseType = watch('merchandiseType');

  const [rate, setRate] = useState(0);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const _data = await getShipmentTypes();
      setMerchandiseTypeOptions(_data?.data?.allShipmentTypes);
    };
    getData();
  }, []);

  useEffect(() => {
    const getEntity = async () => {
      const _selected = await getSingleEntity(
        parseInt(reportShipment?.formData?.step1?.entity)
      );

      setRate(
        // todo.. handle intl shipment for from address
        reportShipment.formData.step1?.country == 'United States'
          ? reportShipment.formData.step1?.state === 'Alaska' ||
            reportShipment.formData.step1?.state === 'Hawaii'
            ? 0.02
            : _selected?.data?.entity?.rate
          : _selected?.data?.entity?.internationalRate
      );
    };
    getEntity();
  }, [reportShipment]);

  const handleUploadAttachments = async (files) => {
    setIsLoading(true);
    toast.loading('Please wait! Uploading...');

    const promises = [];
    const uploadedAttachments = [];

    Object.keys(files).forEach((key) => {
      const document = HANDLE_FILE_SIZE_CHECK(20, files[key]);

      if (document) {
        const formData = new FormData();
        formData.append('upload', files[key]);

        const promise = axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => {
            uploadedAttachments.push(response.data);
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });

        promises.push(promise);
      }
    });

    try {
      await Promise.all(promises);
      toast.dismiss();
      setIsLoading(false);

      setAttachments([...attachments, ...uploadedAttachments]);
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.dismiss();
      setIsLoading(false);
    }
  };

  const handleToggleClick = () => {
    setIsTrialReported(!isTrialReported);
  };

  const handleUploadAttachmentsCancel = (file) => {
    const flattenedAttachments = _.flatten(attachments);
    setAttachments(() => {
      return flattenedAttachments.filter((attachment) => {
        return attachment.location !== file.location;
      });
    });
  };

  useEffect(() => {
    setValue('document', attachments);
  }, [attachments]);

  useEffect(() => {
    setValue(
      'declaredValue',
      declaredValue ||
        (reportShipment.formData.step2 &&
          reportShipment.formData.step2?.declaredValue)
    );
    setDeclaredValue(
      reportShipment.formData.step2 &&
        reportShipment.formData.step2?.declaredValue
    );
    setValue(
      'merchandiseType',
      reportShipment.formData.step2?.merchandiseType
        ? reportShipment.formData.step2.merchandiseType
        : ''
    );
    setValue('document', reportShipment.formData.step2?.document || null);
    setAttachments(reportShipment.formData.step2?.document || []);
    setValue(
      'additionalNote',
      reportShipment.formData.step2?.additionalNote
        ? reportShipment.formData.step2.additionalNote
        : ''
    );
  }, []);

  const closeDeclaredValueModal = () => {
    setDeclaredValueModal(false);
    setDeclaredValueModalClose(true);
  };

  const openDeclaredValueModal = () => {
    setDeclaredValueModal(true);
  };

  const handleDeclaredValue = (value) => {
    setDeclaredValue(value);
  };

  const onSubmit = (values) => {
    if (Number(declaredValue) > MAXIMUM_DECLARED_VALUE) {
      toast.dismiss();
      return toast.error('Value exceed', { duration: TOAST_TIME });
    }
    if (
      Number(declaredValue) > CONFIRMATION_DECLARED_VALUE &&
      !declaredValueModalClose
    ) {
      return setDeclaredValueModal(true);
    }
    values.isTrial = isTrialReported;
    increaseStepHandler();
    dispatch(updateReportShipmentFormAction({ step: 2, values }));
  };
  return {
    rate,
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    watchedMerchandiseType,
    declaredValueModalClose,
    closeDeclaredValueModal,
    declaredValueModal,
    declaredValue,
    isLoading,
    attachments: _.flatten(attachments),
    handleUploadAttachments,
    handleUploadAttachmentsCancel,
    handleDeclaredValue,
    merchandiseTypeOptions,
    handleToggleClick,
    isTrialReported,
  };
};
