import React, { useEffect } from 'react';
import { PATHS } from '../../constants/paths';
import { useNavigate } from 'react-router-dom';

const PaymentMethodSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(PATHS.DASHBOARD);
    }, 5000);
  }, []);

  return (
    <div className='flex flex-col items-center mt-10'>
      <svg
        className='w-24 h-24 text-green-500 animate-tick'
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          className='path'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M5 13l4 4L19 7'
        />
      </svg>{' '}
      <h2 className='text-xl font-bold'>Payment Successful</h2>
      <p className='text-lg text-gray-700'>
        Payment method has been updated successfully.
      </p>
      <p className='mt-24 text-sm text-black'>
        You will be automatically redirected to Home screen in few seconds!{' '}
      </p>
    </div>
  );
};

export default PaymentMethodSuccess;
