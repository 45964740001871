import { gql } from '@apollo/client';
import client from '../apollo.';

export const addLocationMutation = async (entityId, name) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation CreateLocation($createLocationInput: LocationCreateInput!) {
        createLocation(createLocationInput: $createLocationInput) {
          id
          createdAt
          stripeCustomerID
        }
      }
    `,
    variables: {
      createLocationInput: {
        entity: {
          connect: {
            id: entityId,
          },
        },
        name: name,
      },
    },
  });
  return data;
};
export const updateDefaultLocation = async (
  locationID,
  defaultPaymentMethodID
) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation Mutation($locationId: Float!, $defaultPaymentId: String!) {
        updateDefaultPaymentID(
          defaultPaymentID: $defaultPaymentId
          locationID: $locationId
        ) {
          id
        }
      }
    `,
    variables: {
      defaultPaymentId: defaultPaymentMethodID,
      locationId: locationID,
    },
  });
  return data;
};

export const getMyLocationsQuery = async (entityId) => {
  const { data } = await client.query({
    query: gql`
      query LocationAddresses(
        $locationWhereUniqueInput: LocationWhereUniqueInput!
      ) {
        locationAddresses(locationWhereUniqueInput: $locationWhereUniqueInput) {
          createdAt
          nickName
          locationId
          id
          address
        }
      }
    `,
    variables: {
      entityId: entityId,
    },
  });
  return data;
};

export const getEntityLocationsQuery = async (entityId) => {
  try {
    const { data } = await client.query({
      query: gql`
        query MyLocations($entityId: Int!) {
          myLocations(entityId: $entityId) {
            id
            name
          }
        }
      `,
      variables: {
        entityId: parseInt(entityId),
      },
    });
    return { data: data?.myLocations };
  } catch (error) {
    // console.error('Error fetching entity locations:', error);
    // throw error;
  }
};
