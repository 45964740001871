import React from 'react';
import Carriers from '../../features/carriers';

const CarriersPage = () => {
  return (
    <div>
      <Carriers />
    </div>
  );
};

export default CarriersPage;
