import React, { useState } from "react";
import CustomText from "./CustomText";
import ValidationErrorText from "./ValidationErrorText";
import { CustomIcon } from "../customAssets";
import { eye, eyeOff } from "../../assets";

const CustomInput = ({
  className = "text-[#667085] text-base not-italic font-normal leading-6 flex items-center gap-2 self-stretch border shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-4 py-3 rounded-lg border-solid border-[#E7ECF4] w-[100%] h-[44px] rounded-7 lg:px-[14px] bg-foundation-gray outline-none   ",
  type = "text",
  value,
  placeholder = "",
  register,
  name,
  label,
  readOnly = false,
  errors,
  required,
  asterikColor,
  onChange,
  setValue,
  trigger,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {label && (
        <div className="mb-[6px]">
          <CustomText
            as="label"
            className="text-[#464646] text-sm not-italic font-medium leading-[normal]"
          >
            {label}
            {required && (
              <span style={{ color: asterikColor ? asterikColor : "#77553D" }}>
                *
              </span>
            )}
          </CustomText>
        </div>
      )}

      <div className="relative">
        <input
          {...(register && { ...register(name) })}
          name={name}
          value={value}
          className={className}
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          readOnly={readOnly}
          onChange={(e) => {
            onChange && onChange(e); // existing onChange handler
            setValue && setValue(name, e.target.value); // set value for new onChange handler
            trigger && trigger(name); // Trigger revalidation for the specific field
          }}
        />
        {type === "password" && (
          <div
            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <CustomIcon icon={eye} />
            ) : (
              <CustomIcon icon={eyeOff} />
            )}
          </div>
        )}
      </div>

      {errors && errors[name] && (
        <div className="mt-1 mb-2">
          <ValidationErrorText>{errors[name].message}</ValidationErrorText>
        </div>
      )}
    </>
  );
};

export default CustomInput;
