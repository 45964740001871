import React from 'react';
import {
  ClientsPanelHeader,
  CustomEmptyDataComponent,
  CustomHeading,
} from '../../../common/custom';
import usePendingShipmentsList from './usePendingShipmentsList';
import CustomContainer from '../../../common/custom/CustomContainer';
import { PENDING_SHIPMENTS } from '../../../constants/columns';
import PendingShipmentsActionMenu from '../components/pendingShipmentAction.js';
import DeleteModal from '../../modals/delete-modal/index.jsx';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component.jsx';
import CircularILoader from '../../../common/custom/circularLoader.jsx';

const PendingShipmentsList = () => {
  const {
    data,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    showDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    cancelShipmentHandler,
    shipmentActionClickedData,
    approveShipmentHandler,
    shipmentActionClickedId,
    loading
  } = usePendingShipmentsList();

  if (data.length === 0 && !loading) {
    return (
      <CustomEmptyDataComponent heading='There are no Pending Shipments'>
        <ClientsPanelHeader />
      </CustomEmptyDataComponent>
    );
  }
  return (
    <>
      {showDeleteModal ? (
        <DeleteModal
          modalHeading='Delete Shipment?'
          modalPara='Are you sure you want to delete this Shipment? This action cannot be undone.'
          deleteHandler={() => cancelShipmentHandler(shipmentActionClickedId)}
          closeDeleteModal={closeDeleteModal}
        />
      ) : null}
      {loading ? (
        <div class='flex h-[126px] justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
          <CircularILoader />
        </div>
      ) : (
        <CustomContainer>
          <ClientsPanelHeader />

          <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
            <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
              <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
                Pending Shipments
              </CustomHeading>
            </CustomContainer>
            <div className=' overflow-x-auto'>
              <CustomTable
                table="/pending-shipments"
                header={PENDING_SHIPMENTS}
                rows={data}
                handleActionClick={handleActionClick}
                toggleActionMenu={toggleActionMenu}
                actionMenuRef={actionMenuRef}
                menu={
                  <PendingShipmentsActionMenu
                    data={shipmentActionClickedData}
                    openDeleteModal={openDeleteModal}
                    approveShipmentHandler={approveShipmentHandler}
                  />
                }
              />
            </div>
          </div>
        </CustomContainer>
      )}
    </>
  );
};

export default PendingShipmentsList;
