import { createSlice } from '@reduxjs/toolkit';
import {
  resetReportClaimForm,
  resetReportShipmentForm,
  resetSignUp,
  setReportClaimFormStep,
  setReportShipmentFormStep,
  setStep,
  updateFormData,
  updateReportClaimForm,
  updateReportShipmentForm,
} from './formsActions';

const initialState = {
  signUp: {
    currentStep: 1,
    formData: {},
  },
  reportShipment: {
    currentStep: 1,
    formData: {},
  },
  claimFile: {
    currentStep: 1,
    formData: {},
  },
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    updateFormDataAction: updateFormData,
    setStepAction: setStep,
    resetSignUpAction: resetSignUp,
    updateReportShipmentFormAction: updateReportShipmentForm,
    setReportShipmentFormStepAction: setReportShipmentFormStep,
    resetReportShipmentFormAction: resetReportShipmentForm,
    updateReportClaimFormAction: updateReportClaimForm,
    setReportClaimFormStepAction: setReportClaimFormStep,
    resetReportClaimFormAction: resetReportClaimForm,
  },
});

export const formsReducer = formsSlice.reducer;
export const {
  updateFormDataAction,
  setStepAction,
  resetSignUpAction,
  updateReportShipmentFormAction,
  setReportShipmentFormStepAction,
  resetReportShipmentFormAction,
  updateReportClaimFormAction,
  setReportClaimFormStepAction,
  resetReportClaimFormAction,
} = formsSlice.actions;
