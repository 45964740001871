import React from 'react';
import { CustomHeading, CustomModal, CustomText } from '../../../common/custom';
import { CustomCheckCircleIcon } from '../../../common/customAssets';

const PaymentMethodDescriptonMethodModal = ({
  closePaymentDescriptionModal,
}) => {
  return (
    <CustomModal>
      <div class='mx-auto max-w-[895px] w-full p-4 sm:p-6 lg:p-8 '>
        {/* <div class='rounded-md bg-white p-6 shadow'> */}
        <div class='flex justify-between'>
          <CustomHeading className='text-foundation-brown font-inter font-[600] text-[22px] sm:text-[26px] leading-[31.47px]'>
            Payment Method Description
          </CustomHeading>
          <button
            class='text-lg font-semibold'
            onClick={() => closePaymentDescriptionModal()}
          >
            ✕
          </button>
        </div>
        <CustomText className='ml-2 text-[12px] sm:text-[20px] font-inter font-[500] text-[#344054] mt-[26px] sm:mt-[32px]'>
          By keeping your payment method securely stored in our system, you can
          enjoy the following benefits
        </CustomText>
        <ul class='my-[32px] sm:my-[40px]'>
          <li class='flex items-start gap-[10px] mb-[14px] sm:mb-[24px]'>
            <div>
              <CustomCheckCircleIcon />
            </div>
            <span className='ml-2 text-[12px] sm:text-[20px] font-inter font-[500] text-[#344054]'>
              Convenience: It saves you time by eliminating the need to
              repeatedly enter payment information for each purchase.
            </span>
          </li>
          <li class='flex items-start gap-[10px] mb-[14px] sm:mb-[24px]'>
            <div>
              <CustomCheckCircleIcon />
            </div>
            <span className='ml-2 text-[12px] sm:text-[20px] font-inter font-[500] text-[#344054]'>
              Streamlined processes: Storing your payment method streamlines the
              process, ensuring faster and more efficient purchases.
            </span>
          </li>
          <li class='flex items-start gap-[10px] mb-[14px] sm:mb-[24px]'>
            <div>
              <CustomCheckCircleIcon />
            </div>
            <span className='ml-2 text-[12px] sm:text-[20px] font-inter font-[500] text-[#344054]'>
              Prompt service: With your payment method securely stored, our team
              can quickly process your orders and provide prompt service.
            </span>
          </li>
          <li class='flex items-start gap-[10px]'>
            <div>
              <CustomCheckCircleIcon />
            </div>
            <span className='ml-2 text-[12px] sm:text-[20px] font-inter font-[500] text-[#344054]'>
              Enhanced security: Storing your payment method prioritizes the
              safety of your financial information, reducing the risk of data
              breaches or unauthorized access.
            </span>
          </li>
        </ul>
        <p className='ml-2 text-[12px] sm:text-[20px] font-inter font-[500] text-[#344054]'>
          By taking care of payment details, you can focus on other important
          aspects of your business without worrying about payment processing
          delays.
        </p>
        {/* </div> */}
      </div>
    </CustomModal>
  );
};

export default PaymentMethodDescriptonMethodModal;
