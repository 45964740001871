import * as yup from 'yup';

export const recipientSchema = yup.object().shape({
  filedByName: yup.string().required('Filed By Name is required'),
  // location: yup.string().required('Location is required'),
  phoneNumber: yup
    .number()
    .typeError('Recipient’s Phone Number must be a number')
    .required('Recipient’s Phone Number is required'),
});
