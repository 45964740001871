import { createSlice } from "@reduxjs/toolkit";
import { storeAllEntities, storeEntity } from "./entitiesAction";

const initialState = {
  allEntities: [],
  singleEntityDetail: {},
};

const entitiesSlice = createSlice({
  name: "allEntites",
  initialState,
  reducers: {
    storeAllEntitiesAction: storeAllEntities,
    storeSingleEntityAction: storeEntity,
  },
});

export const entitiesReducer = entitiesSlice.reducer;
export const { storeAllEntitiesAction, storeSingleEntityAction } =
  entitiesSlice.actions;
