import React from 'react';
import SalesMembers from '../../features/salesMembers';

const SalesMembersPage = () => {
  return (
    <div>
      <SalesMembers />
    </div>
  );
};

export default SalesMembersPage;
