import formPageImg from './img/form-page-img.png';
import ceastaLogo from './svg/ceasta-logo.svg';
import backIcon from './svg/back_icon.svg';
import noClaims from './svg/no_claims.svg';
import noShipments from './svg/no-shipments.svg';
import ceastaLogoWhite from './svg/ceasta_logo_white.svg';
import marker from './svg/marker.svg';
import chevronDown from './svg/chevron-down.svg';
import chevronDownWhite from './svg/chevron-down-white.svg';
import plusBrown from './svg/plus_brown.svg';
import plusGray from './svg/plus_gray.svg';
import userCircle from './svg/user-circle.svg';
import homeIcon from './svg/home_icon.svg';
import divider from './svg/divider_icon.svg';
import nextIcon from './svg/next_icon.svg';
import actionButton from './svg/action_button.svg';
import deleteIcon from './svg/delete.svg';
import suspendIcon from './svg/suspend.png';
import editIcon from './svg/edit.svg';
import cross from './svg/cross.svg';
import crossCircle from './svg/cross-circle.svg';
import claimFile from './svg/claim-file.svg';
import uploadFileIcon from './svg/upload-cloud-02.svg';
import tickIcon from './svg/Tick.svg';
import downArrow from './svg/arror-down.svg';
import conclude from './svg/conclude.svg';
import attachement from './svg/attachement.svg';
import backCircle from './svg/back-circle.svg';
import closeIcon from './svg/close.svg';
import viewIcon from './svg/view.svg';
import viewClaimIcon from './svg/view-icon.svg';
import informationIcon from './svg/information.svg';
import calendarIcon from './svg/calendar.svg';
import download from './svg/download.svg';
import infoDarkIcon from './svg/info-dark.svg';
import checkBoxIcon from './svg/checked-box.svg';
import unCheckBoxIcon from './svg/unchecked-box.svg';
import locationIcon from './svg/location.svg';
import userIcon from './svg/userIcon.svg';
import bankIcon from './svg/bank.svg';
import alertCircleIcon from './svg/alert-circle.svg';
import alertCircleBlueIcon from './svg/alert-circle-blue.svg';
import blueBankIcon from './svg/bank-blue.svg';
import shipmentsMenuIcon from './svg/shipments-icon.svg';
import shipFromMenuIcon from './svg/ship-from-icon.svg';
import staffMenuIcon from './svg/staff-icon.svg';
import paymentsMenuIcon from './svg/payments-icon.svg';
import commissionIcon from './svg/commission-icon.svg';
import claimsMenyIcon from './svg/claims-icon.svg';
import billingMenuIcon from './svg/billing.svg';
import creditBlueIcon from './svg/credit-card-blue.svg';
import paymentVisaIcon from './svg/Payment-visa-icon.svg';
import paymentAmexIcon from './svg/Payment-amex-icon.svg';
import paymentMasterIcon from './svg/Payment-master-icon.svg';
import eye from './svg/eye.svg';
import eyeOff from './svg/eye-off.svg';
import logoutIcon from './svg/logout-icon.svg';
import userAccountAvatar from './svg/user-account.svg';
import userAccountWhite from './svg/user-account-white.svg';
import approvedIcon from './svg/approved-icon.svg';
import repeatIcon from './svg/repeat-icon.svg';
import checkIcon from './svg/check.svg';
import switchOffIcon from './svg/switch-off-icon.svg';
import switchOnIcon from './svg/switch-on-icon.svg';
import creditIcon from './svg/credit-card-icon.svg';
import changeShipmentsModalIcon from './svg/change-shipments-modal-icon.svg';
import arrowLeft from './svg/arrow-left.svg';
import brownClose from './svg/brown-close-icon.svg';
import redCross from './svg/red-cross.svg';
import filterIcon from './svg/filter.svg';
import pdfIcon from './svg/pdfIcon.svg';
import wordIcon from './svg/wordIcon.svg';
import printIcon from './svg/print-gif.gif';
import excelIcon from './svg/excel.png';
import arrowUp from './svg/arrow_up_icon.svg';
import arrowDown from './svg/arrow_down_icon.svg';
import dashboard from './svg/dashbaord.svg';

export {
  filterIcon,
  redCross,
  bankIcon,
  chevronDownWhite,
  dashboard,
  blueBankIcon,
  brownClose,
  arrowLeft,
  arrowDown,
  arrowUp,
  approvedIcon,
  repeatIcon,
  eye,
  eyeOff,
  paymentVisaIcon,
  paymentAmexIcon,
  paymentMasterIcon,
  claimsMenyIcon,
  paymentsMenuIcon,
  staffMenuIcon,
  shipFromMenuIcon,
  shipmentsMenuIcon,
  billingMenuIcon,
  formPageImg,
  ceastaLogo,
  backIcon,
  noClaims,
  ceastaLogoWhite,
  marker,
  chevronDown,
  plusBrown,
  plusGray,
  userCircle,
  homeIcon,
  divider,
  nextIcon,
  actionButton,
  deleteIcon,
  editIcon,
  cross,
  noShipments,
  claimFile,
  tickIcon,
  uploadFileIcon,
  downArrow,
  alertCircleIcon,
  alertCircleBlueIcon,
  conclude,
  attachement,
  crossCircle,
  backCircle,
  closeIcon,
  viewIcon,
  informationIcon,
  calendarIcon,
  logoutIcon,
  userAccountAvatar,
  userAccountWhite,
  viewClaimIcon,
  download,
  infoDarkIcon,
  checkBoxIcon,
  unCheckBoxIcon,
  checkIcon,
  switchOffIcon,
  switchOnIcon,
  changeShipmentsModalIcon,
  creditIcon,
  locationIcon,
  userIcon,
  creditBlueIcon,
  pdfIcon,
  wordIcon,
  suspendIcon,
  printIcon,
  excelIcon,
  commissionIcon,
};
