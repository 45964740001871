import React from "react";

import CustomContainer from "../../../common/custom/CustomContainer";
import { CustomIcon } from "../../../common/customAssets";
import { noShipments } from "../../../assets";
import {
  AddPaymentMethodAlertComponent,
  CustomText,
} from "../../../common/custom";

const NoEntitiesComponent = ({ children, text }) => {
  return (
    <div className=" p-1">
      <CustomContainer>
        {children}
        <div className=" p-[24px] mt-[32px] bg-foundational-white rounded-[20px]">
          <AddPaymentMethodAlertComponent />
          <CustomContainer className="  min-h-[400px] flex justify-center items-center  ">
            <CustomContainer className="max-w-[399px] min-h-[355px]  w-full flex flex-col items-center">
                 
              <CustomIcon icon={noShipments} className="mt-[40px] " />

              <CustomText className="text-pure-black font-[400] font-roboto text-small-para sm:text-medium-para  mt-[8px]">
                {text}
              </CustomText>
            </CustomContainer>
          </CustomContainer>
        </div>
      </CustomContainer>
    </div>
  );
};

export default NoEntitiesComponent;
