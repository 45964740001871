import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomButton,
  CustomInput,
  CustomModal,
} from '../../../../common/custom';
import CustomDropdown2 from '../../../../common/custom/custom-drop-down/index2';
import AutoCompleteComponent from '../../../components/auto-complete';
import { useAddShipFromAddress } from './useAddShipFromAddress';

const AddShipFromAddress = ({
  entity,
  selectedAddress,
  closeAddModal,
  addresses,
  setAddresses,
}) => {
  const {
    handleSubmit,
    register,
    onSubmit,
    errors,
    setValue,
    trigger,
    fieldsFromGoogle,
    handleGoogleAutoComplete,
    countries,
    setCountries,
    states = [],
    setStates,
    cities = [],
    setCities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    errCountry,
    errState,
    errCity,
  } = useAddShipFromAddress(
    entity,
    selectedAddress,
    closeAddModal,
    addresses,
    setAddresses
  );

  return (
    <CustomModal>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='container mx-auto p-4 sm:p-6 lg:p-8 bg-[#FFF] rounded-lg shadow-md'
        style={{
          maxWidth: '996px',
          boxShadow: 'rgba(0, 0, 0, 0.11) 0px 1px 9px 0px',
        }}
      >
        <h1 className='text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-foundation-brown'>
          Add/Edit Ship From Address
        </h1>
        <div className='mb-[16px]'>
          <CustomInput
            required
            placeholder='Default Location'
            label='Location Name'
            register={register}
            name='locationName'
            errors={errors}
            trigger={trigger}
            setValue={setValue}
          />
        </div>

        <div className='mb-[16px]'>
          <CustomDropdown2
            label='Country*'
            placeholder={'Select Country'}
            options={countries}
            setOptions={setCountries}
            name='country'
            error={errCountry}
            setValue={setSelectedCountry}
            value={selectedCountry}
          />
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 mb-[16px]'>
          <div>
            <CustomDropdown2
              label='State*'
              placeholder={'Select State'}
              options={states}
              setOptions={setStates}
              name='state'
              error={errState}
              setValue={setSelectedState}
              value={selectedState}
            />
          </div>

          <div>
            <CustomDropdown2
              label='City*'
              placeholder={'Select City'}
              options={cities}
              setOptions={setCities}
              name='city'
              error={errCity}
              setValue={setSelectedCity}
              value={selectedCity}
            />
          </div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 mb-[16px]'>
          <div>
            <CustomInput
              required
              placeholder='Zip Code'
              label='Zip Code'
              name='zipCode'
              register={register}
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div>
            <CustomInput
              placeholder='Unit Number'
              label='Unit No#'
              register={register}
              name='unitNumber'
              errors={errors}
              trigger={trigger}
              setValue={setValue}
            />
          </div>{' '}
        </div>
        <div className='mb-[16px]'>
          <AutoCompleteComponent
            setValue={setValue}
            trigger={trigger}
            isFormatted={true}
            register={register}
            errors={errors}
            label='Address'
            name='streetAddress'
            address={fieldsFromGoogle}
            onChange={handleGoogleAutoComplete}
            required
          />
        </div>

        <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
          <CustomButton
            onClick={closeAddModal}
            type='button'
            className='white-bg-btn'
            text='Cancel'
          />

          <CustomButton className='brown-bg-btn' text='Add' />
        </div>
      </form>
    </CustomModal>
  );
};

export default AddShipFromAddress;
