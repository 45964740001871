import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';
import { useDispatch } from 'react-redux';
import { resetReportClaimFormAction } from '../../../../store/slices/formsSlice';

function useShipmentsActionMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClaimFIle = () => {
    dispatch(resetReportClaimFormAction());
    navigate(PATHS.CLAIM_FILE);
  };

  const editHandler = (data) => {
    navigate(`${PATHS.EDIT_SHIPMENT}/${data.id}`);
  };

  const viewHandler = (data) => {
    navigate(`${PATHS.VIEW_SHIPMENT_DETAIL}/${data.id}`);
  };

  return {
    handleClaimFIle,
    editHandler,
    viewHandler,
  };
}

export default useShipmentsActionMenu;
