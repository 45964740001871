import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../../constants/toastNotifications';

export const useDesktopNavbar = () => {
  const navigate = useNavigate();
  const logout = () => {
    toast.dismiss();
    toast.success('Logged out successfully!', { duration: TOAST_TIME });
    localStorage.clear();
    navigate(PATHS.DEFAULT_LOGIN);
  };

  return { logout };
};
