import { Tooltip } from '@material-tailwind/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { approvedIcon, deleteIcon, editIcon, viewIcon } from '../../../../assets';
import { CustomText } from '../../../../common/custom';

import { CustomIcon } from '../../../../common/customAssets';
import { PATHS } from '../../../../constants/paths';

const PendingShipmentsActionMenu = ({
  openDeleteModal,
  data,
  approveShipmentHandler,
  upper,
}) => {
  const navigate = useNavigate();
  const editHandler = (data) => {
    navigate(`${PATHS.EDIT_SHIPMENT}/${data.id}`);
  };
  const viewShipmentDetailHandler = (data) => {
    navigate(`${PATHS.VIEW_SHIPMENT_DETAIL}/${data.id}`)
  }
  return (
    <div
      className={`w-[190px] z-10 shadow-md bg-foundational-white  absolute ${
        upper ? 'top-[-60px]' : 'top-[30px]'
      }  right-[30px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0] `}
    >
      <div
        onClick={() => {
          approveShipmentHandler(parseInt(data.id), data);
        }}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <div className='flex'>
          <Tooltip
            className='mt-[-10px] z-50'
            content='Edit'
          >
            <div>
              {' '}
              <CustomIcon icon={approvedIcon} />
            </div>
          </Tooltip>
          <CustomText className='font-inter font-[500] ml-[8px] text-[#228400] '>
            {/* <Link to={`${PATHS.EDIT_SHIPMENT}/${data.id}`}>Approve Shipment</Link> */}
            Approve Shipment
          </CustomText>
        </div>
      </div>

      <div
        onClick={openDeleteModal}
        className='cursor-pointer flex items-center  w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px]'
      >
        <Tooltip
          className='mt-[-10px] z-50'
          content='Delete'
        >
          <div>
            <CustomIcon icon={deleteIcon} />
          </div>
        </Tooltip>
        <CustomText className='font-inter font-[500] ml-[8px]'>
          <div className='font-inter font-[500] text-[#D92D20] flex gap-[12px]'>
            Delete
          </div>
        </CustomText>
      </div>

      <div
        onClick={() => editHandler(data)}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <Tooltip
          className='mt-[-10px] z-50'
          content='Edit'
        >
          <div>
            <CustomIcon icon={editIcon} />
          </div>
        </Tooltip>

        <CustomText className='font-inter font-[500] ml-[8px] '>
          <div>Edit</div>
        </CustomText>
      </div>
      <div
        onClick={() => viewShipmentDetailHandler(data)}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <Tooltip
          className='mt-[-10px] z-50'
          content='Edit'
        >
          <div>
            <CustomIcon icon={viewIcon} />
          </div>
        </Tooltip>

        <CustomText className='font-inter font-[500] ml-[8px] '>
          <div>View Details</div>
        </CustomText>
      </div>
    </div>
  );
};

export default PendingShipmentsActionMenu;
