import React, { useEffect, useState } from 'react';

const CustomCheckbox = ({ checkboxText, getTermsAndConditionValue }) => {
  const [value, setValue] = useState(false);
  useEffect(() => {
    getTermsAndConditionValue(value);
  }, [value]);

  const changeHandler = () => {
    setValue((prevState) => !prevState);
  };
  return (
    <label className='flex items-center gap-[8px]'>
      <input
        onChange={(e) => changeHandler(e)}
        value={value}
        type='checkbox'
        className='rounded border border-solid border-gray-600 '
      />
      <div className='text-[#464646] text-sm not-italic font-medium leading-5 font-roboto'>
        {checkboxText}
      </div>
    </label>
  );
};

export default CustomCheckbox;
