import client from './apollo.';
import {
  fetchCurrentUserQuery,
  forgetPasswordMutation,
  loginUser,
  resetPasswordMutation,
} from './services/auth.service.js';
import {
  getAllActiveClaimsQuery,
  getAllClaimsQuery,
  createManualClaimMutation,
} from './services/claim.service.js';
import { onboardingMutation } from './services/onboarding.service.js';
import {
  approveShipmentMutation,
  cancelShipmentMutation,
  changeConfirmStatusMutation,
  changePaidStatusMutation,
  getAllShipments,
  getAllShipmentsFromLocationQuery,
  getShipmentQuery,
  reportShipmentMutation,
  updateShipmentMutation,
} from './services/shipments.service.js';
import { findAllSalesMemberQuery } from './services/users.service.js';
import { findAllSalesMemberForAdminQuery } from './services/users.service.js';
import {
  getAllEntitiesQuery,
  updateEntityMutation,
} from './services/entities.service.js';
import {
  addAddressMutation,
  getMyLocationsAddressesQuery,
  removeAddressMutation,
  getMyLocationsQuery,
} from './services/address.service.js';
import { addMemberMutation } from './services/entityMembers.service.js';
import {
  updateDefaultLocation,
  getEntityLocationsQuery,
} from './services/location.service.js';
import { createSetupIntentMutation } from './services/payment.service.js';
import { getListPaymentMethodsQuery } from './services/paymentMethods.service.js';
import { getAllMembersQuery } from './services/entityMembers.service.js';
import { AllOnboardingApplicationsQuery } from './services/onboarding.service.js';
import { allShipmentFromEntityQuery } from './services/shipments.service.js';
import { getEntity } from './services/entities.service.js';
import { removeNewEntityMutation } from './services/entities.service.js';
import { removeEntityMutation } from './services/entities.service.js';
import { updateOnboardingApplicationMutation } from './services/onboarding.service.js';
import { getDashboardStatisticsQuery } from './services/dashboard.service.js';
export {
  addAddressMutation,
  addMemberMutation,
  approveShipmentMutation,
  cancelShipmentMutation,
  client,
  createSetupIntentMutation,
  findAllSalesMemberQuery,
  findAllSalesMemberForAdminQuery,
  forgetPasswordMutation,
  getAllActiveClaimsQuery,
  getAllClaimsQuery,
  createManualClaimMutation,
  getAllShipmentsFromLocationQuery,
  getListPaymentMethodsQuery,
  getMyLocationsAddressesQuery,
  getShipmentQuery,
  loginUser,
  onboardingMutation,
  removeAddressMutation,
  reportShipmentMutation,
  resetPasswordMutation,
  updateDefaultLocation,
  fetchCurrentUserQuery,
  getAllMembersQuery,
  getAllEntitiesQuery,
  AllOnboardingApplicationsQuery,
  changeConfirmStatusMutation,
  updateShipmentMutation,
  getAllShipments,
  allShipmentFromEntityQuery,
  changePaidStatusMutation,
  getMyLocationsQuery,
  getEntityLocationsQuery,
  updateEntityMutation,
  getEntity,
  removeNewEntityMutation,
  removeEntityMutation,
  updateOnboardingApplicationMutation,
  getDashboardStatisticsQuery,
};
