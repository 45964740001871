import {
  getCarriersQuery,
  addCarrierMutation,
  updateCarrierMutation,
  deleteCarrierMutation,
} from '../../api/services/admin.service';
import { useEffect, useRef, useState } from 'react';
import useApi from '../../hook/useApi';
import useOutsideClick from '../../hook/useOutsideClick';
import { useHookFormSubmission } from '../../hook/useHookFormSubmission';
import { addNewSchema } from '../../common/schema/addNewSchema';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../constants/toastNotifications';

export const useCarriers = () => {
  const [getCarriers, loading] = useApi(getCarriersQuery);
  const [data, setData] = useState();
  const [selectedItem, setSelectedItem] = useState(null);

  const actionMenuRef = useRef(null);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  let { handleSubmit, register, reset, errors, trigger, setValue } =
    useHookFormSubmission(addNewSchema);

  useEffect(() => {
    const getData = async () => {
      const _data = await getCarriers();
      setData(_data?.data?.allCarriers);
    };
    getData();
  }, []);

  const handleActionClick = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);

    toggleActionMenu
      ? (setToggleActionMenu(null), setSelectedItem(null))
      : setToggleActionMenu(item.id);
  };

  const onSubmit = async (values) => {
    try {
      toast.dismiss();
      values = { ...values, carrierId: selectedItem?.id };
      const response = selectedItem
        ? await updateCarrierMutation(values)
        : await addCarrierMutation(values);

      selectedItem
        ? (data[data.findIndex((item) => item.id === selectedItem.id)] = {
          ...data.find((item) => item.id === selectedItem.id),
          name: values.name,
          address: values?.address,
        })
        : data?.push(response?.data?.createCarrier);
      data?.sort((a, b) => b.id - a.id);

      toast.success(
        `Carrier ${selectedItem ? 'updated' : 'created'} successfully!`,
        { duration: TOAST_TIME }
      );

      handleClose();
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong!', { duration: TOAST_TIME });
    }
  };

  const deleteHandler = async () => {
    try {
      toast.dismiss();
      const response = await deleteCarrierMutation(parseInt(selectedItem?.id));
      setData((prevData) =>
        prevData.filter((item) => item.id !== selectedItem.id)
      );
      if (response?.data) toast.success('Carrier removed successfully!');
      if (response?.errors?.length)
        toast.error(
          response?.errors[0]?.message?.includes('violate')
            ? 'Cannot delete. \nItem is attached with other data!'
            : 'Something went wrong!'
        );
    } catch (err) {
      toast.error('Something went wrong!', { duration: TOAST_TIME });
    }

    handleClose();
  };

  const handleClose = () => {
    if (reset) reset();
    setSelectedItem(null);
    openAddNewModal
      ? setOpenAddNewModal(false)
      : openEditModal
        ? setOpenEditModal(false)
        : setOpenDeleteModal(false);
  };

  const editActionClick = () => {
    setOpenEditModal(true);
    setToggleActionMenu(null);
  };

  const deleteActionClick = () => {
    setOpenDeleteModal(true);
    setToggleActionMenu(null);
  };

  return {
    data,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    selectedItem,
    setOpenAddNewModal,
    onSubmit,
    handleSubmit,
    register,
    errors,
    trigger,
    setValue,
    handleClose,
    editActionClick,
    deleteActionClick,
    openDeleteModal,
    openEditModal,
    openAddNewModal,
    deleteHandler,
    loading
  };
};
