import { gql } from '@apollo/client';
import client from '../apollo.';

export const onboardingMutation = async (signUpData) => {
  const { data, errors } = await client.mutate({
    errorPolicy: 'all',
    mutation: gql`
      mutation CreateApplication(
        $createApplicationInput: CreateApplicationInput!
      ) {
        createApplication(createApplicationInput: $createApplicationInput) {
          address
          businessName
          businessTIN
          currentInsurancePolicy
          emailAddress
          industryType
          phoneNumber
          principalName
          since
          website
          createdAt
        }
      }
    `,
    variables: {
      createApplicationInput: {
        address: signUpData.streetAddress,
        businessName: signUpData.nameOfBusiness,
        currentInsurancePolicy: signUpData.currentInsurancePolicy,
        industryType: signUpData.industry,
        emailAddress: signUpData.emailAddress,
        phoneNumber: signUpData.phoneNumber,
        principalName: signUpData.nameOfPrincipal,
        since: signUpData.businessSince,
        website: signUpData.website,
        businessTIN: signUpData.tin,
        salesPersonID: parseInt(signUpData.referedBy),
      },
    },
  });

  return { data, errors };
};

export const AllOnboardingApplicationsQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query AllOnboardingApplications {
        allOnboardingApplications {
          address
          createdAt
          currentInsurancePolicy
          businessTIN
          businessName
          emailAddress
          industryType
          phoneNumber
          principalName
          since
          website
          salesPersonID
        }
      }
    `,
  });
  return { data, errors };
};

export const updateOnboardingApplicationMutation = async (updateData) => {
  const { oldBusinessName, ...rest } = updateData; // Destructure businessName from updateData
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateApplication(
        $businessName: String!
        $updateData: UpdateApplicationInput!
      ) {
        updateApplication(
          businessName: $businessName
          updateData: $updateData
        ) {
          address
          businessName
          businessTIN
          createdAt
          currentInsurancePolicy
          emailAddress
          industryType
          phoneNumber
          principalName
          since
          website
        }
      }
    `,
    variables: {
      businessName: oldBusinessName || '', // Provide a default value if businessName is not present
      updateData: {
        address: rest.streetAddress ? rest.streetAddress : '',
        businessName: rest.businessName ? rest.businessName : '',
        businessTIN: rest.tin ? rest.tin : '',
        currentInsurancePolicy: rest.currentInsurancePolicy
          ? rest.currentInsurancePolicy
          : '',
        emailAddress: rest.emailAddress ? rest.emailAddress : '',
        industryType: rest.industry ? parseInt(rest.industry) : 0,
        phoneNumber: rest.phoneNumber ? rest.phoneNumber : '',
        principalName: rest.nameOfPrincipal
          ? rest.nameOfPrincipalnameOfPrincipal
          : '',
        since: rest.since ? rest.since : '',
        website: rest.website ? rest.website : '',
        salesPersonID: rest.salesPersonID,
      },
    },
  });
  return { data, errors };
};
