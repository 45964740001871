import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import addShipFromAddressSchema from '../schema/add-ship-from-address.schema';
import useApi from '../../../../hook/useApi';
import {
  addAddressMutation,
  getMyLocationsAddressesQuery,
} from '../../../../api';
import { storeAllAddressesAction } from '../../../../store';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import {
  fetchCities,
  fetchCountries,
  fetchStates,
  updateAddressMutation,
} from '../../../../api/services/address.service';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import { formatAddress } from '../../../../helpers/helpers';

export const useAddShipFromAddress = (
  entity,
  selectedAddress,
  closeAddModal,
  addresses,
  setAddresses
) => {
  const [fieldsFromGoogle, setFieldsFromGoogle] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState('United States');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [errCountry, setErrCountry] = useState(null);
  const [errState, setErrState] = useState(null);
  const [errCity, setErrCity] = useState(null);

  const [getLocationAddresses] = useApi(
    getMyLocationsAddressesQuery,
    storeAllAddressesAction
  );
  const [updateAddress] = useApi(updateAddressMutation);

  const [addAddress, addAddressLoading, addAddressError, addResponseData] =
    useApi(addAddressMutation);

  const { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(addShipFromAddressSchema);

  useEffect(() => {
    setValue('streetAddress', fieldsFromGoogle);
  }, [fieldsFromGoogle]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _countries = await fetchCountries();

        _countries = _countries.map((item) => ({
          label: item.country,
          value: item.country,
        }));

        setCountries(_countries);
      } catch (error) {}
    };

    fetchData();

    if (selectedAddress) {
      const _address = JSON.parse(selectedAddress?.address);
      setValue('locationName', selectedAddress?.nickName?.trim());
      if (_address?.street) setValue('streetAddress', _address?.street);
      if (_address?.unitNo) setValue('unitNumber', _address?.unitNo);
      if (_address?.zipCode) setValue('zipCode', _address?.zipCode);
      if (_address?.country)
        setSelectedCountry(
          _address?.country === 'USA' || _address?.country === 'US'
            ? 'United States'
            : _address?.country
        );
      if (_address?.state) setSelectedState(_address?.state);
      if (_address?.city) setSelectedCity(_address?.city);
    }
  }, [selectedAddress]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _states = await fetchStates(selectedCountry);
        _states = _states.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setStates(_states);
        setSelectedState(null);
        setSelectedCity(null);
      } catch (error) {}
    };

    fetchData();
  }, [selectedCountry]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let _cities = await fetchCities(selectedCountry, selectedState);
        _cities = _cities.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        setCities(_cities);
      } catch (error) {}
    };

    fetchData();
    setSelectedCity();
  }, [
    useMemo(
      () => [selectedCountry, selectedState],
      [selectedCountry, selectedState]
    ),
  ]);

  const onSubmit = async (values) => {
    if (selectedCountry == '' || selectedCountry == null) {
      setErrCountry('Country is required ');
      return;
    }
    if (selectedState == '' || selectedState == null) {
      setErrState('State is required ');
      return;
    }
    if (selectedCity == '' || selectedCity == null) {
      setErrCity('City is required ');
      return;
    }
    toast.dismiss();
    toast.loading('Adding address! Please wait...');
    const address = {
      street: values.streetAddress,
      unitNo: values.unitNumber,
      zipCode: values.zipCode,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
    };
    if (entity) {
      if (selectedAddress) {
        const { data, errors } = await updateAddress({
          id: selectedAddress.id,
          locationId: entity?.location[0]?.id,
          nickName: values.locationName,
          address: JSON.stringify(address),
        });
        if (data) {
          toast.dismiss();
          toast.success('Address updated successfully', {
            duration: TOAST_TIME,
          });
          closeAddModal();
        }
        if (errors) {
          toast.error('Failed to update address', { duration: TOAST_TIME });
        }
      } else {
        const { data, errors } = await addAddress({
          locationId: parseInt(entity?.location[0]?.id),
          nickName: values.locationName,
          address: JSON.stringify(address),
        });
        if (data) {
          toast.dismiss();
          toast.success('Address added successfully', { duration: TOAST_TIME });
          closeAddModal();

          const _newAddress = {
            nickName: values.locationName,
            address: formatAddress(JSON.stringify(address)),
            formattedAddress: formatAddress(JSON.stringify(address)),
          };
          setAddresses((prevAddresses) => [...prevAddresses, _newAddress]);
        }
        if (errors) {
          toast.error('Failed to add address', { duration: TOAST_TIME });
        }
      }
    }
  };

  const handleGoogleAutoComplete = (address) => {
    setFieldsFromGoogle(address);
  };

  return {
    handleSubmit,
    register,
    onSubmit,
    errors,
    setValue,
    fieldsFromGoogle,
    handleGoogleAutoComplete,
    trigger,
    countries,
    setCountries,
    states,
    setStates,
    cities,
    setCities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    errCountry,
    errState,
    errCity,
  };
};
