import { useEffect, useState } from 'react';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import useApi from '../../../hook/useApi';
import { updateRateEntitySchema } from '../Entities/Schema/editEntitySchema';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import toast from 'react-hot-toast';
import { findAllSalesMemberQuery } from '../../../api';
import { useSelector } from 'react-redux';
import {
  storeAllEntitiesAction,
  storeSingleEntityAction,
} from '../../../store';
import {
  getAllEntitiesQuery,
  updateEntityMutation,
  getEntity,
} from '../../../api';

const useChangeEntityRate = ({
  entityId,
  closeChangeRateModal,
  modalHeading,
}) => {
  const { entities } = useSelector((state) => state);
  const [getSingleEntity] = useApi(getEntity, storeSingleEntityAction);
  const [updateEntity] = useApi(updateEntityMutation);
  const [getApprovedEntities] = useApi(
    getAllEntitiesQuery,
    storeAllEntitiesAction
  );

  const { handleSubmit, register, errors, setValue } = useHookFormSubmission(
    updateRateEntitySchema
  );

  const [salesMembers, setSalesMembers] = useState([]);
  const [selectedMember, setSelected] = useState(null);
  const [salesMemberErr, setSalesMemberErr] = useState(false);

  useEffect(() => {
    getSingleEntity(parseInt(entityId));

    const getMembers = async () => {
      const _data = await findAllSalesMemberQuery();
      // console.log(1, _data);

      const _members = _data?.data?.findAllSalesMember.map((item) => ({
        label: item.firstName + ' ' + item?.lastName,
        name: item.firstName + ' ' + item?.lastName,
        value: item.id,
        id: item.id,
      }));
      // console.log(2, _members);
      setSalesMembers(_members);
    };
    getMembers();
  }, []);

  useEffect(() => {
    if (entities) {
      setValue('premiumRate', entities.singleEntityDetail.rate);
      setValue(
        'internationalRate',
        entities.singleEntityDetail.internationalRate
      );
      setValue('salesMember', entities.singleEntityDetail.salesPersonCode?.id);
    }
  }, [entities]);

  const onSubmit = async (values) => {
    if (!modalHeading?.includes('Premium') && !values?.salesMember) {
      setSalesMemberErr('Sales Member is required');
    } else {
      // console.log(
      //   'v:',
      //   values,
      //   parseInt(entities.singleEntityDetail.id),
      //   entityId
      // );

      toast.loading(
        `Please wait! Changing the ${
          !modalHeading?.includes('Premium') ? 'sales member' : 'premium rate'
        }`
      );
      var singleData = entities.singleEntityDetail;
      const apiData = {
        id: parseInt(singleData.id),
        businessName: singleData.name,
        dba: singleData.DBA,
        busniessTin: singleData.EIN,
        address: singleData.address,
        email: singleData.emailAddress,
        rate: values.premiumRate,
        internationalRate: values.internationalRate,
        salesPersonCode: selectedMember
          ? selectedMember
          : singleData.salesPersonCode?.id,
        note: singleData.additionalNote,
        phone: singleData.phoneNumber,
        website: singleData.website,
        since: singleData.since,
        emailRebate: singleData.rebateEmail,
        since: singleData.since,
      };
      const { data, errors } = await updateEntity({ ...apiData });
      if (data) {
        toast.success(
          `${
            !modalHeading?.includes('Premium') ? 'Sales Member' : 'Premium rate'
          }
        'has been changed successfully!'`,
          {
            duration: TOAST_TIME,
          }
        );
      }
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to update', { duration: TOAST_TIME });
    }
    closeChangeRateModal();
    getApprovedEntities();
  };

  return {
    handleSubmit,
    register,
    onSubmit,
    setValue,
    errors,
    salesMembers,
    salesMemberErr,
    setSalesMemberErr,
    salesPerson: selectedMember
      ? selectedMember
      : entities.singleEntityDetail.salesPersonCode?.id,
    setSelected,
  };
};

export default useChangeEntityRate;
