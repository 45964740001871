import React from "react";
import CustomContainer from "../../../common/custom/CustomContainer";
import { ClientsPanelHeader, CustomModal } from "../../../common/custom";
import UsePaymentEntity from "./usePaymentEntity";
import { CardInformationHeader } from "../../../constants/columns";
import NoEntitiesComponent from "../noEntityComponent";
import CustomTable from "../../../common/custom/CustomTable/custom-table.component";
import PaymentActionMenu from "./paymentLocationActionMenu";
import { CustomIcon } from "../../../common/customAssets";
import { arrowLeft } from "../../../assets";
import StripeCardDetailsForm from "../../stripForm/stripeForm";
export default function Entity_Card_View() {
  const {
    cardInformation,
    handleCardActionClick,
    actionCardMenuRef,
    toggleCardActionMenu,
    onClickBack,
    openStripeForm,
    getSetupIntent,
    id,
    isCardPage,
    paymentIntent
  } = UsePaymentEntity();

  return (
    <>
      <CustomContainer>
        <ClientsPanelHeader />
        <div className="stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]">
          <div className="flex">
            <CustomIcon
              icon={arrowLeft}
              className="p-2 cursor-pointer"
              clickHandler={onClickBack}
            />
            <h1 className="text-[26px] font-semibold mb-8 text-foundation-brown font-inter">
              Card Infomation
            </h1>
          </div>
          {cardInformation && cardInformation.length > 0 ? (
            <CustomTable
              table="/entity-payment"
              header={CardInformationHeader}
              rows={cardInformation}
              handleActionClick={handleCardActionClick}
              actionMenuRef={actionCardMenuRef}
              toggleActionMenu={toggleCardActionMenu}
              menu={<PaymentActionMenu setupCall={getSetupIntent} id={id} isCardPage={isCardPage} />}
            />
          ) : (
            <NoEntitiesComponent text={"No Card Information Found"} />
          )}
        </div>
      </CustomContainer>
      {
        openStripeForm ?
          <CustomModal>
            <div className="max-w-[600px] bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center px-4">
              <StripeCardDetailsForm setupIntent={paymentIntent} />
            </div>
          </CustomModal>
          : null
      }
    </>
  );
}
