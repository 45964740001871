import React from 'react';

const CustomContainer = ({
  // will receive className props  in which tailwind css classes will be passed.
  className = 'max-w-[1300px] w-full mx-auto  px-1 ', // if no className props are passed than these classes will aplied as default
  // Note (this custom container has application container max-width, can be used as as application container if no class prop is passed ) .

  // receive content as children
  children,
  onClick,
}) => {
  // Render the dynamic content with dynamic styles based on props
  return <div className={className} onClick={onClick}>{children}</div>;
};

export default CustomContainer;

// Example usage:
// example 1 for using as application container for max-width as 1200px mention in figma , here we dont pass any className prop
// <CustomContainer >
//  <nav>content</nav>
// </CustomContainer>

// example 2 for using as a custom container for desired styling
// <CustomContainer
// className='max-w-[600px] mx-auto bg-red-100' >
// <h1>Hello World</h1>
// </CustomContainer>
