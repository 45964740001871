import React from 'react';
import FormImageLayout from '../component/FormImageLayout';
import { CustomButton, CustomInput, CustomText } from '../../../common/custom';
import { useForgetPassword } from './useForgetPassword';

import { PATHS } from '../../../constants/paths';

import CustomHeading from '../../../common/custom/CustomHeading';

import { backIcon } from '../../../assets';
import BackButton from '../../../common/custom/BackButton';

const ForgetPassword = () => {
  const { handleSubmit, onSubmit, register, errors, forgetPasswordLoading } =
    useForgetPassword();
  return (
    <FormImageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomHeading as='h1'>Trouble logging in?</CustomHeading>
        <CustomText
          as='p'
          className='text-para-black   font-[500] font-roboto text-small-para md:text-medium-para text-center mt-[12px] mb-[28px]'
        >
          Please add the info you gave us when you created your account.
        </CustomText>
        <div className='mb-[30px]'>
          <CustomInput
            name='email'
            register={register}
            placeholder='Enter your email'
            label='Email'
            errors={errors}
          />
        </div>

        <CustomButton
          disabled={forgetPasswordLoading ? true : false}
          text='Send Verification Code'
        />

        <div className=' flex justify-center mt-[32px]'>
          <BackButton link={PATHS.DEFAULT_LOGIN} icon={backIcon} />
        </div>
      </form>
    </FormImageLayout>
  );
};

export default ForgetPassword;
