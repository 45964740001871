import {React } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { PaymentElement } from "@stripe/react-stripe-js";
import { CustomButton } from "../../common/custom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import { TOAST_TIME } from "../../constants/toastNotifications";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PK);

const StripeCardDetailsForm = ({ setupIntent }) => {
  const options = {
    clientSecret: setupIntent?.client_secret,
    appearance: {},
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm setupIntent={setupIntent} />
    </Elements>
  );
};

export default StripeCardDetailsForm;

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }
toast.dismiss();
    await stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url:window.location.origin
        },
      })
      .then(() => {
        toast.success("Card Added", {
          duration: TOAST_TIME,
        });
      })
      .catch((err) => {
        toast.success(err, {
          duration: TOAST_TIME,
        });


      });
  };

  return (
    <form className="h-[600px]" onSubmit={handleSubmit}>
      <div className="flex my-5 items-center justify-center">
        <h2 className="text-xl flex items-center gap-5 font-semibold h-[24px]">
          Add Card Information
        </h2>
      </div>
      <PaymentElement className="h-[400px] w-[100%] overflow-y-scroll" />
      <div className="flex mt-5 items-center justify-center">
        <CustomButton
          className="bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] "
          text="Save Card"
        />
      </div>
    </form>
  );
};
