import { Entities } from "../../features/entities";
import React from "react";

const EntitiesPage = () => {
  return (
    <>
      <Entities />
    </>
  );
};

export default EntitiesPage;
