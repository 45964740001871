import React from 'react';
import {
  CustomButton,
  CustomDropdown,
  CustomInput,
  CustomText,
} from '../../../../common/custom';
import { useReportShipmentStepTwo } from './useShipmentStepTwo';
import ReportShipmentCofirmation from '../../../modals/reportShipmentAmoutConfirmation';
import { FILE_TYPE, PREMIUM, REBATE } from '../../../../helpers/helpers';
import FilePreview from './filePreview/report-file-preview';
import FileUpload from '../../../../common/custom/file-upload/file-upload.component';
import { informationIcon, uploadFileIcon } from '../../../../assets';
import { Tooltip } from '@mui/material';
import { CustomIcon } from '../../../../common/customAssets';

const ReportShipmentStepTwo = ({
  increaseStepHandler,
  decreaseStepHandler,
  isTrial,
}) => {
  const {
    rate,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    watchedMerchandiseType,
    declaredValueModalClose,
    closeDeclaredValueModal,
    declaredValueModal,
    declaredValue,
    handleDeclaredValue,
    handleUploadAttachmentsCancel,
    handleUploadAttachments,
    trigger,
    attachments,
    isLoading,
    merchandiseTypeOptions,
    handleToggleClick,
    isTrialReported,
  } = useReportShipmentStepTwo(increaseStepHandler, isTrial);

  const merchandiseTypePreValue = merchandiseTypeOptions.find(
    (option) => option.id === watchedMerchandiseType
  );
  return (
    <>
      {declaredValueModal && !declaredValueModalClose ? (
        <ReportShipmentCofirmation
          closeDeclaredValueModal={closeDeclaredValueModal}
          modalHeading='High-Value Item Declaration'
          modalPara='Are there any items in this shipment valued over $50,000?'
        />
      ) : null}

      <div className='stepper-cards container mx-auto p-4 sm:p-6 md:p-8 lg:p-10 bg-white border border-gray-300 rounded-lg shadow-sm w-full sm:w-[450px] md:w-[600px] lg:w-[895px]'>
        <h1 className='text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-foundation-brown'>
          Report Shipment
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isTrial && (
            <div className='flex justify-end items-center gap-2'>
              <label htmlFor='isTrial' className='font-bold flex'>
                Report this shipment as trial
              </label>
              <Tooltip
                className='mt-[-10px] z-50 cursor-pointer'
                title='This is first shipment of selected entity, and it will be considered as
                  trial i.e. no premium will be charged for this first shipment
                  upto total value of $5000.'
              >
                <div className='relative'>
                  <CustomIcon icon={informationIcon} className='w-5' />
                </div>
              </Tooltip>

              <button
                type='button'
                onClick={handleToggleClick}
                className={`${
                  isTrialReported ? 'bg-[#77553d]' : 'bg-gray-300'
                } w-14 h-8 rounded-full p-1 transition-colors duration-300 ease-in-out`}
              >
                <div
                  className={`${
                    isTrialReported ? 'translate-x-6' : 'translate-x-1'
                  } transform bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out`}
                ></div>
              </button>
            </div>
          )}
          <div className='mb-4'>
            <CustomInput
              name='declaredValue'
              register={register}
              placeholder='Declared value (IN USD)'
              type='number'
              label='Declared Value'
              required
              asterikColor='#E31836'
              errors={errors}
              value={declaredValue}
              trigger={trigger}
              setValue={setValue}
              onChange={(e) => handleDeclaredValue(e.target.value)}
            />

            <p
              className={`text-xs sm:text-sm text-gray-600 ${
                errors.declaredValue ? 'mt-6' : 'mt-[6px]'
              } `}
            >
              <span className='text-[#2F950B] font-inter text-[14px] font-[500] items-center'>
                Premium:{' '}
                <span className='text-[#2F950B] text-sm italic font-semibold leading-5'>
                  {isTrialReported && declaredValue <= 5000
                    ? 0
                    : PREMIUM(
                        isTrialReported ? declaredValue - 5000 : declaredValue,
                        rate
                      )}
                </span>
              </span>
              <span className='px-1 text-[#E59111] text-sm italic font-medium leading-5'>
                -
              </span>
              <span className='text-[#E59111] font-inter text-[14px] font-[500]'>
                Rebate:{' '}
                <span className='text-[#E59111] text-sm italic font-semibold leading-5'>
                  {isTrialReported && declaredValue <= 5000
                    ? 0
                    : REBATE(
                        isTrialReported ? declaredValue - 5000 : declaredValue,
                        rate
                      )}
                  {/* {REBATE(declaredValue, rate)} */}
                </span>
              </span>
            </p>
          </div>
          <div>
            <div className='mb-[6px]'>
              <CustomText>Merchandise Type</CustomText>
            </div>

            <CustomDropdown
              value={merchandiseTypePreValue?.name}
              placeholder='Select merchandise type'
              options={merchandiseTypeOptions}
              setValue={setValue}
              name='merchandiseType'
              errors={errors}
            />
          </div>

          <div className='mt-[20px]'>
            <CustomText>Attachments</CustomText>
          </div>
          <div className='flex justify-center mt-[20px]'>
            <div className='flex gap-[6px] w-full flex-col'>
              {attachments && attachments.length > 0 ? (
                <FilePreview
                  files={attachments}
                  isLoading={isLoading}
                  handleCancel={handleUploadAttachmentsCancel}
                />
              ) : null}

              <FileUpload
                handleChange={handleUploadAttachments}
                fileTypes={FILE_TYPE}
                multiple={true}
              />
            </div>
          </div>
          <div className='w-full mt-[20px]'>
            <CustomInput
              register={register}
              name='additionalNote'
              placeholder='Shipment / Attachment Notes here...'
              type='text'
              errors={errors}
              label='Shipment / Attachment Notes'
              // asterikColor='#E31836'
              trigger={trigger}
              setValue={setValue}
            />
          </div>
          <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
            <CustomButton
              type='button'
              onClick={decreaseStepHandler}
              className='white-bg-btn'
              variant='outline'
              text='Previous'
            />

            <CustomButton className='brown-bg-btn' text='Next' />
          </div>
        </form>
      </div>
    </>
  );
};

export default ReportShipmentStepTwo;
