import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CustomIcon } from '../../../common/customAssets';
import { menuListData } from './menuListData';
import { useDispatch } from 'react-redux';
import { setPage } from '../../../store/slices/paginationSlice';
import { Menu, MenuItem, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { chevronDownWhite } from '../../../assets';

const MenuList = ({
  className = 'flex  w-full min-w-[508px] justify-center items-center gap-[8px]  ',
  closeHamburgerMenu,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('login-user'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDropdown, setOpenDropdown] = React.useState(null);

  const handleDropdownClick = (event, menuName) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdown(openDropdown === menuName ? null : menuName);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setOpenDropdown(null);
  };

  const updatedMenuListData =
    user?.type !== 'ADMIN' ? menuListData?.slice(1) : menuListData;

  const handleLinkClicked = (name) => {
    dispatch(setPage({ tableId: name, page: 1 }));
    handleDropdownClose();
  }
  const isMenuActive = (menu, children) => {
    if (children) {
      return children.some(child => location.pathname.startsWith(child.link));
    }
    return location.pathname.startsWith(menu.link);
  };
  return (
    <ul className={className}>
      {menuListData.map((menu) => {
        const isActive = isMenuActive(menu, menu.children);
        return menu?.user === user?.type || !menu?.user ? (
          <li
            onClick={closeHamburgerMenu}
            className={` text-pure-white text-small-para rounded-[6px] text-[14px]  cursor-pointer px-[12px] py-[8px] ${isActive ? 'bg-[#8E725E]' : ''}`}
            key={menu.menuName}
          >
            {menu.children ? (
              <>
                <ListItem sx={{ p: 0 }} onClick={(e) => handleDropdownClick(e, menu.menuName)}                >
                  {/* <ListItemIcon>
                    <CustomIcon icon={menu.icon} />
                  </ListItemIcon> */}
                  <ListItemText sx={{ m: 0, '& span': { fontSize: '14px' } }} primary={menu.menuName} />
                  <CustomIcon icon={chevronDownWhite} />
                </ListItem>
                <Menu
                  anchorEl={anchorEl}
                  open={openDropdown === menu.menuName}
                  onClose={handleDropdownClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      marginTop: '1rem',
                      backgroundColor: 'white',
                      color: '#8E725E',
                      borderRadius: '6px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    }
                  }}
                >
                  {menu.children.map((child) => (
                    <MenuItem
                      key={child.menuName}
                      onClick={() => handleLinkClicked(child.link)}
                      selected={location.pathname.startsWith(child.link)}
                      sx={{
                        backgroundColor: location.pathname.startsWith(child.link) ? '#8E725E' : 'inherit',
                        color: location.pathname.startsWith(child.link) ? 'white' : '#8E725E',
                        '&.Mui-selected': {
                          backgroundColor: '#8E725E',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#8E725E',
                            color: 'white',
                          }
                        },
                        '&:hover': {
                          backgroundColor: '#8E725E',
                          color: 'white',
                        }
                      }}
                    >
                      <Link to={child.link} className="flex items-center gap-[6px] w-full" style={{ fontSize: '14px' }}>
                        {location.pathname.startsWith(child.link) &&
                          <CustomIcon icon={child.icon} />
                        }
                        {child.menuName}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) :
              <Link to={menu.link} onClick={() => handleLinkClicked(menu.link)} className={`flex items-center gap-[6px]`}>
                {isActive && <CustomIcon icon={menu.icon} />}
                {menu.menuName}
              </Link>
            }
          </li>
        ) : (
          !menu?.user && (
            <li
              onClick={closeHamburgerMenu}
              className={` text-pure-white text-small-para rounded-[6px] text-[14px]  cursor-pointer px-[12px] py-[8px]  ${location.pathname.startsWith(menu.link) ? 'bg-[#8E725E]' : ''
                } `}
              key={menu.menuName}
            >
              {menu.children ? (
                <>
                  <ListItem sx={{ p: 0 }} onClick={(e) => handleDropdownClick(e, menu.menuName)}                >
                    {/* <ListItemIcon>
                    <CustomIcon icon={menu.icon} />
                  </ListItemIcon> */}
                    <ListItemText sx={{ m: 0, '& span': { fontSize: '14px' } }} primary={menu.menuName} />
                    <CustomIcon icon={chevronDownWhite} />
                  </ListItem>
                  <Menu
                    anchorEl={anchorEl}
                    open={openDropdown === menu.menuName}
                    onClose={handleDropdownClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{
                      '& .MuiPaper-root': {
                        marginTop: '1rem',
                        backgroundColor: 'white',
                        color: '#8E725E',
                        borderRadius: '6px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      }
                    }}
                  >
                    {menu.children.map((child) => (
                      <MenuItem
                        key={child.menuName}
                        onClick={() => handleLinkClicked(child.link)}
                        selected={location.pathname.startsWith(child.link)}
                        sx={{
                          backgroundColor: location.pathname.startsWith(child.link) ? '#8E725E' : 'inherit',
                          color: location.pathname.startsWith(child.link) ? 'white' : '#8E725E',
                          '&.Mui-selected': {
                            backgroundColor: '#8E725E',
                            color: 'white',
                            '&:hover': {
                              backgroundColor: '#8E725E',
                              color: 'white',
                            }
                          },
                          '&:hover': {
                            backgroundColor: '#8E725E',
                            color: 'white',
                          }
                        }}
                      >
                        <Link to={child.link} className="flex items-center gap-[6px] w-full" style={{ fontSize: '14px' }}>
                          {location.pathname.startsWith(child.link) &&
                            <CustomIcon icon={child.icon} />
                          }
                          {child.menuName}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Link to={menu.link} onClick={() => handleLinkClicked(menu.link)} className={`flex items-center gap-[6px]`}>
                  {isActive && <CustomIcon icon={menu.icon} />}
                  {menu.menuName}
                </Link>
              )}
            </li>
          )
        );
      })}
    </ul >
  );
};

export default MenuList;