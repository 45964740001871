import { combineReducers } from '@reduxjs/toolkit';
import { uiSliceReducer } from './ui-slice';
import { formsReducer } from './formsSlice';
import { shipmentsReducer } from './shipmentsSlice';
import { addressesReducer } from './addressesSlice';
import { staffReducer } from './staffSlice';
import { claimReducer } from './claimSlices';
import { paymentsReducer } from './payments';
import { entitiesReducer } from './entitiesSlice';
import { onBoardingReducer } from './onBoardingSlice';
import { billingReducer } from './billingSlice';
import { loginUserReducer } from './loginUserSlice';
import paginationReducer from './paginationSlice'

export const rootReducer = combineReducers({
  ui: uiSliceReducer,
  forms: formsReducer,
  shipments: shipmentsReducer,
  addresses: addressesReducer,
  staff: staffReducer,
  claim: claimReducer,
  payments: paymentsReducer,
  entities: entitiesReducer,
  onBoarding: onBoardingReducer,
  billing: billingReducer,
  loginUser: loginUserReducer,
  //
  pagination: paginationReducer
});
