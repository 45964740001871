import { useNavigate } from 'react-router-dom';

import { signUpFinalStepSchema } from '../../schema/sign-up-final-step';
import { PATHS } from '../../../../constants/paths';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import { findAllSalesMemberQuery, onboardingMutation } from '../../../../api';
import useApi from '../../../../hook/useApi';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../../constants/toastNotifications';

export const useSignUpFinalStep = () => {
  const [tersmAndCondition, setTermsAndCondition] = useState(false);

  const [signUpApplication, signUpApplicationLoading] =
    useApi(onboardingMutation);

  const { signUp } = useSelector((state) => state.forms);

  const [
    fetchSalesMember,
    data,
  ] = useApi(findAllSalesMemberQuery);

  const navigate = useNavigate();
  const { handleSubmit, register, errors, setValue } = useHookFormSubmission(
    signUpFinalStepSchema
  );

  useEffect(() => {
    setValue(
      'businessSince',
      signUp.formData.step3?.businessSince
        ? signUp.formData.step3.businessSince
        : ''
    );

    setValue(
      'currentInsurancePolicy',
      signUp.formData.step3?.currentInsurancePolicy
        ? signUp.formData.step3.currentInsurancePolicy
        : ''
    );
  }, []);

  useEffect(() => {
    fetchSalesMember();
  }, []);

  const onSubmit = async (values) => {
    if (!tersmAndCondition) {
      toast.dismiss();
      return toast.error('Please accept terms and conditions', {
        duration: TOAST_TIME,
      });
    }
    toast.dismiss();
    toast.loading('Signing up... Please wait');
    const { data, errors } = await signUpApplication({
      ...signUp.formData.step1,
      ...signUp.formData.step2,
      ...values,
    });

    if (data) {
      // dispatch(resetSignUpAction());
      toast.dismiss();
      toast.success('Application submitted successfully', {
        duration: TOAST_TIME,
      });
      navigate(PATHS.THANK_YOU_PAGE);
    } else if (errors) {
      toast.error('Oops! Something went wrong. Please try again later', {
        duration: TOAST_TIME,
      });
    }
  };

  const getTermsAndConditionValue = (value) => {
    setTermsAndCondition(value);
  };
  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    salesPersons: data?.findAllSalesMember,
    setValue,
    signUpApplicationLoading,
    getTermsAndConditionValue,
  };
};
