import React from 'react';
import { BILLING } from '../../../constants/columns';
import CustomContainer from '../../../common/custom/CustomContainer';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomEmptyDataComponent,
  CustomHeading,
} from '../../../common/custom';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { useAllBilling } from './useAllBilling';
import { crossCircle, filterIcon } from '../../../assets';
import FilterBillingModal from '../filterBilling';
import DownloadCSVButton from '../../../common/custom/download-csv';
import DownloadExcelButton from '../../../common/custom/download-xlsx';
import CircularILoader from '../../../common/custom/circularLoader';

const AllBilling = () => {
  const {
    data,
    showFilterModal,
    handleOpenFilterModal,
    handleCloseFilterModal,
    handleFilterSubmit,
    handleFilterReset,
    handleSubmit,
    register,
    errors,
    setValue,
    entityOptions,
    isFilterCleared,
    setIsFilterCleared,
    filteredTotalValue,
    filteredTotalPremium,
    filteredTotalRebate,
    loading,
  } = useAllBilling();

  return (
    <CustomContainer>
      {showFilterModal && (
        <FilterBillingModal
          closeFilterModal={handleCloseFilterModal}
          onReset={handleFilterReset}
          errors={errors}
          entitiesData={entityOptions}
          register={register}
          setValue={setValue}
          handleSubmit={handleSubmit}
          onSubmit={handleFilterSubmit}
        />
      )}
      <ClientsPanelHeader />
      {loading ? (
        <div class='flex justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
          <CircularILoader />
        </div>
      ) : (
        <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
          <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
            <div className='flex justify-between items-center flex-row w-full'>
              <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
                Billings
              </CustomHeading>
              <div className='flex justify-between gap-4 w-[28%]'>
                {/* {data?.length > 0 && (
                  <> */}
                <DownloadExcelButton data={data} />
                <DownloadCSVButton data={data} />
                {/* </>
                )} */}
                {
                  // data?.length > 0 &&
                  isFilterCleared ? (
                    <CustomButton
                      onClick={handleOpenFilterModal}
                      className='px-[10px] py-[16px] text-[14px] min-w-[84px] font-bold font-[inter] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                      text='Filter'
                      icon={filterIcon}
                    />
                  ) : (
                    <CustomButton
                      onClick={handleFilterReset}
                      // onClick={() => setIsFilterCleared(true)}
                      className='px-[10px] py-[16px] text-[14px] font-bold font-[inter] min-w-[140px] text-[#101828] flex items-center h-[48px] gap-2 text-[#344054] border-2 border-[#D0D5DD] rounded-[7px]'
                      text='Clear Filters'
                      icon={crossCircle}
                    />
                  )
                }
              </div>
            </div>
          </CustomContainer>
          <div className='overflow-x-auto'>
            {data?.length > 0 ? (
              <CustomTable
                header={BILLING}
                rows={data}
                table='billing'
                noPaginate={!isFilterCleared}
              />
            ) : (
              <CustomEmptyDataComponent heading='No Data Found!'></CustomEmptyDataComponent>
            )}
          </div>

          <div className='overflow-x-auto'>
            {!isFilterCleared && data?.length && (
              <div className='flex justify-end mr-24 font-bold'>
                <div className='flex justify-center w-40 px-2 py-4'>Total:</div>
                <div className='flex justify-center w-48 px-2 py-4'>
                  {filteredTotalValue}
                </div>
                <div className='flex justify-center w-40 px-2 py-4'>
                  {filteredTotalPremium}
                </div>
                <div className='flex justify-center w-32 px-2 py-4'>
                  {filteredTotalRebate}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </CustomContainer>
  );
};

export default AllBilling;
