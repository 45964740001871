import {
  closeIcon,
  commissionIcon,
  editIcon,
  plusBrown,
  viewIcon,
} from '../../../assets';
import {
  CustomButton,
  CustomHeading,
  CustomInput,
  CustomModal,
  CustomText,
  ValidationErrorText,
} from '../../../common/custom';
import { CustomIcon, CustomImage } from '../../../common/customAssets';
import { useEffect, useState } from 'react';
import useApi from '../../../hook/useApi';
import { getCommissionDetailsQuery } from '../../../api/services/users.service';
import { FORMATE_AMOUNT } from '../../../helpers/helpers';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { SALES_MEMBERS_COMMISSION } from '../../../constants/columns';

const CommissionDetails = ({ id }) => {
  const [getCommissionDetails] = useApi(getCommissionDetailsQuery);

  const [totalCommission, setTotalCommission] = useState(0);
  const [allShipments, setAllShipments] = useState([]);
  const [filteredShipments, setFilteredShipments] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await getCommissionDetails(id);
      setAllShipments(response.data?.getCommissionDetails);

      let _totalPremium = 0;
      response.data?.getCommissionDetails.map(
        (e) => (_totalPremium += e?.premium)
      );

      setTotalCommission(
        FORMATE_AMOUNT((20 / 100) * (_totalPremium / 1.03)?.toFixed() ?? 0)
      );

      const filteredData = response.data?.getCommissionDetails.map(
        (item, idx) => ({
          id: idx + 1,
          premium: item.premium,
          commission: FORMATE_AMOUNT(
            (20 / 100) * (item.premium / 1.03)?.toFixed() ?? 0
          ),
          date: new Date(item.createdAt).toLocaleDateString(),
        })
      );

      setFilteredShipments(filteredData);
    };
    getData();
  }, [id]);

  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
        <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
          <div className='flex justify-between items-center flex-row w-full'>
            <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
              Sales Members Commission
            </CustomHeading>
          </div>
        </CustomContainer>

        <div className='mt-4 gap-4 flex justify-center'>
          Total Commission: <span className='font-bold'>{totalCommission}</span>
        </div>
        <div className='overflow-x-auto'>
          {data?.length ? (
            <CustomTable
              table='/sales-members-commission'
              header={SALES_MEMBERS_COMMISSION}
              rows={filteredShipments}
            />
          ) : (
            <CustomEmptyDataComponent heading='No Data Found!'></CustomEmptyDataComponent>
          )}
        </div>
      </div>
    </CustomContainer>
  );
};
export default CommissionDetails;

// <CustomModal
//   onClose={onClose}
//   className='max-w-[1219px]  p-8 overflow-y-auto bg-slate-700'
// >
//   <div className='!pt-4 mt-[28.5px] pb-6 min-w-[680px] max-h-[560px] p-3 shadow-[0px_0px_10px_0px_rgba(0,0,0,0.14)] rounded-xl bg-[#fff]'>
//     <div className='flex justify-between'>
//       <div className='flex justify-between items-center gap-3'>
//         <CustomIcon
//           icon={commissionIcon}
//           alt='Commission'
//           className='w-[16px] h-[16px]'
//         />
//         <div className='text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]'>
//           {title}
//         </div>
//       </div>
//       <div onClick={onClose} className='hover:cursor-pointer'>
//         <CustomImage
//           img={closeIcon}
//           alt='close'
//           className='w-[30px] h-[30px]'
//         />
//       </div>
//     </div>
//     <div className='flex flex-col gap-1'>
//       <div className='mt-4 gap-4 flex justify-center'>
//         Total Commission: {totalCommission}{' '}
//       </div>
//       <div className='min-h-[400px] my-4'>
//         <div className='gap-4 font-bold text-[#77553D]'>Shipments</div>
//         {filteredShipments?.length > 0 ? (
//           <div className='overflow-y-auto'>
//             <CustomTable
//               table={'Sale Member Shipments'}
//               rows={filteredShipments}
//               header={SALES_MEMBERS_COMMISSION}
//             />
//           </div>
//         ) : (
//           <div className='font-bold flex justify-center'>No Data Found</div>
//         )}
//       </div>
//     </div>
//   </div>
// </CustomModal>
<></>;
