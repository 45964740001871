import { useEffect, useRef, useState } from 'react';
import useApi from '../../hook/useApi';
import useOutsideClick from '../../hook/useOutsideClick';
import { useHookFormSubmission } from '../../hook/useHookFormSubmission';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../constants/toastNotifications';
import { addNewSaleMemberSchema } from '../../common/schema/addNewSaleMemberSchema';
import {
  addSalesMemberMutation,
  changeSalesMemberPasswordMutation,
  deleteSalesMemberMutation,
  findAllSalesMemberForAdminQuery,
  getCommissionDetailsQuery,
  updateSalesMemberMutation,
} from '../../api/services/users.service';
import { FORMATE_AMOUNT, generateYearsArray } from '../../helpers/helpers';
import { monthlyFilter } from '../../constants/monthly-filters';
import moment from 'moment';

export const useSalesMembers = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const [fetchSalesMember, loading] = useApi(findAllSalesMemberForAdminQuery);

  const [data, setData] = useState();

  const actionMenuRef = useRef(null);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [passErr, setPassErr] = useState(false);

  const [openCommissionModal, setOpenCommissionModal] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateErr, setDateErr] = useState('');

  let { handleSubmit, register, reset, errors, trigger, setValue } =
    useHookFormSubmission(addNewSaleMemberSchema);

  const [getCommissionDetails] = useApi(getCommissionDetailsQuery);

  const [totalCommission, setTotalCommission] = useState(0);
  const [allShipments, setAllShipments] = useState([]);
  const [filteredShipments, setFilteredShipments] = useState([]);

  useEffect(() => {
    if (selectedItem) {
      const getData = async () => {
        const response = await getCommissionDetails(selectedItem?.id);
        setAllShipments(response.data?.getCommissionDetails);

        let _totalPremium = 0;
        response.data?.getCommissionDetails.map(
          (e) => (_totalPremium += e?.premium)
        );

        setTotalCommission(
          FORMATE_AMOUNT((20 / 100) * (_totalPremium / 1.03)?.toFixed() ?? 0)
        );

        const filteredData = response.data?.getCommissionDetails.map(
          (item, idx) => ({
            id: idx + 1,
            premium: item.premium,
            commission: FORMATE_AMOUNT(
              (20 / 100) * (item.premium / 1.03)?.toFixed() ?? 0
            ),
            date: new Date(item.createdAt).toLocaleDateString(),
          })
        );

        setFilteredShipments(filteredData);
      };
      getData();
    }
  }, [selectedItem?.id]);

  useEffect(() => {
    const getData = async () => {
      const response = await fetchSalesMember();
      setData(response?.data?.findAllSalesMemberForAdmin);
    };
    getData();
  }, []);

  const handleActionClick = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);

    toggleActionMenu
      ? (setToggleActionMenu(null), setSelectedItem(null))
      : setToggleActionMenu(item.id);
  };

  const onSubmit = async (values) => {
    try {
      toast.dismiss();
      values = { ...values, userId: selectedItem?.id };
      const response = selectedItem
        ? await updateSalesMemberMutation(values)
        : await addSalesMemberMutation(values);

      selectedItem
        ? (data[data.findIndex((item) => item.id === selectedItem.id)] = {
            ...data.find((item) => item.id === selectedItem.id),
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.email,
          })
        : data?.push(response?.data?.createUser);
      data?.sort((a, b) => b.id - a.id);

      toast.success(
        `Sales Member ${selectedItem ? 'updated' : 'added'} successfully!`,
        { duration: TOAST_TIME }
      );

      handleClose();
    } catch (err) {
      toast.error('Something went wrong!', { duration: TOAST_TIME });
    }
  };

  const onChangePassSubmit = async () => {
    // console.log('changePass', pass, confirmPass);
    if (pass?.length < 8) {
      setPassErr('Password must be 8 characters long');
      setOpenChangePasswordModal(true);
      return;
    }
    if (pass != confirmPass) {
      setPassErr('Confirm Password does not match');
      setOpenChangePasswordModal(true);
      return;
    }
    try {
      toast.dismiss();
      const values = { password: pass, userId: selectedItem?.id };
      await changeSalesMemberPasswordMutation(values);
      toast.success(`Password changed successfully!`, { duration: TOAST_TIME });

      handleClose();
    } catch (err) {
      toast.error('Something went wrong!', { duration: TOAST_TIME });
    }
  };

  const deleteHandler = async () => {
    try {
      toast.dismiss();
      const response = await deleteSalesMemberMutation(
        parseInt(selectedItem?.id)
      );
      setData((prevData) =>
        prevData.filter((item) => item.id !== selectedItem.id)
      );
      if (response?.data) toast.success('Sales Member removed successfully!');
      if (response?.errors?.length)
        toast.error(
          response?.errors[0]?.message?.includes('violate')
            ? 'Cannot delete. \nItem is attached with other data!'
            : 'Something went wrong!'
        );
    } catch (err) {
      toast.error('Something went wrong!', { duration: TOAST_TIME });
    }

    handleClose();
  };

  const handleClose = () => {
    if (reset) reset();
    setSelectedItem(null);
    openAddNewModal
      ? setOpenAddNewModal(false)
      : openEditModal
      ? setOpenEditModal(false)
      : openCommissionModal
      ? setOpenCommissionModal(false)
      : openViewModal
      ? setOpenViewModal(false)
      : openChangePasswordModal
      ? setOpenChangePasswordModal(false)
      : setOpenDeleteModal(false);
    setPass('');
    setConfirmPass('');
    setPassErr(false);
  };

  const editActionClick = () => {
    setOpenEditModal(true);
    setToggleActionMenu(null);
  };

  const commissionActionClick = () => {
    setOpenCommissionModal(true);
    setToggleActionMenu(null);
  };

  const changePassActionClick = () => {
    setOpenChangePasswordModal(true);
    setToggleActionMenu(null);
  };

  const viewActionClick = () => {
    setOpenViewModal(true);
    setToggleActionMenu(null);
  };

  const deleteActionClick = () => {
    setOpenDeleteModal(true);
    setToggleActionMenu(null);
  };

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    setStartDate(date);

    if (endDate && moment(date).isAfter(moment(endDate))) {
      setDateErr('The "From" date cannot be greater than the "To" date.');
      return;
    }

    filterData(date, endDate);
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setEndDate(date);

    if (startDate && moment(date).isBefore(moment(startDate))) {
      setDateErr('The "To" date cannot be earlier than the "From" date.');
      return;
    }

    filterData(startDate, date);
  };

  const filterData = (start, end) => {
    const filtered = allShipments.filter((item) => {
      const createdAt = moment(item.createdAt);
      const isAfterStart = start
        ? createdAt.isSameOrAfter(moment(start))
        : true;
      const isBeforeEnd = end ? createdAt.isSameOrBefore(moment(end)) : true;
      return isAfterStart && isBeforeEnd;
    });

    let _totalPremium = 0;
    filtered.map((e) => (_totalPremium += e?.premium));

    setTotalCommission(
      FORMATE_AMOUNT((20 / 100) * (_totalPremium / 1.03)?.toFixed() ?? 0)
    );

    const formattedData = filtered.map((item, idx) => ({
      id: idx + 1,
      premium: item.premium,
      commission: FORMATE_AMOUNT(
        (20 / 100) * (item.premium / 1.03)?.toFixed() ?? 0
      ),
      date: new Date(item.createdAt).toLocaleDateString(),
    }));

    setFilteredShipments(formattedData);
  };

  return {
    data: data,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    selectedItem,
    setOpenAddNewModal,
    onSubmit,
    onChangePassSubmit,
    handleSubmit,
    register,
    errors,
    trigger,
    setValue,
    handleClose,
    editActionClick,
    changePassActionClick,
    viewActionClick,
    deleteActionClick,
    openDeleteModal,
    openEditModal,
    openChangePasswordModal,
    openViewModal,
    openAddNewModal,
    deleteHandler,
    pass,
    setPass,
    confirmPass,
    setConfirmPass,
    passErr,
    loading,
    commissionActionClick,
    openCommissionModal,
    setOpenCommissionModal,
    totalCommission,
    filteredShipments,
    handleStartDateChange,
    handleEndDateChange,
  };
};
