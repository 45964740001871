import { useEffect, useRef, useState } from "react";

function useDropdown(name, setValue, onChange) {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState("bottom");

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (setValue) {
      setValue(name, selectedOption?.id);
    }
  }, [selectedOption, setValue]);

  useEffect(() => {
    const dropdownElement = dropdownRef.current;
    const { bottom } = dropdownElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Check if there is enough space below the dropdown, otherwise set position to "top"
    if (bottom + 200 > windowHeight) {
      setDropdownPosition("top");
    } else {
      setDropdownPosition("bottom");
    }
  }, [isOpen]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange && onChange(option);
  };

  return {
    dropdownRef,
    handleToggleDropdown,
    selectedOption,
    isOpen,
    handleOptionClick,
    dropdownPosition,
  };
}

export default useDropdown;
