import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
  AllOnboardingApplicationsQuery,
  getAllEntitiesQuery,
  removeNewEntityMutation,
  removeEntityMutation,
} from '../../../api';
import { approveApplicationMutation } from '../../../api/services/entityMembers.service';
import { locationIcon, userIcon } from '../../../assets';
import { CustomIcon } from '../../../common/customAssets';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { FORMATE_DATE } from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import useOutsideClick from '../../../hook/useOutsideClick';
import {
  storeAllEntitiesAction,
  storeUnapprovedEntitiesAction,
} from '../../../store';
import {
  getEntity,
  suspendEntityMutation,
} from '../../../api/services/entities.service';

const useEntities = () => {
  const [filterStatus, setFilter] = useState('');
  const [entitiesData, setEntitiesData] = useState([]);
  const [newEntitiesData, setNewEntitiesData] = useState([]);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  const [entityActionClickedId, setEntityActionClickedId] = useState(null);

  const [entitiesActionClickedData, setEntitiesActionClickedData] =
    useState(null);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showChangeRateModal, setShowChangeRateModal] = useState(false);
  const [showChangeSalesMemberModal, setShowChangeChangeMemberModal] =
    useState(false);

  const actionMenuRef = useRef(null);

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  const [approvedEntity] = useApi(approveApplicationMutation);
  const { entities } = useSelector((state) => state);
  const { onBoarding } = useSelector((state) => state);

  const [getApprovedEntities, loading] = useApi(
    getAllEntitiesQuery,
    storeAllEntitiesAction
  );

  const [getSingleEntity] = useApi(getEntity);

  const [getUnapprovedEntities, unapprovedLoading] = useApi(
    AllOnboardingApplicationsQuery,
    storeUnapprovedEntitiesAction
  );

  const handleNewApplication = () => {
    setFilter('new');
    localStorage.setItem('Entity Current Tab', 'new');
  };

  const handleAllEntities = () => {
    setFilter('all');
    localStorage.setItem('Entity Current Tab', 'all');
  };

  //  =handle action menu dots click

  const handleLocalStorage = (data) => {
    localStorage.setItem('Filter Status', filterStatus);

    if (onBoarding) {
      const filterData =
        onBoarding.newEntities.allOnboardingApplications[data.id];
      localStorage.setItem('Selected Entity', JSON.stringify(filterData));
    }
  };

  const handleActionClick = (e, data) => {
    e.stopPropagation();
    setEntitiesActionClickedData(data);
    setEntityActionClickedId({ data });
    toggleActionMenu ? setToggleActionMenu(null) : setToggleActionMenu(data.id);
    handleLocalStorage(data);
  };

  //delete from action menu
  const openSuspendModal = () => {
    setShowSuspendModal(true);
  };

  const closeSuspendModal = () => {
    setShowSuspendModal(false);
  };

  //delete from action menu
  const openPolicyModal = () => {
    setShowPolicyModal(true);
  };

  const closePolicyModal = () => {
    setShowPolicyModal(false);
  };

  //delete from action menu
  const openDeteleModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  //appoval from action menu for new application

  const openApprovalModal = () => {
    // console.log('hittin');
    setShowApprovalModal(true);
  };

  const closeApprovalModal = () => {
    setShowApprovalModal(false);
  };

  // change rate from action menu for all applications

  const openChangeRateModal = () => {
    setShowChangeRateModal(true);
  };

  const closeChangeRateModal = () => {
    setShowChangeRateModal(false);
  };

  const openChangeSalesMemberModal = () => {
    setShowChangeChangeMemberModal(true);
  };

  const closeChangeSalesMemberModal = () => {
    setShowChangeChangeMemberModal(false);
  };

  const suspendEntityHandler = async (entity) => {
    try {
      let data = null;
      data = await suspendEntityMutation(Number(entity.data.id));

      if (data) {
        setToggleActionMenu(false);
        getApprovedEntities();
        toast.success('Success', { duration: TOAST_TIME });
      } else {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    } catch (error) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    } finally {
      setShowSuspendModal(false);
    }

    return { data: {} };
  };

  const policyDocumentHandler = async (entity) => {
    try {
      let data = null;
      console.log('policyDocumentHandler');
    } catch (error) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    } finally {
      closePolicyModal();
    }

    return { data: {} };
  };

  const cancelEntityHandler = async (entity) => {
    try {
      let data = null;
      if (filterStatus === 'all') {
        data = await removeEntityMutation(Number(entity.data.id));
      } else if (filterStatus === 'new') {
        data = await removeNewEntityMutation(entity.data.businessName);
      }
      if (data) {
        setToggleActionMenu(false);
        getApprovedEntities();
        getUnapprovedEntities();
        toast.success('Success', { duration: TOAST_TIME });
      } else {
        toast.error('Something went wrong', { duration: TOAST_TIME });
      }
    } catch (error) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    } finally {
      setShowDeleteModal(false);
    }

    return { data: {} };
  };

  const approveEntityHandler = async (value) => {
    const entity = value.data;

    let apidata = {
      additionalNote: '',
      address: entity.address,
      businessDBA: entity.businessName,
      businessName: entity.businessName,
      businessTIN: entity.businessTIN,
      currentInsurancePolicy: entity.currentInsurancePolicy,
      emailAddress: entity.emailAddress,
      industryType: isNaN(Number(entity.industryType))
        ? 0
        : Number(entity.industryType),
      phoneNumber: entity.phoneNumber,
      principalName: entity.principalName,
      premiumRate: '0.0125',
      salesPersonID: entity.salesPersonID,
      website: entity.website,
      since: entity.since,
      donation: 0,
      slug: '',
      rebateEmailAddress: entity.emailAddress,
      rebateAddress: entity.address,
    };
    const { data } = await approvedEntity({ ...apidata });
    if (data?.approveApplication === true) {
      setToggleActionMenu(false);
      getApprovedEntities();
      getUnapprovedEntities();
      toast.success('Entity approved successfully', { duration: TOAST_TIME });
    } else if (!data.approveApplication) {
      setToggleActionMenu(false);
      getApprovedEntities();
      getUnapprovedEntities();
      toast.success('Success', { duration: TOAST_TIME });
    } else {
      toast.error('Something went wrong', { duration: TOAST_TIME });
      setToggleActionMenu(false);
    }

    return { data: {} };
  };

  useEffect(() => {
    getApprovedEntities();
    getUnapprovedEntities();
    if (localStorage.getItem('Entity Current Tab'))
      setFilter(localStorage.getItem('Entity Current Tab'));
    else setFilter('all');
  }, []);

  // console.log('kmk ', entities?.allEntities[0]);

  useEffect(() => {
    let entitiesArryay = entities.allEntities.allEntity?.map((item) => {
      return {
        id: item.id,
        business: item?.name,
        salesperson: item.EIN,
        isBlocked: item.isBlocked,
        email: item.emailAddress,
        phone: item.phoneNumber,
        industry: item.type?.name,
        policyDocument: item.policyDocument,
        joined: FORMATE_DATE(item.createdAt, 'mm/dd/yy'),
        count: (
          <div className='flex gap-2'>
            <div className='rounded p-1.5 bg-[#ff99001f]'>
              <div className='flex items-center gap-1'>
                <CustomIcon icon={userIcon} className='h-5 w-5' />
                <div className='text-[#475467] text-center text-sm not-italic font-medium leading-[normal]'>
                  {item._count && item._count.entityMember}
                </div>
              </div>
            </div>
            <div className='rounded p-1.5 bg-[#4394ff1f]'>
              <div className='flex items-center gap-1'>
                <CustomIcon icon={locationIcon} className='h-5 w-5' />
                <div className='text-[#475467] text-center text-sm not-italic font-medium leading-[normal]'>
                  {item._count && item._count.location}
                </div>
              </div>
            </div>
          </div>
        ),
      };
    });

    setEntitiesData(entitiesArryay);
  }, [entities]);

  useEffect(() => {
    if (onBoarding !== undefined) {
      let newEntitiesArryay =
        onBoarding.newEntities.allOnboardingApplications?.map((item, index) => {
          return {
            ...item,
            id: `${index}`,
            business: item.businessName,
            salesperson: item.businessTIN,
            email: item.emailAddress,
            phone: item.phoneNumber,
            industry: item.industryType,
            since: item.since,
          };
        });
      setNewEntitiesData(newEntitiesArryay);
    }
  }, [onBoarding]);

  return {
    entitiesData,
    newEntitiesData,
    handleNewApplication,
    handleAllEntities,
    filterStatus,
    handleActionClick,
    actionMenuRef,
    entitiesActionClickedData,
    toggleActionMenu,
    openSuspendModal,
    closeSuspendModal,
    openDeteleModal,
    closeDeleteModal,
    showDeleteModal,
    showSuspendModal,
    openApprovalModal,
    showApprovalModal,
    approveEntityHandler,
    closeApprovalModal,
    entityActionClickedId,
    suspendEntityHandler,
    cancelEntityHandler,
    showChangeRateModal,
    openChangeRateModal,
    closeChangeRateModal,
    showChangeSalesMemberModal,
    openChangeSalesMemberModal,
    closeChangeSalesMemberModal,
    showPolicyModal,
    openPolicyModal,
    closePolicyModal,
    policyDocumentHandler,
    loading: unapprovedLoading || loading
  };
};

export default useEntities;
