import React from 'react';
import { Link } from 'react-router-dom';
import { CustomButton, CustomInput, CustomText } from '../../../common/custom';
import CustomHeading from '../../../common/custom/CustomHeading';
import { PATHS } from '../../../constants/paths';
import FormImageLayout from '../component/FormImageLayout';
import { useLogin } from './useLogin';
import CustomRadio from '../../../common/custom/CustomRadio';

const Login = () => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    trigger,
    setValue,
    loginLoading,
  } = useLogin();

  return (
    <FormImageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomHeading>My Account</CustomHeading>
        <CustomText className='text-para-black text-small-para md:text-medium-para font-[500] font-roboto text-center mt-[12px]'>
          Welcome back! Please enter your details here
        </CustomText>
        {/* <div className='mb-[16px] mt-[28px]'>
          <CustomText className='text-bold'>Login as:</CustomText>
          <CustomRadio
            register={register}
            name='loginUserType'
            options={[
              { value: 'ADMIN', label: 'Admin' },
              { value: 'CEASTA_STAFF', label: 'Ceasta Staff', selected: true },
            ]}
            errors={errors}
          />
        </div> */}
        <div className='mb-[21px] mt-[28px]'>
          <CustomText>Email</CustomText>
          <div className='mt-[4px]'>
            <CustomInput
              register={register}
              name='email'
              errors={errors}
              placeholder='Enter your email'
              trigger={trigger}
              setValue={setValue}
            />
          </div>{' '}
        </div>
        <div>
          <CustomText as='label'>Password</CustomText>
          <div className='mt-[4px]'>
            <CustomInput
              type='password'
              register={register}
              name='password'
              errors={errors}
              placeholder='Enter your pasword'
              trigger={trigger}
              setValue={setValue}
            />
          </div>
        </div>
        <div className='flex justify-end mt-[24px] mb-[30px]'>
          <Link to={PATHS.FORGET_PASSWORD}>
            <CustomText
              as='p'
              className='text-small-para text-foundation-brown font-[500] font-roboto'
            >
              Forgot password
            </CustomText>
          </Link>
        </div>
        <CustomButton disabled={loginLoading ? true : false} text='Sign in' />
      </form>
    </FormImageLayout>
  );
};

export default Login;
