import React from 'react';
import CustomContainer from '../../../../common/custom/CustomContainer';
import {
  CustomButton,
  CustomDropdown,
  CustomInput,
  CustomText,
  ValidationErrorText,
} from '../../../../common/custom';
import { useSignUpStepOne } from './useSignUpStepOne';

const SignUpStepOne = ({ increaseStepHandler }) => {
  const { handleSubmit, onSubmit, register, errors, setValue } =
    useSignUpStepOne(increaseStepHandler);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col my-8 gap-6 w-full'
    >
      <div className='flex flex-col sm:flex-row justify-center gap-6'>
        <CustomContainer className='w-full'>
          <CustomInput
            name='nameOfBusiness'
            register={register}
            placeholder='Enter name of your business'
            label='Name of business'
            required
            errors={errors}
          />
        </CustomContainer>
        <CustomContainer className='w-full'>
          <CustomInput
            name='tin'
            register={register}
            placeholder='Enter your TIN'
            label='EIN(optional)'
          />
        </CustomContainer>
      </div>
      <div className='flex flex-col sm:flex-row gap-[24px] '>
        <CustomContainer className='w-full '>
          <div className='mt-[2px]'>
            <div className='mb-[8px]'>
              <CustomText className='  text-[#464646] text-sm not-italic font-[600] leading-5'>
                Type of industry<span className='text-foundation-brown'>*</span>
              </CustomText>
            </div>

            <CustomDropdown
              placeholder='Enter your industry'
              options={[
                { id: '1', name: 'Carrier' },
                { id: '2', name: 'Furniture' },
                { id: '3', name: 'Fine Art' },
                { id: '4', name: 'Interior Design' },
                { id: '5', name: 'Other' },
              ]}
              setValue={setValue}
              name='industry'
            />

            {errors.industry ? (
              <ValidationErrorText>
                {errors.industry.message}
              </ValidationErrorText>
            ) : null}
          </div>
        </CustomContainer>
        <CustomContainer className='w-full'>
          <CustomInput
            name='nameOfPrincipal'
            register={register}
            placeholder='Enter name of your principal'
            label='Name of Principal'
            required
          />

          {errors.nameOfPrincipal ? (
            <ValidationErrorText>
              {errors.nameOfPrincipal.message}
            </ValidationErrorText>
          ) : null}
        </CustomContainer>
      </div>

      <div className='flex justify-center mt-2'>
        <CustomButton text='Next' />
      </div>
    </form>
  );
};

export default SignUpStepOne;
