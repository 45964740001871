import { Tooltip } from '@material-tailwind/react';
import {
  brownClose,
  crossCircle,
  pdfIcon,
  uploadFileIcon,
  wordIcon,
} from '../../../assets';
import {
  CustomButton,
  CustomInput,
  CustomModal,
  CustomHeading,
  ValidationErrorText,
} from '../../../common/custom';
import CustomDropdown3 from '../../../common/custom/custom-drop-down/index3';
import FileUpload from '../../../common/custom/file-upload/file-upload.component';
import { CustomIcon } from '../../../common/customAssets';
import { FILE_SIZE_B_TO_MB } from '../../../helpers/helpers';
import usePolicyDocument from './usePolicyDocument';
const PolicyDocumentModal = ({ closeModal, modalHeading, entityId }) => {
  const {
    policyFile,
    err,
    isLoading,
    handleSubmit,
    onSubmit,
    handleUpload,
    handleCancelUpload,
  } = usePolicyDocument({
    entityId,
    closeModal,
  });

  return (
    <>
      <CustomModal>
        <div className='h-auto p-8 max-w-[600px] h-[250px] bg-pure-white rounded-[12px] flex flex-col items-center gap-1 justify-center px-4'>
          <div className='flex justify-between py-2 max-w-[476px] w-full h-[50px]'>
            <div className=' gap-1 flex '>
              <div className=' max-w-[476px]'>
                <CustomHeading className='text-[26px] font-inter leading-[28px] font-[600] text-foundation-brown'>
                  {modalHeading}
                </CustomHeading>
              </div>
            </div>
            <div
              onClick={closeModal}
              className=' cursor-pointer flex justify-end'
            >
              <CustomIcon
                icon={brownClose}
                className=' h-[18px] w-[18px] flex justify-center items-center'
              />
            </div>
          </div>
          <div className='min-w-[360px]'>
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            {policyFile ? (
              <div className='flex justify-center'>
                <div className='flex items-center gap-4 p-2 rounded-lg bg-[#efefef]'>
                  <img
                    src={pdfIcon}
                    alt='file'
                    className='h-10 hover:cursor-pointer'
                  />
                  <div className=''>
                    <div className='overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6'>
                      {policyFile?.originalname}
                    </div>
                    <div className='text-[#747474] text-xs not-italic font-normal leading-[normal]'>
                      {FILE_SIZE_B_TO_MB(policyFile.size)}MB
                    </div>
                  </div>
                </div>
                <Tooltip className='' content='Cancel'>
                  <div
                    className='relative right-3 -top-3'
                    onClick={() => handleCancelUpload()}
                  >
                    <CustomIcon
                      icon={crossCircle}
                      className='w-6 h-6 cursor-pointer'
                    />
                  </div>
                </Tooltip>
              </div>
            ) : (
              <FileUpload handleChange={handleUpload} fileTypes={['PDF']} />
            )}
            {err && (
              <div className='mt-1'>
                <ValidationErrorText>{err}</ValidationErrorText>
              </div>
            )}
            <div className=' flex flex-col md:flex-row gap-[32px] justify-end mt-[32px]'>
              <CustomButton
                type='button'
                onClick={closeModal}
                className='bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
                variant='outline'
                text='Cancel'
              />
              <CustomButton
                className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
                text='Attach Policy'
                onClick={onSubmit}
              />
            </div>
            {/* </form> */}
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default PolicyDocumentModal;
