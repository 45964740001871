import { gql } from '@apollo/client';
import client from '../apollo.';

export const getIndustriesQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query allIndustries {
        allIndustries {
          id
          name
        }
      }
    `,
  });

  return { data, errors };
};

export const getCarriersQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query allCarriers {
        allCarriers {
          id
          name
          address
          createdAt
        }
      }
    `,
  });

  return { data, errors };
};

export const getShipmentTypesQuery = async () => {
  const { data, errors } = await client.query({
    query: gql`
      query allShipmentTypes {
        allShipmentTypes {
          id
          name
        }
      }
    `,
  });

  return { data, errors };
};

export const addIndustryMutation = async (inputData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation createIndustry($industryCreateInput: IndustryCreateInput!) {
        createIndustry(industryCreateInput: $industryCreateInput) {
          id
          name
        }
      }
    `,
    variables: {
      industryCreateInput: {
        name: inputData.name,
      },
    },
  });
  return { data, errors };
};

export const updateIndustryMutation = async (updateData) => {
  // console.log('updateData', updateData);
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateIndustry(
        $industryWhereUniqueInput: IndustryWhereUniqueInput!
        $updateIndustryInput: IndustryUpdateInput!
      ) {
        updateIndustry(
          industryWhereUniqueInput: $industryWhereUniqueInput
          updateIndustryInput: $updateIndustryInput
        ) {
          id
        }
      }
    `,
    variables: {
      industryWhereUniqueInput: {
        id: parseInt(updateData.industryId),
      },
      updateIndustryInput: {
        name: {
          set: updateData.name,
        },
      },
    },
  });
  // console.log('data', data);

  return { data, errors };
};

export const deleteIndustryMutation = async (industryId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation DeleteIndustry($deleteIndustryId: Int!) {
        deleteIndustry(id: $deleteIndustryId) {
          id
        }
      }
    `,
    variables: {
      deleteIndustryId: industryId,
    },
  });
  return { data, errors };
};

export const addShipmentTypeMutation = async (inputData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation createShipmentType(
        $shipmentTypeCreateInput: ShipmentTypeCreateInput!
      ) {
        createShipmentType(shipmentTypeCreateInput: $shipmentTypeCreateInput) {
          id
          name
        }
      }
    `,
    variables: {
      shipmentTypeCreateInput: {
        name: inputData.name,
      },
    },
  });
  return { data, errors };
};

export const updateShipmentTypeMutation = async (updateData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateShipmentType(
        $shipmentTypeWhereUniqueInput: ShipmentTypeWhereUniqueInput!
        $updateShipmentTypeInput: ShipmentTypeUpdateInput!
      ) {
        updateShipmentType(
          shipmentTypeWhereUniqueInput: $shipmentTypeWhereUniqueInput
          updateShipmentTypeInput: $updateShipmentTypeInput
        ) {
          id
        }
      }
    `,
    variables: {
      shipmentTypeWhereUniqueInput: {
        id: parseInt(updateData.shipmentTypeId),
      },
      updateShipmentTypeInput: {
        name: {
          set: updateData.name,
        },
      },
    },
  });

  return { data, errors };
};

export const deleteShipmentTypeMutation = async (shipmentTypeId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation DeleteShipmentType($deleteShipmentTypeId: Int!) {
        deleteShipmentType(id: $deleteShipmentTypeId) {
          id
        }
      }
    `,
    variables: {
      deleteShipmentTypeId: shipmentTypeId,
    },
  });
  return { data, errors };
};

export const addCarrierMutation = async (inputData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation createCarrier($carrierCreateInput: CarrierCreateInput!) {
        createCarrier(carrierCreateInput: $carrierCreateInput) {
          id
          name
          address
          createdAt
        }
      }
    `,
    variables: {
      carrierCreateInput: {
        name: inputData.name,
        address: inputData?.address,
      },
    },
  });
  return { data, errors };
};

export const updateCarrierMutation = async (updateData) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation UpdateCarrier(
        $carrierWhereUniqueInput: CarrierWhereUniqueInput!
        $updateCarrierInput: CarrierUpdateInput!
      ) {
        updateCarrier(
          carrierWhereUniqueInput: $carrierWhereUniqueInput
          updateCarrierInput: $updateCarrierInput
        ) {
          id
          name
          address
          createdAt
        }
      }
    `,
    variables: {
      carrierWhereUniqueInput: {
        id: parseInt(updateData.carrierId),
      },
      updateCarrierInput: {
        name: {
          set: updateData.name,
        },
        address: {
          set: updateData.address,
        },
      },
    },
  });

  return { data, errors };
};

export const deleteCarrierMutation = async (carrierId) => {
  const { data, errors } = await client.mutate({
    mutation: gql`
      mutation DeleteCarrier($deleteCarrierId: Int!) {
        deleteCarrier(id: $deleteCarrierId) {
          id
        }
      }
    `,
    variables: {
      deleteCarrierId: carrierId,
    },
  });
  return { data, errors };
};
