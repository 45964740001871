import React from 'react';
import { CustomDropdown } from '../../../../../common/custom';
import TextArea from '../../../../../common/custom/customTextArea';
import { useDescribeClaim } from './use-describe-claim.hook';

const DescribeClaim = ({ increaseStepHandler, decreaseStepHandler }) => {
  const {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    claimType,
    onCancel,
  } = useDescribeClaim(increaseStepHandler);
  return (
    <div className='stepper-cards'>
      <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
        Claim Details
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid gap-4'>
          <div className='md:mt-[30px]'>
            <CustomDropdown
              placeholder='Claim Type'
              label='Claim Type'
              value={claimType}
              errors={errors}
              options={[
                { id: 'Damaged', state: 'Damaged' },
                { id: 'Lost/Stolen', state: 'Lost/Stolen' },
              ]}
              setValue={setValue}
              name='claimType'
            />
          </div>
          <div>
            <TextArea
              label='Briefly describe what led to the damage or loss'
              name='description'
              register={register}
              errors={errors}
              placeholder='Description'
              type='text'
              labelClassName='text-[#344054] text-sm not-italic font-medium leading-5'
            />
          </div>

          <div className='flex justify-end mt-4'>
            <div className='flex gap-[24px]'>
              <button
                className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className='flex border border-solid text-[#77553D] text-base not-italic font-medium leading-6 border-[#77553D] w-40 justify-center bg-[ #fff] items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg border-[#77553D]'
                onClick={() => decreaseStepHandler()}
              >
                Previous
              </button>
              <button
                className='ml-4 flex text-white text-base not-italic font-medium leading-6 w-40 bg-[#77553d] justify-center items-center gap-2 self-stretch shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] px-5 py-3 rounded-lg'
                type='submit'
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DescribeClaim;
