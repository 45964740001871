import React from 'react';
import { viewClaimIcon } from '../../../../assets';
import CustomTable from '../../../../common/custom/CustomTable/custom-table.component';
import { CLAIMS } from '../../../../constants/columns';
import { DeleteModal } from '../../../modals';
import useAllClaims from './useAllClaims.hook';

const ALlClaimsList = ({ data, isLoading, currentTab }) => {
  const {
    showDeleteModal,
    closeDeleteModal,
    toggleActionMenu,
    openViewClaimModal,
    cancelClaimHandler,
    handleActionClick,
    claimActionClickedId,
  } = useAllClaims(isLoading, currentTab);

  return (
    <>
      {showDeleteModal ? (
        <DeleteModal
          modalHeading='Delete Claim?'
          modalPara='Are you sure you want to delete this Claim? This action cannot be undone.'
          deleteHandler={() => cancelClaimHandler(claimActionClickedId)}
          closeDeleteModal={closeDeleteModal}
        />
      ) : null}

      <CustomTable
        header={CLAIMS}
        table={currentTab === 'manual' ? "/manual-claims" : "/claims"}
        rows={data}
        {...{ handleActionClick, toggleActionMenu, openViewClaimModal }}
        icons={[viewClaimIcon]}
      />
    </>
  );
};

export default ALlClaimsList;
