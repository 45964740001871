import { loginSchema } from '../schema/login';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';
import useApi from '../../../hook/useApi';
import { loginUser } from '../../../api';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { persistor, storeLoginUserAction } from '../../../store';

export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, loginLoading] = useApi(loginUser);

  const { handleSubmit, register, trigger, errors, setValue } =
    useHookFormSubmission(loginSchema);

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Logging in! Please wait...');
    const { data, errors } = await login({
      username: values.email,
      password: values.password,
      loginUserType: values.loginUserType,
    });
    if (data) {
      toast.dismiss();
      toast.success('You have successfully logged in!', {
        duration: TOAST_TIME,
      });
      localStorage.setItem(
        'token',
        JSON.stringify(data.loginStaff.access_token)
      );
      dispatch(
        storeLoginUserAction(JSON.stringify(data.loginStaff.access_token))
      );
      localStorage.setItem('login-user', JSON.stringify(data.loginStaff.user));
      navigate(PATHS.DASHBOARD);
    }
    if (errors) {
      toast.error(
        errors?.length
          ? errors[0]?.message
          : 'Login unsuccessful. Please check your credentials and try again',
        { duration: TOAST_TIME }
      );
    }
  };

  useEffect(() => {
    localStorage.clear();
    setTimeout(() => {
      persistor.purge();
      persistor.purge();
    }, 200);
  }, []);
  return {
    handleSubmit,
    onSubmit,
    register,
    trigger,
    errors,
    setValue,
    loginLoading,
  };
};
