import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ValidationErrorText from '../ValidationErrorText';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: '2px 0',
    borderRadius: '4px',
    border: '1px solid #E7ECF4',
    boxShadow: state.isFocused ? '0 0 0 1px #4A90E2' : 'none',
    '&:hover': {
      border: '1px solid #4A90E2',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#98A2B3',
  }),
};

const CustomDropdown3 = ({
  options,
  setOptions,
  label,
  placeholder,
  name,
  value,
  error,
  setValue,
}) => {
  const [val, setVal] = useState();

  useEffect(() => {
    setVal(value), [value];
  });

  const createOption = (label) => ({
    label,
    value: label?.toLowerCase()?.replace(/\W/g, ''),
  });

  return (
    <>
      {label && (
        <label className='text-sm text-[#344054] font-medium mb-2'>
          {label}
        </label>
      )}
      <div className='mt-1'></div>
      <Select
        isClearable
        // isLoading
        isSearchable
        options={options}
        styles={customStyles}
        onChange={(v) => {
          setValue(v?.value);
        }}
        // value={val}
        value={value?.label ? value?.label : value}
        defaultValue={value}
        onKeyDown={(e) => {
          if (e.key === 'Backspace' && e.target.value?.length <= 0) {
            e.preventDefault();
          }
        }}
      />
      {!value && error && (
        <div className='mt-1'>
          <ValidationErrorText>{error}</ValidationErrorText>
        </div>
      )}
    </>
  );
};

export default CustomDropdown3;
