import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export const useHookFormSubmission = (validationSchema) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  return { handleSubmit, register, reset, errors, watch, setValue, trigger };
};
