// import React from 'react';
// import { SHIPMENT_TYPES } from '../../constants/columns';
// import CustomContainer from '../../common/custom/CustomContainer';
// import {
//   ClientsPanelHeader,
//   CustomButton,
//   CustomEmptyDataComponent,
//   CustomHeading,
// } from '../../common/custom';
// import CustomTable from '../../common/custom/CustomTable/custom-table.component';
// import { crossCircle, filterIcon } from '../../assets';
// import { useShipmentTypes } from './useShipmentTypes';

// const ShipmentTypes = () => {
//   const {
//     data,
//     showFilterModal,
//     handleOpenFilterModal,
//     handleCloseFilterModal,
//     handleFilterSubmit,
//     handleFilterReset,
//     handleSubmit,
//     register,
//     errors,
//     setValue,
//     entityOptions,
//     isFilterCleared,
//     setIsFilterCleared,
//   } = useShipmentTypes();

//   // if (data?.length === 0) {
//   //   return (
//   //     <CustomEmptyDataComponent heading='There are no Billings yet.'>
//   //       <ClientsPanelHeader />
//   //     </CustomEmptyDataComponent>
//   //   );
//   // }
//   return (
//     <CustomContainer>
//       {showFilterModal && (
//         <FilterBillingModal
//           closeFilterModal={handleCloseFilterModal}
//           onReset={handleFilterReset}
//           errors={errors}
//           entitiesData={entityOptions}
//           register={register}
//           setValue={setValue}
//           handleSubmit={handleSubmit}
//           onSubmit={handleFilterSubmit}
//         />
//       )}
//       <ClientsPanelHeader />
//       <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
//         <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
//           <div className='flex justify-between items-center flex-row w-full'>
//             <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
//               Shipment Types
//             </CustomHeading>
//           </div>
//         </CustomContainer>
//         <div className='overflow-x-auto'>
//           {data?.length ? (
//             <CustomTable
//               header={SHIPMENT_TYPES}
//               rows={data}
//               table='shipment-types'
//             />
//           ) : (
//             <CustomEmptyDataComponent heading='No Data Found!'></CustomEmptyDataComponent>
//           )}
//         </div>
//       </div>
//     </CustomContainer>
//   );
// };

// export default ShipmentTypes;

import React from 'react';
import { SHIPMENT_TYPES } from '../../constants/columns';
import CustomContainer from '../../common/custom/CustomContainer';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomEmptyDataComponent,
  CustomHeading,
} from '../../common/custom';
import CustomTable from '../../common/custom/CustomTable/custom-table.component';
import { useShipmentTypes } from './useShipmentTypes';
import { plusGray } from '../../assets';
import AddNewModal from '../../common/custom/Modals/AddNewModal';
import CommonActionMenu from '../../common/actionMenu';
import { DeleteModal } from '../modals';
import CircularILoader from '../../common/custom/circularLoader';

const ShipmentTypes = () => {
  const {
    data,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    selectedItem,
    setOpenAddNewModal,
    onSubmit,
    handleSubmit,
    register,
    errors,
    trigger,
    setValue,
    handleClose,
    editActionClick,
    deleteActionClick,
    openDeleteModal,
    openEditModal,
    openAddNewModal,
    deleteHandler,
    loading
  } = useShipmentTypes();

  return (
    <>
     {loading ? (
        <div class='flex h-[126px] justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
          <CircularILoader />
        </div>
      ) : (
      <CustomContainer>
        <ClientsPanelHeader />
        <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
          <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
            <div className='flex justify-between items-center flex-row w-full'>
              <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
                Shipment Types
              </CustomHeading>
              <CustomButton
                onClick={() => setOpenAddNewModal(true)}
                icon={plusGray}
                text='Add New'
                className={`bg-foundation-white px-[16px] rounded-[8px] border border-[1px]  border-[#D0D5DD] h-[40px] min-w-[120px] flex justify-between items-center text-[14px] font-[500] mt-4 sm:mt-0  gap-3`}
              />
            </div>
          </CustomContainer>
          <div className='overflow-x-auto'>
            {data?.length ? (
              <CustomTable
                table="/shipmentsType"
                header={SHIPMENT_TYPES}
                rows={data}
                handleActionClick={handleActionClick}
                toggleActionMenu={toggleActionMenu}
                actionMenuRef={actionMenuRef}
                menu={
                  <CommonActionMenu
                    data={selectedItem}
                    editActionClick={editActionClick}
                    deleteActionClick={deleteActionClick}
                  />
                }
              />
            ) : (
              <CustomEmptyDataComponent heading='No Data Found!'></CustomEmptyDataComponent>
            )}
          </div>
        </div>
      </CustomContainer>
      )}
      {openAddNewModal && (
        <AddNewModal
          title={'Add New Shipment Type'}
          open={openAddNewModal}
          onClose={handleClose}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          trigger={trigger}
          setValue={setValue}
        />
      )}
      {openEditModal && (
        <AddNewModal
          title={'Edit Shipment Type'}
          open={openEditModal}
          onClose={handleClose}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          trigger={trigger}
          setValue={setValue}
          data={selectedItem}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          modalHeading='Delete Shipment Type?'
          modalPara='Are you sure you want to delete this Shipment Type? This action cannot be undone.'
          deleteHandler={deleteHandler}
          closeDeleteModal={handleClose}
        />
      )}
    </>
  );
};

export default ShipmentTypes;
