import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

const CustomModal = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  return createPortal(
    <div className='fixed inset-0 overflow-hidden z-[100] bg-black bg-opacity-40 backdrop-blur-3 flex justify-center items-center p-1'>
      {/* <div className='bg-white max-w-[554px] w-full  p-4 '>{children}</div> */}
      {children}
    </div>,

    document.querySelector('.modal')
  );
};

export default CustomModal;
