import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getManualClaimDetailsQuery,
  updateManualClaimMutation,
} from '../../../api/services/claim.service';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import {
  updateClaimSchema,
  updateManualClaimSchema,
} from '../../shipments/schema/describeClaimSchema';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';

function UseManualClaimDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateManualClaim] = useApi(updateManualClaimMutation);

  const { handleSubmit, register, errors, setValue } = useHookFormSubmission(
    updateManualClaimSchema
  );

  const [manualClaimDetails, data] = useApi(getManualClaimDetailsQuery);

  const [claimType, setClaimType] = useState('');
  // const [adjustmentAmount, setAdjustmentAmount] = useState(0);
  const [claimData, setClaimData] = useState(null);
  const [claimClosefile, setClaimCloseFile] = useState(null);
  const [fileError, setFileError] = useState(false);

  useEffect(() => {
    claimDetail();
  }, [id]);

  const claimDetail = async () => {
    const manualClaim = await manualClaimDetails(Number(id));

    setClaimData(manualClaim?.data?.manualClaim);
    setClaimType(
      manualClaim &&
        manualClaim?.data?.manualClaim &&
        manualClaim?.data?.manualClaim?.status
    );
    // setAdjustmentAmount(
    //   manualClaim &&
    //     manualClaim.data.claim &&
    //     manualClaim.data.claim.claimAmount
    // );
    setValue(
      'claimType',
      claimType ||
        (manualClaim &&
          manualClaim?.data?.manualClaim &&
          manualClaim?.data?.manualClaim?.status)
    );
    setValue(
      'internalNote',
      manualClaim &&
        manualClaim?.data?.manualClaim &&
        manualClaim?.data?.manualClaim?.internalNote
    );
  };

  const options = [
    { id: 'Claim Received', state: 'Claim Received' },
    { id: 'Under Investigation', state: 'Under Investigation' },
    { id: 'Claim Assessed', state: 'Claim Assessed' },
    { id: 'Pending Documentation', state: 'Pending Documentation' },
    { id: 'Claim Approved', state: 'Claim Approved' },
    { id: 'Claim Denied', state: 'Claim Denied' },
    { id: 'Settlement Offered', state: 'Settlement Offered' },
    { id: 'Claim Paid and Closed', state: 'Claim Paid and Closed' },
    { id: 'Claim Closed', state: 'Claim Closed' },
    { id: 'Manual Claim Created', state: 'Manual Claim Created' },
  ];

  const handleUpdateClaim = async (values) => {
    toast.loading('Please wait! Updating the claim...', {
      duration: TOAST_TIME,
    });
    const { data, errors } = await updateManualClaim({
      claimId: Number(id),
      claimAmount: adjustmentAmount,
      status: claimType,
      internalNote: values.internalNote,
      claimCloseDoc: JSON.stringify([claimClosefile]),
    });

    if (data) {
      toast.success('Claim updated successfully!', {
        duration: TOAST_TIME,
      });
      navigate(-1);
    } else if (errors) {
      toast.error('Something went wrong', { duration: TOAST_TIME });
    }
  };

  const onCancel = () => navigate(-1);

  return {
    claim: data && data.claim,
    claimType,
    options,
    setClaimType,
    handleSubmit,
    register,
    errors,
    handleUpdateClaim,
    claimData,
    onCancel,
    claimClosefile,
    setClaimCloseFile,
    fileError,
    setFileError,
    // adjustmentAmount,
    // setAdjustmentAmount,
  };
}

export default UseManualClaimDetails;
