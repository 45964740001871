import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { reportShipmentMutation } from '../../../../api';
import { PATHS } from '../../../../constants/paths';
import useApi from '../../../../hook/useApi';
import { addShipmentAction } from '../../../../store';
import { resetReportShipmentFormAction } from '../../../../store/slices/formsSlice';
import { reportShipmentStepThreeSchema } from '../../schema/reportShipmentStepThreeSchema';
import { TOAST_TIME } from '../../../../constants/toastNotifications';
import { getCarriersQuery } from '../../../../api/services/admin.service';

export const useReportShipmentStepThree = () => {
  // const { addresses } = useSelector((state) => state);

  const [reportShipmentCall, reportShipmentCallLoading] = useApi(
    reportShipmentMutation
  );

  const { reportShipment } = useSelector((state) => state.forms);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, register, errors, setValue } = useHookFormSubmission(
    reportShipmentStepThreeSchema
  );

  const [getCarriers] = useApi(getCarriersQuery);
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await getCarriers();
      setCarriers(res?.data?.allCarriers);
    };
    getData();
  }, []);

  useEffect(() => {
    setValue(
      'pickupDate',
      reportShipment.formData.step3?.pickupDate
        ? reportShipment.formData.step3.pickupDate
        : ''
    );

    setValue(
      'deliveryDate',
      reportShipment.formData.step3?.deliveryDate
        ? reportShipment.formData.step3.deliveryDate
        : ''
    );
    setValue(
      'carrier',
      reportShipment.formData.step3?.carrier
        ? reportShipment.formData.step3.carrier
        : ''
    );
  }, []);

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('Please wait! Shipment is reporting...');
    let deliveryDate = new Date(values.deliveryDate);
    deliveryDate = DateTime.fromJSDate(deliveryDate).toFormat('yyyy-MM-dd');

    let pickupDate = new Date(values.pickupDate);
    pickupDate = DateTime.fromJSDate(pickupDate).toFormat('yyyy-MM-dd');
    const from = parseInt(reportShipment.formData.step1?.from);
    const uid = parseInt(reportShipment.formData.step1?.entity);
    const locationId = parseInt(reportShipment.formData.step1?.locationId);
    // locationId: parseInt(selectedEntity?.location[0]?.id),

    if (from && uid) {
      // console.log(
      //   reportShipment?.formData?.step1,
      //   reportShipment?.formData?.step2,
      //   '=>',
      //   values,
      //   deliveryDate,
      //   pickupDate,
      //   from,
      //   uid,
      //   from
      // );
      const { data, errors } = await reportShipmentCall({
        ...reportShipment.formData.step1,
        ...reportShipment.formData.step2,
        ...values,
        deliveryDate,
        pickupDate,
        locationId,
        // additionalNote: '',
        entityId: uid,
        from,
      });

      if (data) {
        dispatch(addShipmentAction(data));
        dispatch(resetReportShipmentFormAction());
        toast.dismiss();
        toast.success('Congratulations! Shipment is reported successfully!', {
          duration: TOAST_TIME,
        });
        navigate(PATHS.SHIPMENTS);
      } else if (errors) {
        toast.dismiss();
        toast.error('Failed to report. Please try again!', {
          duration: TOAST_TIME,
        });
      }
    } else {
      toast.error('From address or Entity not selected', {
        duration: TOAST_TIME,
      });
    }
  };

  return {
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    reportShipmentCallLoading,
    carriers,
  };
};

// ERROR in CREATE SHIPMENT FROM BACKEND
