import { Squash as Hamburger } from 'hamburger-react';
import { ceastaLogoWhite } from '../../../../assets';
import { CustomIcon } from '../../../../common/customAssets';
import MenuList from '../MenuList';
import User from '../User';

import { useMobileNavbar } from './useMobileNavbar';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';
import { useRef, useState } from 'react';
import useOutsideClick from '../../../../hook/useOutsideClick';

const MobileNavbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { logout } = useMobileNavbar();

  const hamburgerMenuRef = useRef(null);

  useOutsideClick(hamburgerMenuRef, () => setToggleMenu(false));
  const closeHamburgerMenu = () => {
    setToggleMenu(false);
  };
  return (
    <div
      ref={hamburgerMenuRef}
      className='bg-foundation-brown h-[50px] px-1   '
    >
      <div className='flex items-center justify-between  '>
        <Hamburger
          color='white'
          size={20}
          toggled={toggleMenu}
          toggle={() => setToggleMenu((prevState) => !prevState)}
        />
        <Link to={PATHS.SHIPMENTS}>
          <CustomIcon
            icon={ceastaLogoWhite}
            className='w-[180px]   flex items-center'
          />
        </Link>
      </div>

      <div
        className={`z-[100]  transition duration-500 ease-in-out left-0   fixed top-0 bottom-0 ${
          toggleMenu ? 'translate-x-0' : 'translate-x-[-300px]'
        } `}
      >
        <div className='bg-foundation-brown h-[100vh] w-[250px] '>
          <div className='flex justify-between items-center  p-1 '>
            <div
              className='flex items-center 
             gap-[20px] max-w-[68px] w-full'
            >
              <User
                type
                logout={logout}
              />
            </div>
            <Hamburger
              color='white'
              size={20}
              toggled={toggleMenu}
              toggle={() => setToggleMenu((prevState) => !prevState)}
            />
          </div>

          <div className=' mt-8'>
            <MenuList
              closeHamburgerMenu={closeHamburgerMenu}
              className='flex flex-col gap-4'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileNavbar;
