import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateShipmentMutation } from '../../../api';
import { PATHS } from '../../../constants/paths';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { FORMATE_DATE } from '../../../helpers/helpers';
import useApi from '../../../hook/useApi';
import { useHookFormSubmission } from '../../../hook/useHookFormSubmission';
import { editShipmentSchema } from '../schema/editShipmentSchema';
import { DateTime } from 'luxon';

export const useViewShipmentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [toAddress, setToAddress] = useState(null);
  const [declaredValue, setDeclaredValue] = useState('');
  const [customerDetailModalOpen, setCustomerDetailModal] = useState(false);

  const [d_date, set_d_date] = useState(null);

  const { shipments } = useSelector((state) => state);

  const [update] = useApi(updateShipmentMutation);

  const { handleSubmit, register, errors, trigger, setValue, watch } =
    useHookFormSubmission(editShipmentSchema);

  const findEditSHipment = shipments?.allShipments.find(
    (shipment) => shipment.id === id
  );

  const rate =
    findEditSHipment.location &&
    findEditSHipment.location.entity &&
    findEditSHipment.location.entity.rate;

  useEffect(() => {
    // console.log('DR', findEditSHipment.pickUpDate?.split('T')[0]);
    if (findEditSHipment) {
      if (findEditSHipment.carrierId) {
        setValue('carrier', findEditSHipment.carrierId);
      }
      if (findEditSHipment.pickUpDate) {
        const pickupDate = DateTime.fromISO(findEditSHipment.pickUpDate);
        setValue('pickupDate', pickupDate.toISODate());
      }
      if (findEditSHipment.deliveryDate) {
        const deliveryDate = DateTime.fromISO(findEditSHipment.deliveryDate);
        setValue('deliveryDate', deliveryDate.toISODate());
      }
      if (findEditSHipment.totalValue) {
        setValue('declaredValue', declaredValue || findEditSHipment.totalValue);
        setDeclaredValue(declaredValue || findEditSHipment.totalValue);
      }
      if (findEditSHipment.shipmentType) {
        setValue('merchandiseType', findEditSHipment.shipmentType.id);
      }
    }
  }, [findEditSHipment]);

  const watchedPickup = FORMATE_DATE(watch('pickupDate'));
  // console.log('wathcedPickup ', watchedPickup);

  const closeCustomerDetailModal = () => setCustomerDetailModal(false);
  const openCustomerDetailModal = () => setCustomerDetailModal(true);

  const getUpdateCustomerDetailData = (updatedCustomerDetail) => {
    setToAddress(updatedCustomerDetail);
  };

  const streetPlaceholder = () => {
    if (toAddress) {
      return toAddress;
    } else {
      return JSON.parse(findEditSHipment.to);
    }
  };

  const handleDeclaredValue = (value) => {
    setDeclaredValue(value);
  };

  const onSubmit = async (values) => {
    toast.dismiss();
    toast.loading('updating');
    values.deliveryDate = FORMATE_DATE(values.deliveryDate, 'yyyy-MM-dd');
    values.pickUpDate = FORMATE_DATE(values.pickupDate, 'yyyy-MM-dd');
    const modifiedToAddress = {
      name: toAddress.destinationName,
      city: toAddress.city,
      state: toAddress.state,
      zipCode: toAddress.zipCode,
      unitNo: toAddress.unitNumber,
      reference: toAddress.referenceNumber,
      country: toAddress.country,
    };
    const { data, errors } = await update({
      shipmentId: parseInt(findEditSHipment.id),
      ...values,
      to: toAddress ? JSON.stringify(modifiedToAddress) : findEditSHipment.to,
      fromLocationId: parseInt(findEditSHipment.locationId),
    });
    if (data) {
      navigate(PATHS.SHIPMENTS);
      toast.dismiss();
      toast.success('Successfully updated', { duration: TOAST_TIME });
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to update', { duration: TOAST_TIME });
    }
  };

  return {
    rate,
    data: findEditSHipment,
    handleSubmit,
    onSubmit,
    register,
    errors,
    setValue,
    closeCustomerDetailModal,
    openCustomerDetailModal,
    getUpdateCustomerDetailData,
    streetPlaceholder,
    customerDetailModalOpen,
    declaredValue,
    handleDeclaredValue,
    navigate,
    trigger,
  };
};
