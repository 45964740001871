import React from 'react';
import {
  SALES_MEMBERS,
  SALES_MEMBERS_COMMISSION,
  SHIPMENT_TYPES,
} from '../../constants/columns';
import CustomContainer from '../../common/custom/CustomContainer';
import {
  ClientsPanelHeader,
  CustomButton,
  CustomEmptyDataComponent,
  CustomHeading,
} from '../../common/custom';
import CustomTable from '../../common/custom/CustomTable/custom-table.component';
import { arrowLeft, backIcon, plusGray } from '../../assets';
import AddNewModal from '../../common/custom/Modals/AddNewModal';
import CommonActionMenu from '../../common/actionMenu';
import { DeleteModal } from '../modals';
import { useSalesMembers } from './useSalesMembers';
import CircularILoader from '../../common/custom/circularLoader';
import CommissionDetails from './CommissionDetails';
import { Box, Button, InputLabel, MenuItem, Select } from '@mui/material';
import BackButton from '../../common/custom/BackButton';
import CustomDatePicker from '../../common/custom/CustomDatePicker';
import CustomDatePicker2 from '../../common/custom/CustomDatePicker2';

const StyledMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: '300px',
    },
  },
};

const SalesMembers = () => {
  const {
    data,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    selectedItem,
    setOpenAddNewModal,
    onSubmit,
    onChangePassSubmit,
    handleSubmit,
    register,
    errors,
    trigger,
    setValue,
    handleClose,
    editActionClick,
    changePassActionClick,
    viewActionClick,
    deleteActionClick,
    openDeleteModal,
    openEditModal,
    openChangePasswordModal,
    openViewModal,
    openAddNewModal,
    deleteHandler,
    pass,
    setPass,
    confirmPass,
    setConfirmPass,
    passErr,
    loading,
    commissionActionClick,
    openCommissionModal,
    setOpenCommissionModal,
    totalCommission,
    filteredShipments,
    handleStartDateChange,
    handleEndDateChange,
  } = useSalesMembers();

  return (
    <>
      {loading ? (
        <div class='flex h-[126px] justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl'>
          <CircularILoader />
        </div>
      ) : (
        <CustomContainer>
          <ClientsPanelHeader />
          <div className=' border-2 border-[#EAECF0] rounded-[7px]'>
            <CustomContainer className='px-6 min-h-[75px] flex justify-center items-center '>
              <div className='flex justify-between items-center flex-row w-full'>
                <CustomHeading className='text-foundation-black font-inter text-small-heading font-[600]   max-w-[1152px] w-full h-full'>
                  {!openCommissionModal
                    ? 'Sales Members'
                    : 'Sales Members Commission'}{' '}
                </CustomHeading>
                {!openCommissionModal && (
                  <CustomButton
                    onClick={() => setOpenAddNewModal(true)}
                    icon={plusGray}
                    text='Add New'
                    className={`bg-foundation-white px-[16px] rounded-[8px] border border-[1px]  border-[#D0D5DD] h-[40px] min-w-[120px] flex justify-between items-center text-[14px] font-[500] mt-4 sm:mt-0  gap-3`}
                  />
                )}
              </div>
            </CustomContainer>
            <div className='overflow-x-auto'>
              {!openCommissionModal ? (
                <>
                  {data?.length ? (
                    <CustomTable
                      table='/sales-members'
                      header={SALES_MEMBERS}
                      rows={data}
                      handleActionClick={handleActionClick}
                      toggleActionMenu={toggleActionMenu}
                      actionMenuRef={actionMenuRef}
                      menu={
                        <CommonActionMenu
                          data={selectedItem}
                          editActionClick={editActionClick}
                          viewActionClick={viewActionClick}
                          changePassActionClick={changePassActionClick}
                          deleteActionClick={deleteActionClick}
                          commissionActionClick={commissionActionClick}
                        />
                      }
                    />
                  ) : (
                    <CustomEmptyDataComponent heading='No Data Found!'></CustomEmptyDataComponent>
                  )}
                </>
              ) : (
                <>
                  <div className='m-6 flex justify-between '>
                    <div className='max-w-[200px]'>
                      <Button
                        onClick={() => setOpenCommissionModal(false)}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '3px',
                          border: '1px solid #77553d',
                          color: '#77553d',
                        }}
                      >
                        <img src={arrowLeft} alt='arrow left' />{' '}
                        <span>Back</span>
                      </Button>
                    </div>
                    <div className='flex justify-space gap-3 max-w-[400px]'>
                      <CustomDatePicker2
                        label='From date'
                        onChange={handleStartDateChange}
                      />
                      <CustomDatePicker2
                        label='To date'
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                  <div className='mt-4 gap-4 flex justify-center'>
                    Total Commission:
                    <span className='font-bold'>{totalCommission}</span>
                  </div>
                  <div className='gap-4 font-bold text-[#77553D] p-2'>
                    Shipments
                  </div>
                  {filteredShipments?.length ? (
                    <CustomTable
                      table='/sales-members-commission'
                      header={SALES_MEMBERS_COMMISSION}
                      rows={filteredShipments}
                    />
                  ) : (
                    <CustomEmptyDataComponent heading='No Data Found!'></CustomEmptyDataComponent>
                  )}
                </>
              )}
            </div>
          </div>
        </CustomContainer>
      )}
      {openAddNewModal && (
        <AddNewModal
          title={'Add New Sales Member'}
          open={openAddNewModal}
          onClose={handleClose}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          trigger={trigger}
          setValue={setValue}
        />
      )}
      {openEditModal && (
        <AddNewModal
          title={'Edit Sales Member'}
          open={openEditModal}
          onClose={handleClose}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          trigger={trigger}
          setValue={setValue}
          data={selectedItem}
        />
      )}
      {/* {openCommissionModal && (
        <CommissionDetailsModal
          title={'Commission Details'}
          onClose={handleClose}
          id={selectedItem?.id}
        />
      )} */}
      {openChangePasswordModal && (
        <AddNewModal
          title={'Change User Password'}
          open={openChangePasswordModal}
          onClose={handleClose}
          onSubmit={onChangePassSubmit}
          data={selectedItem}
          pass={pass}
          setPass={setPass}
          confirmPass={confirmPass}
          setConfirmPass={setConfirmPass}
          passErr={passErr}
        />
      )}
      {openViewModal && (
        <AddNewModal
          title={'View Sales Member'}
          open={openViewModal}
          onClose={handleClose}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          errors={errors}
          trigger={trigger}
          setValue={setValue}
          data={selectedItem}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          modalHeading='Delete Sales Member?'
          modalPara='Are you sure you want to delete this Sales Member? This action cannot be undone.'
          deleteHandler={deleteHandler}
          closeDeleteModal={handleClose}
        />
      )}
    </>
  );
};

export default SalesMembers;
