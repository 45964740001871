import CustomContainer from "../../../common/custom/CustomContainer";
import { ClientsPanelHeader } from "../../../common/custom";
import CustomTable from "../../../common/custom/CustomTable/custom-table.component";
import { ShipmentInformation } from "../../../constants/columns";
import NoEntitiesComponent from "../noEntityComponent";
import useEntityShipment from "./useEntityShipment";
import { CustomIcon } from "../../../common/customAssets";
import { arrowLeft } from "../../../assets";

const EntityShipment = () => {
  const { shipmentInformation, onClickBack } = useEntityShipment();
  return (
    <CustomContainer>
      <ClientsPanelHeader />
      <div className="stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]">
        <div className="flex">
          <CustomIcon
            icon={arrowLeft}
            className="p-2 cursor-pointer"
            clickHandler={onClickBack}
          />
          <h1 className="text-[26px] font-semibold mb-8 text-foundation-brown font-inter">
            Shipment Infomation
          </h1>
        </div>

        {shipmentInformation && shipmentInformation?.length > 0 ? (
          <CustomTable
          table="entityShipmentInformation"
            header={ShipmentInformation}
            rows={shipmentInformation}
          />
        ) : (
          <NoEntitiesComponent text={"No Shipment Information Found"} />
        )}
      </div>
    </CustomContainer>
  );
};

export default EntityShipment;
