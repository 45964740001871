import {
  Elements,
  useElements,
  useStripe,
  PaymentElement,
} from '@stripe/react-stripe-js';

import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { getListPaymentMethodsQuery } from '../../../api';
import useApi from '../../../hook/useApi';
import { CustomButton } from '../../../common/custom';
import { PATHS } from '../../../constants/paths';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_PK);

const StripeCardDetailsForm = ({ setupIntent }) => {
  const options = {
    clientSecret: setupIntent?.client_secret,
    appearance: {},
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm />
    </Elements>
  );
};

export default StripeCardDetailsForm;

const SetupForm = () => {
  const [getListPaymentMethods] = useApi(getListPaymentMethodsQuery);

  const user = JSON.parse(localStorage.getItem('login-user'));

  const fetchPaymentMethodsList = async () => {
    const { data, errors } = await getListPaymentMethods(
      user.location.stripeCustomerID
    );
  };

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [showWarning, setShowWarning] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return null;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_PUBLIC_STRIPE_RETURN_URL}payment/success`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      fetchPaymentMethodsList();
    }
    navigate(PATHS.ENTITIES);
  };

  return (
    <form className='mt-10' onSubmit={handleSubmit}>
      <PaymentElement
        onChange={(event) => {
          if (event.value.type.includes('card')) {
            setShowWarning(true);
          } else {
            setShowWarning(false);
          }
        }}
        className='px-2'
      />
      <div className=' flex flex-col  md:flex-row gap-[18px]  md:gap-[32px] justify-between mt-[32px]'>
        <CustomButton
          type='button'
          onClick={() => {
            navigate(PATHS.ENTITIES);
          }}
          className='order-1 md:order-none bg-none rounded-[8px] border-[1px] border-foundation-brown  text-foundation-brown max-w-[160px] w-full h-[48px]'
          variant='outline'
          text='Cancel'
        />

        <CustomButton
          className='bg-foundation-brown rounded-[8px] text-white max-w-[160px] w-full h-[48px] '
          text='Save'
        />
      </div>
      {showWarning ? (
        <p className='py-6 text-xs font-semibold'>
          * Additional 3% on premium will be charged on Credit Card payments
        </p>
      ) : (
        ''
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
