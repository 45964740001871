import React from 'react';

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { PATHS } from '../../../constants/paths';
import Breadcrumbs from '../BreadCrumbs';

import CustomContainer from '../CustomContainer'; // importing Custom Container for using in this componenet
import { useClientPanelHeader } from './useClientsPanelHeader';
import { TOAST_TIME } from '../../../constants/toastNotifications';

// Note  ==> (This componenet is a reusable component which is being used in different pages header where we have routes history information and
// a button so from here we can send more props like routes history , its currently under development)

// Note ==>  right now its currently under development so no styling or other props are passed in this component
const ClientsPanelHeader = () => {
  const navigate = useNavigate();
  const { paymentsMethod } = useClientPanelHeader();
  const reportShipmentHandler = () => {
    if (Array.isArray(paymentsMethod) && paymentsMethod.length > 0) {
      navigate(PATHS.REPORTS_SHIPMENTS);
    } else {
      toast.dismiss();
      toast.error('Please add a payment method', { duration: TOAST_TIME });
    }
  };
  return (
    <CustomContainer className=' flex flex-col sm:flex-row justify-between items-start sm:items-center my-[24px]   sm:my-[30px] md:my-[36px] lg:my-[42px] '>
      <Breadcrumbs />
    </CustomContainer>
  );
};

export default ClientsPanelHeader;
