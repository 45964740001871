import {
  CustomModal,
  CustomDropdown,
  CustomText,
  CustomButton,
} from "../../../../common/custom";
import { CustomIcon } from "../../../../common/customAssets";
import { cross } from "../../../../assets";
import CustomDatePicker from "../../../../common/custom/CustomDatePicker";
import { Divider } from "@mui/material";
import useFilterShipment from "./useFilterShipment";

const FilterShipmentModal = ({
  closeFilterModal,
  entityData,
  register,
  handleSubmit,
  onSubmit,
  setValue,
  errors,
  onReset,
}) => {
  const { entity, status, setEntity, setStatus } = useFilterShipment();

  return (
    <CustomModal>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-[400px] w-[400px] min-w-[300]  h-auto bg-pure-white w-full rounded-[12px] flex flex-col items-center gap-1 justify-center   ">
          <div className="flex justify-space w-full px-5 my-4 items-center">
            <CustomText className="text-[#101828] font-semibold  text-[16px] font-[inter]">
              Filter
            </CustomText>
            <div
              onClick={closeFilterModal}
              className=" cursor-pointer w-full flex justify-end "
            >
              <CustomIcon icon={cross} className=" h-[12px] w-[12px]" />
            </div>
          </div>
          <Divider className="w-full divide-gray-400" />

          <div className="flex flex-col my-4 px-[20px] w-full font-medium font-[inter]">
            <CustomText
              as="label"
              className="text-[#77553D] flex justify-start"
            >
              Select Entity
            </CustomText>
            <div className="mt-[8px]">
              <CustomDropdown
                placeholder="Select Entity"
                options={entityData ? entityData : []}
                setValue={setValue}
                name="entity"
                onChange={(event) => setEntity(event.state)}
                value={entity}
                register={register}
              />
            </div>
          </div>

          <Divider className="w-full divide-gray-400" />
          <div className="flex flex-col my-4 px-[20px] w-full font-medium font-[inter]">
            <CustomText
              as="label"
              className="text-[#77553D] flex justify-start"
            >
              Delivery Status
            </CustomText>
            <div className="mt-[8px]">
              <CustomDropdown
                placeholder="Delivery Status"
                value={status}
                register={register}
                options={[
                  { id: "Both", state: "Both" },
                  { id: "Delivered", state: "Delivered" },
                  { id: "In-Transit", state: "In-Transit" },
                ]}
                onChange={(event) => setStatus(event.state)}
                setValue={setValue}
                name="status"
                errors={errors}

              />
            </div>
          </div>
          {/* <CustomDropdown
              placeholder="Claim Type"
              label="Claim Type"
              value={claimType}
              errors={errors}
              options={[
                { id: "Damaged", state: "Damaged" },
                { id: "Lost/Stolen", state: "Lost/Stolen" },
              ]}
              setValue={setValue}
              name="claimType"
            /> */}
          <Divider className="w-full divide-gray-400" />
          <div className="flex flex-col my-4 px-[20px] w-full font-medium font-[inter]">
            <CustomText
              as="label"
              className="text-[#77553D] flex justify-start"
            >
              Delivery Date
            </CustomText>

            <div className="flex w-full justify-space gap-3">
              <CustomDatePicker
                label="From"
                name="fromDate"
                register={register}
                errors={errors}
              />
              <CustomDatePicker
                label="To"
                name="toDate"
                register={register}
                errors={errors}
              />
            </div>
          </div>

          <div className=" h-[44px] flex gap-[12px] w-full my-4 px-[20px]">
            <CustomButton
              type="button"
              text="Reset"
              className="w-full h-[44px] border-[1px] rounded-[8px]"
              onClick={onReset}
            />
            <CustomButton
              text="Apply"
              className="w-full h-[44px] bg-green-500 rounded-[8px] text-pure-white"
            />
          </div>
        </div>
      </form>
    </CustomModal>
  );
};
export default FilterShipmentModal;
