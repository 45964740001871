import { useEffect, useRef, useState } from 'react';
import { removeAddressMutation } from '../../../api';
import { plusBrown, plusGray } from '../../../assets';
import CustomContainer from '../../../common/custom/CustomContainer';
import CustomTable from '../../../common/custom/CustomTable/custom-table.component';
import { CustomIcon } from '../../../common/customAssets';
import { AddressInformation } from '../../../constants/columns';
import useApi from '../../../hook/useApi';
import { DeleteModal } from '../../modals';
import ShipFromActionMenu from '../EditEntities/AddressActionMenu';
import NoEntitiesComponent from '../noEntityComponent';
import AddShipFromAddress from './add-ship-from-address';
import { formatAddress } from '../../../helpers/helpers';
import useOutsideClick from '../../../hook/useOutsideClick';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../../constants/toastNotifications';
import { CustomButton } from '../../../common/custom';

const AddressList = ({ entity }) => {
  const [removeAddressCall, removeAddressLoading] = useApi(
    removeAddressMutation
  );
  const [entityAddresses, setEntityAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [toggleActionMenu, setToggleActionMenu] = useState(false);
  const actionMenuRef = useRef(null);

  useEffect(() => {
    if (JSON.stringify(entity) !== '{}') {
      const _formattedAddresses = entity?.location[0]?.address.map((e) => {
        return {
          ...e,
          nickName: e.nickName ? e.nickName : 'N/A',
          formattedAddress: formatAddress(e.address),
        };
      });
      setEntityAddresses(_formattedAddresses ? _formattedAddresses : []);
    }
  }, [entity]);

  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));

  const handleActionClick = (e, address) => {
    e.stopPropagation();
    setSelectedAddress(address);
    if (toggleActionMenu) {
      setToggleActionMenu(null);
    } else {
      setToggleActionMenu(address.id);
    }
  };

  const handleDeleteAddress = async () => {
    toast.dismiss();
    toast.loading('Removing address! Please wait...');
    const { data, errors } = await removeAddressCall(
      parseInt(selectedAddress.id)
    );
    if (data) {
      toast.dismiss();
      toast.success('Address has been removed successfully', {
        duration: TOAST_TIME,
      });

      const _formattedAddresses = entity?.location[0]?.address
        .filter((e) => e?.id !== selectedAddress?.id)
        .map((e) => {
          return {
            ...e,
            nickName: e.nickName ? e.nickName : 'N/A',
            formattedAddress: formatAddress(e.address),
          };
        });
      setEntityAddresses(_formattedAddresses);
    }
    if (errors) {
      toast.dismiss();
      toast.error('Failed to remove address', { duration: TOAST_TIME });
    }
    return { data, errors };
  };

  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => setShowAddModal(false);

  const openDeleteModal = () => setShowDeleteModal(true);
  const closeDeleteModal = () => setShowDeleteModal(false);

  return (
    <CustomContainer className='mt-4'>
      {showAddModal && (
        <AddShipFromAddress
          entity={entity}
          selectedAddress={selectedAddress}
          closeAddModal={closeAddModal}
          addresses={entityAddresses}
          setAddresses={setEntityAddresses}
        />
      )}
      {showDeleteModal ? (
        <DeleteModal
          deleteHandler={handleDeleteAddress}
          modalHeading='Delete Ship Address?'
          modalPara='Are you sure you want to delete this Ship Address? This action cannot be undone.'
          closeDeleteModal={closeDeleteModal}
          deleteLoading={removeAddressLoading}
        />
      ) : null}
      <div className='stepper-cards max-w-[895px] w-full mx-auto p-4 bg-[#F8FAFB] border-[1px] border-[#E7ECF4]'>
        <div className='flex justify-between'>
          <h1 className='text-[26px] font-semibold mb-8 text-foundation-brown font-inter'>
            Addresses Information
          </h1>
          <CustomButton
            onClick={openAddModal}
            icon={plusGray}
            text='Add New'
            className={`bg-foundation-white px-[16px]  rounded-[8px] border border-[1px]  border-[#D0D5DD] h-[40px] flex justify-between items-center text-[14px] font-[500] mt-4 sm:mt-0  gap-3`}
          />
        </div>
        {entityAddresses?.length > 0 ? (
          <CustomTable
            header={AddressInformation}
            table="/entities"
            rows={entityAddresses}
            actionMenuRef={actionMenuRef}
            {...{ handleActionClick, toggleActionMenu }}
            menu={
              <ShipFromActionMenu
                openEditModal={openAddModal}
                openDeleteModal={openDeleteModal}
              />
            }
          />
        ) : (
          <NoEntitiesComponent text={'No Addresses Found'} />
        )}
      </div>
    </CustomContainer>
  );
};

export default AddressList;
