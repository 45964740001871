import * as yup from 'yup';

export const reportShipmentStepThreeSchema = yup.object().shape({
  pickupDate: yup.date().required('Pickup date is required'),
  deliveryDate: yup
    .date()
    .required('Delivery date is required')
    .min(yup.ref('pickupDate'), 'Delivery date must be after pickup date'),
  carrier: yup.string().required('Please select carrier'),
});
