import React from 'react';
import ShipmentTypes from '../../features/shipmentTypes';

const ShipmentTypesPage = () => {
  return (
    <div>
      <ShipmentTypes />
    </div>
  );
};

export default ShipmentTypesPage;
