import { useDispatch, useSelector } from 'react-redux';
import { setReportClaimFormStepAction } from '../../../store';
import { FORMATE_DATE, PARSIFY } from '../../../helpers/helpers';

export const useCreateClaim = () => {
  const dispatch = useDispatch();
  const { claimFile } = useSelector((state) => state.forms);
  const { shipments } = useSelector((state) => state);

  const toBeClaimed = shipments?.allShipments
    ?.filter((shipment) => shipment?.isDelivered == true && !shipment.claim)
    .map((shipment, index) => {
      return {
        id: shipment.id,
        value: shipment.id,
        totalValue: shipment.totalValue,
        name: `${index + 1}. Confirmation ID: ${
          shipment.confirmationId
        }, Reference: ${PARSIFY(shipment.to)?.reference}, from: ${
          shipment?.from?.nickName
        }(${shipment.location.name}) to: ${
          PARSIFY(shipment.to).name
        }, delivered on ${FORMATE_DATE(shipment?.deliveryDate)}`,
        label: `${index + 1}. Confirmation ID: ${
          shipment.confirmationId
        }, Reference: ${PARSIFY(shipment.to)?.reference}, from: ${
          shipment?.from?.nickName
        }(${shipment.location.name}) to: ${
          PARSIFY(shipment.to).name
        }, delivered on ${FORMATE_DATE(shipment?.deliveryDate)}`,
        location: PARSIFY(shipment.to)?.name,
        additionalNote: shipment.additionalNote,
        locationId: shipment.locationId,
        entityId: shipment.location.entity.id,
      };
    });

  // console.log(toBeClaimed);
  const increaseStepHandler = () => {
    const step = claimFile.currentStep + 1;
    dispatch(setReportClaimFormStepAction(step));
  };

  const decreaseStepHandler = () => {
    const step = claimFile.currentStep - 1;
    dispatch(setReportClaimFormStepAction(step));
  };

  return {
    currentStep: claimFile ? claimFile.currentStep : 1,
    increaseStepHandler,
    decreaseStepHandler,
    toBeClaimed,
  };
};
