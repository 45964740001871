import React from 'react';
import { CustomIcon } from '../../../../common/customAssets';
import { DOWNLOAD_FILE } from '../../../../helpers/helpers';
import { download } from '../../../../assets';

const SignedRelease = ({ files }) => {
  return (
    <div className='stepper-cards mb-10'>
      <h1 className='text-[26px] font-semibold mb-4 text-foundation-brown font-inter'>
        Upload Signed Release Claim
      </h1>
      {files?.length &&
        files?.map((e, index) => (
          <div key={index} className='flex justify-between items-center pt-1'>
            <div className='flex justify-start items-center'>
              <div className='w-8'></div>
              <div className='text-sm text-[#7D7D7D]'>{e?.originalname}</div>
            </div>
            <div onClick={() => DOWNLOAD_FILE(e?.location)}>
              <CustomIcon icon={download} className='w-6 h-6 cursor-pointer' />
            </div>
          </div>
        ))}
    </div>
  );
};

export default SignedRelease;
