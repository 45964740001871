import React from 'react';

import CustomButton from '../CustomButton';
import CustomText from '../CustomText';
import { CustomIcon } from '../../customAssets';
import { alertCircleIcon, plusGray } from '../../../assets';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../constants/paths';
import { useADdPaymentMethodsAlert } from './useAddPaymentMethodsALert';

export default function AddPaymentMethodAlertComponent() {
  const { paymentMethods } = useADdPaymentMethodsAlert();

  if (paymentMethods?.length > 0) return <></>;
  //   return (
  //     <div
  //       variant='outlined'
  //       color='blue'
  //       icon={<ExclamationTriangleIcon className='h-6 w-6' />}
  //       action={
  //         user.role == 'MANAGER' || user.role == 'ADMIN' ? (
  //           <Link href='/payment/save-card'>
  //             <Button
  //               variant='filled'
  //               color='blue'
  //               size='sm'
  //               className='!absolute top-3 right-3'
  //             >
  //               Add Payment Method
  //             </Button>
  //           </Link>
  //         ) : (
  //           <></>
  //         )
  //       }
  //     >
  //       Payment Method Missing: Please ensure there is at least one valid payment
  //       method on file.
  //     </div>
  //     );

  return (
    <div className='hidden p-[0px] md:p-[6px] lg:p-[24px]   '>
      <div className='border-[1px] bg-[#FFFBFA] rounded-[12px] w-full border-[#FDA29B] min-h-[80px] p-[16px]'>
        <div className='flex flex-col lg:flex-row items-start lg:justify-between lg:items-center h-full gap-2'>
          <div className=' max-w-[732px] flex flex-col items-start lg:flex-row lg:items-center gap-[12px] w-full'>
            <div>
              <CustomIcon icon={alertCircleIcon} />{' '}
            </div>
            <div>
              <CustomText className='text-[#B42318] font-inter text-[14px] md:text-[16px] font-[600] leading-[20px] mb-[8px]'>
                Payment Method Missing: Please ensure there is at least one
                valid payment method on file.
              </CustomText>
              <CustomText className='text-[#B42318] tetx-[12px] md:text-[14px] font-inter font-[400] leading-[20px]'>
                Please ensure there is at least one valid payment method on
                file.
              </CustomText>
            </div>
          </div>
          <Link
            to={PATHS.PAYMENT_ADD_PAYMENT_METHOD_CARD}
            className=' px-[16px] py-[10px] rounded-[8px] border-[1px] font-inter leading-[20px]'
          >
            <CustomButton
              icon={plusGray}
              text='Add Payment Method'
              className='flex items-center gap-[8px] text-[10px] sm:text-[12px] lg:text-[14px] text-[#344054] font-[600]'
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
