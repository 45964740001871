import { createSlice } from "@reduxjs/toolkit";
import {
  setAddress,
  storeAllAddresses,
  storeAllEntityAddress,
} from "./addressesAction";

const initialState = {
  addresses: [],
  selectedAddress: null,
  entityAddress: null,
};

const addressesSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    storeAllAddressesAction: storeAllAddresses,
    setAddressAction: setAddress,
    storeAllEntityAddressAction: storeAllEntityAddress,
  },
});

export const addressesReducer = addressesSlice.reducer;
export const {
  storeAllAddressesAction,
  setAddressAction,
  storeAllEntityAddressAction,
} = addressesSlice.actions;
