import axios from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fileClaimMutation } from '../../../../../api/services/claim.service';
import { HANDLE_FILE_SIZE_CHECK } from '../../../../../helpers/helpers';
import useApi from '../../../../../hook/useApi';
import { PATHS } from '../../../../../constants/paths';
import { resetReportClaimFormAction } from '../../../../../store';
import { TOAST_TIME } from '../../../../../constants/toastNotifications';

function useUploadDocument() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [purchaseFiles, setPurchaseFiles] = useState([]);
  const [pickUpFiles, setPickUpFiles] = useState([]);
  const [damageLossFiles, setDamageLossFiles] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [pickupLoading, setPickUpLoading] = useState(false);
  const [damageLossLoading, setDamageLossLoading] = useState(false);

  const recipientDetail = useSelector(
    (state) => state.forms.claimFile.formData.step1
  );

  const describeClaim = useSelector(
    (state) => state.forms.claimFile.formData.step2
  );

  const [claimFile] = useApi(fileClaimMutation);

  const handlePurchaseFiles = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setisLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setPurchaseFiles((prevFiles) => [...prevFiles, ...successfulUploads]);
          setisLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setisLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handlePickUpFiles = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setPickUpLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setPickUpFiles((prevFiles) => [...prevFiles, ...successfulUploads]);
          setPickUpLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setPickUpLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handleDamageLossFiles = (files) => {
    try {
      const validFiles = Array.from(files).filter((file) =>
        HANDLE_FILE_SIZE_CHECK(20, file)
      );

      if (validFiles.length === 0) {
        return; // No valid files to upload
      }

      setDamageLossLoading(true);
      toast.loading('Files are uploading. Please wait...');

      const uploadPromises = validFiles.map((file) => {
        const formData = new FormData();
        formData.append('upload', file);

        return axios
          .post('https://api.ceasta.com/fileUpload', formData)
          .then((response) => response.data)
          .catch((err) => {
            console.log('Error in uploading the file: ', err);
            return null;
          });
      });

      Promise.all(uploadPromises)
        .then((results) => {
          const successfulUploads = results.filter((result) => result !== null);
          setDamageLossFiles((prevFiles) => [
            ...prevFiles,
            ...successfulUploads,
          ]);
          setDamageLossLoading(false);
          toast.dismiss();
        })
        .catch((err) => {
          console.log('Error in uploading files: ', err);
          setDamageLossLoading(false);
          toast.dismiss();
        });
    } catch (err) {
      console.log('Error in uploading the files: ', err);
    }
  };

  const handlePurchaseCancel = (index) => {
    setPurchaseFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handlePickUpCancel = (index) => {
    setPickUpFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDamageLossCancel = (index) => {
    setDamageLossFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleClaimFIle = async (e) => {
    try {
      e.preventDefault();
      toast.loading('Filing the Claim. Please wait...');
      const { data, errors } = await claimFile({
        receipts: JSON.stringify([pickUpFiles]),
        invoice: JSON.stringify([purchaseFiles]),
        attachments: JSON.stringify([damageLossFiles]),
        entityId: Number(recipientDetail.entityId),
        locationId: recipientDetail.locationId,
        internalNote: recipientDetail.additionalNote,
        shipmentId: Number(recipientDetail.selectedShipment),
        bestWayToContact: recipientDetail.contact,
        customerPhone: String(recipientDetail.phoneNumber),
        description: describeClaim.description,
        claimAmount: Number(recipientDetail.claimAmount),
        partToBeClaimed: recipientDetail.partToBeClaimed,
        internalComments: describeClaim.internalComments,
        filedByName: recipientDetail.filedByName,
      });

      if (data) {
        navigate(PATHS.CLAIMS);
        toast.success('The Claim Filed Successfully', { duration: TOAST_TIME });
        dispatch(resetReportClaimFormAction());
      } else {
        toast.error(
          errors[0]?.message
            ? errors[0]?.message
            : 'Error Occured in Filing the Claim!',
          {
            duration: TOAST_TIME,
          }
        );
      }
    } catch (err) {
      console.log('Error in filing the claim: ', err);
    }
  };

  const onCancel = () => {
    dispatch(resetReportClaimFormAction());
    navigate(-1);
  };

  return {
    purchaseFiles,
    pickUpFiles,
    damageLossFiles,
    handlePurchaseFiles,
    handlePickUpFiles,
    handleDamageLossFiles,
    handlePurchaseCancel,
    handlePickUpCancel,
    handleDamageLossCancel,
    handleClaimFIle,
    isLoading,
    pickupLoading,
    damageLossLoading,
    onCancel,
  };
}

export default useUploadDocument;
