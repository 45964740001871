// import React from 'react';
// import GoogleMapAutocomplete from '../google-maps-auto-complete';

// const AutoCompleteComponent = ({
//   label,
//   onChange,
//   street,
//   setstreet,
//   placeholder,
//   classes = 'border border-gray-400 rounded-md px-4 text-sm py-2  w-full',
//   isFormatted = false,
// }) => {
//   const handlePlaceSelect = (address, latitude, longitude) => {
//     // Perform any additional actions with the selected address and coordinates
//   };

//   return (
//     <GoogleMapAutocomplete
//       label={label}
//       onChange={onChange}
//       street={street}
//       classes={classes}
//       setstreet={setstreet}
//       placeholder={placeholder}
//       onPlaceSelect={handlePlaceSelect}
//       isFormatted={isFormatted}
//     />
//   );
// };

// export default AutoCompleteComponent;
import React from 'react';
import GoogleMapAutocomplete from '../google-maps-auto-complete';
import { CustomText } from '../../../common/custom';

const AutoCompleteComponent = ({
  onChange,
  street,
  errors,
  name,
  label = 'Address',
  address,
  setstreet,
  classes = 'border border-gray-400 rounded-md px-4 text-sm py-2  w-full',
  isFormatted = false,
  setValue,
  trigger,
  register,
  required,
  value,
}) => {
  const handlePlaceSelect = (address, latitude, longitude) => {};
  return (
    <div className=''>
      <CustomText className='mb-[6px] font-inter font-[500] text-[14px] text-[#344054]'>
        {label}
        {required && <span className='text-foundation-brown'>*</span>}
      </CustomText>
      <GoogleMapAutocomplete
        name={name}
        label={label}
        onChange={onChange}
        street={street}
        classes={classes}
        setstreet={setstreet}
        onPlaceSelect={handlePlaceSelect}
        isFormatted={isFormatted}
        address={address}
        setValue={setValue}
        trigger={trigger}
        errors={errors}
        register={register}
      />
    </div>
  );
};

export default AutoCompleteComponent;
