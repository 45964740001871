import React from "react";
import { closeIcon, wordIcon, pdfIcon } from "../../../assets";
import { CustomImage } from "../../../common/customAssets";
import CustomModal from "../../../common/custom/custom-modal";
import { FILE_SIZE_B_TO_MB } from "../../../helpers/helpers";

function ViewAttachmentsModal({ onClose, title, files }) {
  return (
    <CustomModal>
      <div className="w-full bg-[#fff] max-w-[1219px] p-8 h-[511px] overflow-y-auto shadow-[0px_1px_9px_0px_rgba(0,0,0,0.11)] rounded-xl">
        <div className="flex justify-between items-center gap-3">
          <div className="text-[#77553D] text-[26px] not-italic font-semibold leading-[normal]">
            {title}
          </div>
          <div onClick={onClose} className="hover:cursor-pointer">
            <CustomImage
              img={closeIcon}
              alt="back"
              className="w-[30px] h-[30px]"
            />
          </div>
        </div>
        <div className="!pt-8 pb-6 flex flex-wrap gap-4 justify-center items-center">
          {files?.map((file) => {
            return (
             file !== null && file.location?.split(".").pop() === "pdf" ? (
                <>
                  <a href={file.location} download={file.originalname}>
                    <div className="flex items-center gap-4 p-2 rounded-lg bg-[#efefef]">
                      <img
                        src={pdfIcon}
                        alt="file"
                        className="h-10 hover:cursor-pointer"
                      />
                      <div className="">
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6">
                          {file?.originalname}
                        </div>
                        <div className="text-[#747474] text-xs not-italic font-normal leading-[normal]">
                          {FILE_SIZE_B_TO_MB(file.size)}MB
                        </div>
                      </div>
                    </div>
                  </a>
                </>
              ) : (file !== null && file.location?.split(".").pop() === "docx" || file.location?.split(".").pop() === "doc") ? (
                <>
                  <a href={file.location} download={file.originalname}>
                    <div className="flex items-center gap-4 p-2 rounded-lg bg-[#efefef]">
                      <img
                        src={wordIcon}
                        alt="file"
                        className="h-10 hover:cursor-pointer"
                      />
                      <div className="">
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[150px] text-[#1D3250] text-sm not-italic font-semibold leading-6">
                          {file?.originalname}
                        </div>
                        <div className="text-[#747474] text-xs not-italic font-normal leading-[normal]">
                          {FILE_SIZE_B_TO_MB(file.size)}MB
                        </div>
                      </div>
                    </div>
                  </a>
                </>
              ) : (
                <div className="flex gap-6">
                  <img
                    src={file && file.location}
                    alt="pickUpFileUrl"
                    className="h-full w-full max-w-[369px]"
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
    </CustomModal>
  );
}

export default ViewAttachmentsModal;
