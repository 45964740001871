import { useEffect } from 'react';
import { useHookFormSubmission } from '../../../../hook/useHookFormSubmission';
import { signUpStepTwoSchema } from '../../schema/sign-up-step-two';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormDataAction } from '../../../../store';

export const useSignUpStepTwo = (increaseStepHandler) => {
  const { signUp } = useSelector((state) => state.forms);
  const dispatch = useDispatch();
  const { handleSubmit, register, errors, setValue } =
    useHookFormSubmission(signUpStepTwoSchema);

  useEffect(() => {
    setValue(
      'streetAddress',
      signUp.formData.step2?.streetAddress
        ? signUp.formData.step2.streetAddress
        : ''
    );
    setValue(
      'phoneNumber',
      signUp.formData.step2?.phoneNumber
        ? signUp.formData.step2.phoneNumber
        : ''
    );
    setValue(
      'emailAddress',
      signUp.formData.step2?.emailAddress
        ? signUp.formData.step2.emailAddress
        : ''
    );
    setValue(
      'website',
      signUp.formData.step2?.website ? signUp.formData.step2.website : ''
    );
  }, []);

  const onSubmit = (values) => {
    increaseStepHandler();
    dispatch(updateFormDataAction({ step: 2, values }));
  };

  return { handleSubmit, onSubmit, register, errors };
};
