import React from 'react';

import CustomContainer from '../../../common/custom/CustomContainer';
import { ClientsPanelHeader } from '../../../common/custom';
import { useClaims } from './useClaims';
import NoClaimsComponent from '../components/noCLaimsComponent';
import AllClaims from '../components/allClaims';
import CircularILoader from '../../../common/custom/circularLoader';

const Claims = () => {
  const {
    data,
    isLoading,
    reportClaimHandler,
    concludeHandler,
    newClaimHandler,
    currentTab,
    handleTabSwitch,
  } = useClaims();

  if (data?.length === 0 && !isLoading) {
    return (
      <NoClaimsComponent>
        <ClientsPanelHeader />
      </NoClaimsComponent>
    );
  }
  return (<>
    {
      isLoading ? (
        <div class='flex h-[126px] justify-center items-center gap-1 self-stretch px-6 py-4 rounded-xl' >
          <CircularILoader />
        </div >
      ) : (
        <CustomContainer>
          <ClientsPanelHeader />
          <AllClaims
            data={data}
            isLoading={isLoading}
            reportClaimHandler={reportClaimHandler}
            concludeHandler={concludeHandler}
            newClaimHandler={newClaimHandler}
            currentTab={currentTab}
            handleTabSwitch={handleTabSwitch}
          />
        </CustomContainer>
      )}
  </>
  );
};

export default Claims;
