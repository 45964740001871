import * as yup from 'yup';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

export const editEntitySchema = yup.object().shape({
  businessName: yup.string().required('Business Name is required'),
  email: yup
    .string()
    .email('Invalid Email Format')
    .matches(emailRegex, 'Invalid Email Format')
    .required('Email is required'),
  phone: yup.string().required('Phone Number is required'),
  address: yup.string().required('Street address is required'),
});

export const updateRateEntitySchema = yup.object().shape({
  premiumRate: yup
    .number()
    .required('Premium Rate Value is required')
    .typeError('Premium Rate Value must be a number'),
});
