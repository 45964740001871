import React from 'react';

// custom icon componenet
const CustomIcon = ({
  className = '', // accept custom className prop for custom styling like size or other
  icon, // accept icon as prop
  alt, // accept alt
  clickHandler,
}) => {
  return (
    <div onClick={clickHandler}>
      <img className={className} src={icon ? icon : ''} alt={alt ? alt : ''} />
    </div>
  );
};

export default CustomIcon;
