import { Tooltip } from '@material-tailwind/react';
import React from 'react';

import {
  approvedIcon,
  attachement,
  changeShipmentsModalIcon,
  checkIcon,
  deleteIcon,
  editIcon,
  commissionIcon,
  paymentMasterIcon,
  paymentsMenuIcon,
  tickIcon,
  viewIcon,
} from '../assets';
import { CustomText } from './custom';
import { CustomIcon } from './customAssets';

const CommonActionMenu = ({
  upper,
  data,
  editActionClick,
  changePassActionClick,
  viewActionClick,
  deleteActionClick,
  commissionActionClick,
}) => {
  return (
    <div
      className={`w-[190px] z-10 shadow-md bg-foundational-white  absolute ${
        upper ? 'top-[-80px]' : 'top-[16px]'
      }  right-[50px] rounded-[8px] flex flex-col items-center justify-center  gap-[2px] py-[4px] border-[1px] border-solid border-[#EAECF0] `}
    >
      <div
        onClick={editActionClick}
        className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <Tooltip className='mt-[-10px] z-50' content='Edit'>
          <div>
            {' '}
            <CustomIcon icon={editIcon} />
          </div>
        </Tooltip>

        <CustomText className='font-inter font-[500] ml-[8px] '>
          <div>Edit</div>
        </CustomText>
      </div>

      <div
        onClick={commissionActionClick}
        className='cursor-pointer flex items-center w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
      >
        <Tooltip className='mt-[-10px] z-50' content='View Commission Details'>
          <div>
            <CustomIcon icon={commissionIcon} />
          </div>
        </Tooltip>

        <CustomText className='font-inter font-[500] ml-[8px] '>
          <div>View Commission Details</div>
        </CustomText>
      </div>

      {changePassActionClick && (
        <div
          onClick={changePassActionClick}
          className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
        >
          <Tooltip className='mt-[-10px] z-50' content='Change Password'>
            <div>
              <CustomIcon
                icon={tickIcon}
                className='rounded-full bg-[#000] p-1 w-5 h-5'
              />
            </div>
          </Tooltip>

          <CustomText className='font-inter font-[500] ml-[8px] '>
            <div>Change Password</div>
          </CustomText>
        </div>
      )}

      {viewActionClick && (
        <div
          onClick={viewActionClick}
          className=' cursor-pointer flex items-center  w-full   min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px] '
        >
          <Tooltip className='mt-[-10px] z-50' content='Edit'>
            <div>
              {' '}
              <CustomIcon icon={viewIcon} />
            </div>
          </Tooltip>

          <CustomText className='font-inter font-[500] ml-[8px] '>
            <div>View</div>
          </CustomText>
        </div>
      )}
      <div
        onClick={deleteActionClick}
        className='cursor-pointer flex items-center  w-full min-h-[40px] px-[8px] hover:bg-[#F9FAFB] rounded-[6px]'
      >
        <Tooltip className='mt-[-10px] z-50' content='Delete'>
          <div>
            {' '}
            <CustomIcon icon={deleteIcon} />
          </div>
        </Tooltip>
        <CustomText className='font-inter font-[500] ml-[8px]'>
          <div className='font-inter font-[500] text-[#D92D20] flex gap-[12px]'>
            Delete
          </div>
        </CustomText>
      </div>
    </div>
  );
};

export default CommonActionMenu;
