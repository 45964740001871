import {
  getIndustriesQuery,
  addIndustryMutation,
  updateIndustryMutation,
  deleteIndustryMutation,
} from '../../api/services/admin.service';
import { useEffect, useRef, useState } from 'react';
import useApi from '../../hook/useApi';
import useOutsideClick from '../../hook/useOutsideClick';
import { useHookFormSubmission } from '../../hook/useHookFormSubmission';
import { addNewSchema } from '../../common/schema/addNewSchema';
import toast from 'react-hot-toast';
import { TOAST_TIME } from '../../constants/toastNotifications';

export const useIndustries = () => {
  const [getIndustries, loading] = useApi(getIndustriesQuery);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const actionMenuRef = useRef(null);
  const [toggleActionMenu, setToggleActionMenu] = useState(null);
  useOutsideClick(actionMenuRef, () => setToggleActionMenu(false));
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  let { handleSubmit, register, reset, errors, trigger, setValue } =
    useHookFormSubmission(addNewSchema);

  useEffect(() => {
    const getData = async () => {
      const res = await getIndustries();
      setData(res?.data?.allIndustries);
    };
    getData();
  }, []);

  const handleActionClick = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);

    toggleActionMenu
      ? (setToggleActionMenu(null), setSelectedItem(null))
      : setToggleActionMenu(item.id);
  };

  const onSubmit = async (values) => {
    try {
      toast.dismiss();
      values = { ...values, industryId: selectedItem?.id };
      const response = selectedItem
        ? await updateIndustryMutation(values)
        : await addIndustryMutation(values);

      selectedItem
        ? (data[data.findIndex((item) => item.id === selectedItem.id)] = {
          ...data.find((item) => item.id === selectedItem.id),
          name: values.name,
        })
        : data?.push(response?.data?.createIndustry);
      data?.sort((a, b) => b.id - a.id);

      toast.success(
        `Industry ${selectedItem ? 'updated' : 'created'} successfully!`,
        { duration: TOAST_TIME }
      );

      handleClose();
    } catch (err) {
      toast.error('Something went wrong!', { duration: TOAST_TIME });
    }
  };

  const deleteHandler = async () => {
    try {
      toast.dismiss();
      const response = await deleteIndustryMutation(parseInt(selectedItem?.id));
      setData((prevData) =>
        prevData.filter((item) => item.id !== selectedItem.id)
      );
      if (response?.data) toast.success('Industry removed successfully!');
      if (response?.errors?.length)
        toast.error(
          response?.errors[0]?.message?.includes('violate')
            ? 'Cannot delete. \nItem is attached with other data!'
            : 'Something went wrong!'
        );
    } catch (err) {
      toast.error('Something went wrong!', { duration: TOAST_TIME });
    }

    handleClose();
  };

  const handleClose = () => {
    if (reset) reset();
    setSelectedItem(null);
    openAddNewModal
      ? setOpenAddNewModal(false)
      : openEditModal
        ? setOpenEditModal(false)
        : setOpenDeleteModal(false);
  };

  const editActionClick = () => {
    setOpenEditModal(true);
    setToggleActionMenu(null);
  };

  const deleteActionClick = () => {
    setOpenDeleteModal(true);
    setToggleActionMenu(null);
  };

  return {
    data,
    handleActionClick,
    toggleActionMenu,
    actionMenuRef,
    selectedItem,
    setOpenAddNewModal,
    onSubmit,
    handleSubmit,
    register,
    errors,
    trigger,
    setValue,
    handleClose,
    editActionClick,
    deleteActionClick,
    openDeleteModal,
    openEditModal,
    openAddNewModal,
    deleteHandler,
    loading
  };
};
